import Accordion from '@components/Accordion';
import React from 'react';

export default function PrivacyFAQ() {
  const items = [
    {
      title:
        'Will my personal information remain private and not shared with outsiders?',
      content: (
        <>
          <p>
            Yes, your personal information will remain private and secure. We
            are committed to protecting your privacy, and we do not share your
            information with any outside parties.
          </p>
        </>
      ),
    },
    {
      title: 'Will my anonymity be protected?',
      content: (
        <>
          <p>
            At HeyJuni, your anonymity is our highest priority. We understand
            the importance of expressing yourself freely, which is why our
            platform is designed to ensure your confidentiality at every step.
          </p>
          <br />
          <p className="text-2xl font-bold">
            Key Measures for Protecting Your Anonymity
          </p>
          <ol className="list-decimal list-inside inline-block">
            <li className="mb-4">
              <span className="font-bold">
                No Personal Identifiable Information:
              </span>{' '}
              During the booking process, you are not required to provide any
              personally identifiable information, including your real name.
            </li>
            <li className="mb-4">
              <span className="font-bold">Data Encryption:</span> All
              information you share is encrypted using industry-standard
              protocols. This ensures that your data is securely stored and
              protected against unauthorized access.
            </li>
            <li className="mb-4">
              <span className="font-bold">Confidential Environment:</span> At
              HeyJuni, you can discuss your experiences without fear of
              judgment. No one will know who you are or what you talk about in
              your sessions. Our Peer Counselors follow strict protocols to
              ensure that all interactions remain private, so you can speak
              openly without fear of repercussions or exposure.
            </li>
            <li className="mb-4">
              <span className="font-bold">Self-Disclosure Guidelines:</span>{' '}
              Additionally, to safeguard your own anonymity, we recommend you to
              refrain from sharing details that could reveal your identity. This
              further safeguards your anonymity.
            </li>
          </ol>
        </>
      ),
    },
    {
      title:
        'What happens if I suspect a breach of privacy or experience a privacy-related issue?',
      content: (
        <>
          <p>
            If you suspect a breach of privacy or experience any privacy-related
            issue during a session, please end the conversation immediately. We
            take your privacy and anonymity very seriously.
          </p>
          <br />
          <p>
            After ending the session, report the incident to us right away via
            email at{' '}
            <a href="mailto:anna@heyjuni.com" className="text-blue-800">
              anna@heyjuni.com
            </a>
            .
          </p>
          <br />
          <p>
            Your safety and confidentiality are our top priorities, and we will
            address any concerns you have promptly.
          </p>
        </>
      ),
    },
  ];

  return (
    <>
      <p className="text-5xl font-extrabold mb-10">Privacy</p>
      <Accordion items={items} />
    </>
  );
}
