import React, { useState } from 'react';

import BackgroundImage1 from '@assets/footages/landing/testimonial-card-1.svg';
import BackgroundImage2 from '@assets/footages/landing/testimonial-card-2.svg';
import BackgroundImage3 from '@assets/footages/landing/testimonial-card-3.svg';

import BackgroundImage1Mobile from '@assets/footages/landing/testimonial-card-1-mobile.svg';
import BackgroundImage2Mobile from '@assets/footages/landing/testimonial-card-2-mobile.svg';
import BackgroundImage3Mobile from '@assets/footages/landing/testimonial-card-3-mobile.svg';

import LeftArrow from '@assets/icons/shared/circle-arrow-left.svg';
import RightArrow from '@assets/icons/shared/circle-arrow-right.svg';

import QuoteIcon from '@assets/icons/shared/quote-icon.svg';
import NormalObject from '@components/NormalObject';
import { useTranslation } from 'react-i18next';

export function TestimonialsSection() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslation('home');

  const testimonials = [
    {
      text: t('testimonial.testimonials.r-25.feedback'),
      author: t('testimonial.testimonials.r-25.name'),
      backgroundImage: BackgroundImage1,
      backgroundImageMobile: BackgroundImage1Mobile,
    },
    {
      text: t('testimonial.testimonials.m-16.feedback'),
      author: t('testimonial.testimonials.m-16.name'),
      backgroundImage: BackgroundImage3,
      backgroundImageMobile: BackgroundImage3Mobile,
    },
    {
      text: t('testimonial.testimonials.m-18.feedback'),
      author: t('testimonial.testimonials.m-18.name'),
      backgroundImage: BackgroundImage2,
      backgroundImageMobile: BackgroundImage2Mobile,
    },
    {
      text: t('testimonial.testimonials.s-18.feedback'),
      author: t('testimonial.testimonials.s-18.name'),
      backgroundImage: BackgroundImage1,
      backgroundImageMobile: BackgroundImage1Mobile,
    },
    {
      text: t('testimonial.testimonials.j-27.feedback'),
      author: t('testimonial.testimonials.j-27.name'),
      backgroundImage: BackgroundImage3,
      backgroundImageMobile: BackgroundImage3Mobile,
    },
    {
      text: t('testimonial.testimonials.a-25.feedback'),
      author: t('testimonial.testimonials.a-25.name'),
      backgroundImage: BackgroundImage2,
      backgroundImageMobile: BackgroundImage2Mobile,
    },
    {
      text: t('testimonial.testimonials.p-30.feedback'),
      author: t('testimonial.testimonials.p-30.name'),
      backgroundImage: BackgroundImage3,
      backgroundImageMobile: BackgroundImage3Mobile,
    },
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const currentTestimonial = testimonials[currentIndex];

  // TODO: Fix height

  return (
    <section className="relative text-center py-[20px] mb-[64px]">
      <p className="text-5xl md:text-7xl leading-[48px] md:leading-[65px] font-extrabold mb-[40px] md:mb-0">
        {t('testimonial.title')}
      </p>

      <div className="relative md:mt-[20px]">
        <div className="">
          <img
            src={currentTestimonial.backgroundImage}
            className="hidden md:block w-full h-auto object-cover"
            alt="Testimonial Background"
          />

          <img
            src={currentTestimonial.backgroundImageMobile}
            className="md:hidden w-full h-auto object-cover"
            alt="Testimonial Background"
          />

          <div className="absolute inset-0 flex flex-col justify-start mt-[31px] px-[27px] md:mt-[39px] md:px-[66px]">
            <NormalObject
              data={QuoteIcon}
              className="w-[42px] h-[42px] md:w-[150px] md:h-[150px]"
            />
            <p className="text-left text-sm md:text-2xl font-regular md:leading-[32px] mt-[25px] md:mt-[52px] max-w-[1012px]">
              {currentTestimonial.text}
            </p>

            <p className="font-extrabold text-4xl md:text-5xl text-left mt-[20px] md:mt-[50px]">
              {currentTestimonial.author}
            </p>
            <div className="absolute bottom-[28px] md:bottom-[52px] left-[28px] md:left-[66px]">
              <button onClick={handlePrev}>
                <NormalObject data={LeftArrow} className="w-[42px] h-[42px]" />
              </button>
            </div>
            <div className="absolute bottom-[28px] md:bottom-[52px] right-[28px] md:right-[66px]">
              <button onClick={handleNext}>
                <NormalObject data={RightArrow} className="w-[42px] h-[42px]" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
