import appLogo from '@assets/logo.svg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useScrollToTop from '@src/hooks/useScrollToTop';

interface VnHeaderProps {}

const NavLinks = () => {
  return (
    <>
      <span>Vì Sức Khỏe Của Bạn</span>
      <span>www.heyjuni.com/vn</span>
    </>
  );
};

const Header = ({ navigate }: { navigate: (path: string) => void }) => {
  return (
    <header className="fixed top-0 left-0 right-0 bg-white shadow-md z-50 h-[62px] md:h-[90px]">
      <nav
        id="desktop-header"
        className="mx-auto flex items-center justify-between md:px-[33px] md:py-[23px] h-full"
      >
        <div className="flex items-center gap-[10px] md:gap-[50px] pl-[20px] md:pl-0">
          <img
            src={appLogo}
            className="w-[64px] h-[44px] md:w-[74px] md:h-[50px] cursor-pointer"
            onClick={() => navigate('/')}
            alt="HeyJuni Logo"
          />
          <ul className="sm:flex space-x-[10px] md:space-x-[30px] font-semibold text-[12px] md:font-normal md:text-lg">
            <NavLinks />
          </ul>
        </div>
      </nav>
    </header>
  );
};

// Main Header Component
export default function VnHeader(props: VnHeaderProps) {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? 'hidden' : 'visible';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isMenuOpen]);

  // TODO: Put this somewhere else make more sense.
  useScrollToTop();

  return <Header navigate={navigate} />;
}
