import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';

interface PopUpProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  baseRef?: React.RefObject<HTMLElement>;
  right?: number;
  className?: string;
  isGlobalOverlap?: boolean;
}

export function PopUp({
  isOpen,
  onClose,
  children,
  baseRef,
  right,
  className,
  isGlobalOverlap = false,
}: PopUpProps) {
  const [shouldRender, setShouldRender] = useState(isOpen);
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    const navElement = document.getElementById('desktop-header');

    const addPadding = (element: HTMLElement | null, padding: number) => {
      if (element) {
        const currentPadding = parseFloat(
          getComputedStyle(element).paddingRight || '0'
        );
        element.style.paddingRight = `${currentPadding + padding}px`;
      }
    };

    const resetPadding = (element: HTMLElement | null, padding: number) => {
      if (element) {
        const currentPadding = parseFloat(
          getComputedStyle(element).paddingRight || '0'
        );
        element.style.paddingRight = `${currentPadding - padding}px`;
      }
    };

    if (isOpen) {
      setShouldRender(true);
      // Disable scrolling while keeping scrollbar visible
      document.body.style.overflow = 'hidden';
      addPadding(document.body, scrollbarWidth);
      addPadding(navElement, scrollbarWidth);
    } else {
      setTimeout(() => setShouldRender(false), 200); // Delay unmounting for the reverse animation
      // Enable scrolling after popup closes
      document.body.style.overflow = '';
      resetPadding(document.body, scrollbarWidth);
      resetPadding(navElement, scrollbarWidth);
    }

    // Cleanup on component unmount
    return () => {
      document.body.style.overflow = '';
      resetPadding(document.body, scrollbarWidth);
      resetPadding(navElement, scrollbarWidth);
    };
  }, [isOpen]);

  // Calculate the position based on `baseRef` if provided
  const calculatePosition = () => {
    if (baseRef?.current) {
      if (right) {
        return {
          right: `${right}px`,
        };
      }

      const baseRect = baseRef.current.getBoundingClientRect();
      return {
        left: `${baseRect.left}px`,
      };
    }
    // Default position when baseRef is not provided (centered)
    return {
      left: '50%',
      transform: 'translate(-50%, 50%)',
    };
  };

  if (!shouldRender) return null;

  // Overlay content
  const overlay = (
    <div
      onClick={onClose}
      className={`fixed inset-0 !m-0 bg-black bg-opacity-50 z-[99] cursor-default transition-opacity duration-200 ${
        isOpen ? 'opacity-50' : 'opacity-0'
      }`}
    ></div>
  );

  // Popup content with dynamic positioning
  const popupContent = (
    <div
      ref={popupRef}
      style={calculatePosition()}
      className={clsx(
        `fixed overflow-hidden transform z-[100] bg-white rounded-[10px] shadow-lg transition-all ${
          isOpen
            ? baseRef?.current
              ? 'animate-popupSlideUpTop--custom'
              : 'animate-popupSlideUpBottom--default'
            : baseRef?.current
              ? 'animate-popupSlideDownTop--custom'
              : 'animate-popupSlideDownBottom--default'
        }`,
        className
      )}
    >
      {children}
    </div>
  );

  // Render overlay and popup content in the body if isGlobalOverlap is true
  if (isGlobalOverlap) {
    return (
      <>
        {ReactDOM.createPortal(overlay, document.body)}
        {ReactDOM.createPortal(popupContent, document.body)}
      </>
    );
  }

  return (
    <>
      {/* Render overlay in the current context */}
      {overlay}
      {/* Render popup content in the current context */}
      {popupContent}
    </>
  );
}
