import Button from '@components/Button';
import { trpc } from '@src/App';
import { fetchCountry } from '@src/utils/country';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

// TODO: Will use Trans

export function IntroSection() {
  const navigate = useNavigate();
  const { t } = useTranslation('home');

  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const subscriptionMutation =
    trpc.subscription.createSubscription.useMutation();

  const subscribe = async () => {
    if (!email) return; // Check if the email is not empty
    const emailSchema = z.string().email();
    const parseResult = emailSchema.safeParse(email);
    if (!parseResult.success) {
      // Check if the email is valid
      alert('Invalid email address');
      return;
    }

    try {
      setIsLoading(true);
      const countryCode = await fetchCountry();
      await subscriptionMutation.mutateAsync({
        email,
        locale: countryCode === 'VN' ? 'vn' : 'global',
      });
      setSubscribed(true);
      setEmail('');
    } catch (error) {
      console.error('Subscription failed:', error);
    }
    setIsLoading(false);
  };

  return (
    <div className="relative flex flex-col md:flex-row md:justify-between md:mb-0">
      <ComingBanner />

      <div className="flex flex-col px-[26px] md:pl-[76px] md:pr-0 mt-[56px] md:mt-[106px] md:pt-0">
        <div className="hidden md:block font-extrabold md:text-8xl md:leading-[85px] tracking-[-0.03em]">
          <p>Tìm hỗ trợ cảm xúc</p>
          <p>Chỉ trong 15 phút,</p>
          <p>hoàn toàn ẩn danh.</p>
        </div>

        <div className="md:hidden font-extrabold text-5xl leading-[48px]">
          Tìm hỗ trợ cảm xúc. Chỉ trong 15 phút, hoàn toàn ẩn danh.
        </div>

        <div className="font-bold md:font-bold text-3xl md:text-5xl mt-[40px]">
          <p>Chia sẻ để chữa lành.</p>
        </div>

        <div className="flex items-center rounded-[18px] overflow-hidden w-full md:w-[487px] h-[46px] md:h-[72px] mt-5 md:mt-10">
          <input
            type="email"
            placeholder="Điền email để nhận thông báo..."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="h-full flex-grow text-[15px] md:text-2xl p-[12px] md:p-[20px] focus:outline-none placeholder-gray-400 bg-gray-100"
          />
          <button
            onClick={subscribe}
            disabled={isLoading}
            className="h-full bg-[#FEE60C] text-[15px] md:text-lg px-[18px] md:px-8 hover:bg-[#9DFFE2] transition-colors"
          >
            GỬI
          </button>
        </div>
      </div>

      <div className="relative md:hidden pl-[26px]" style={{ zIndex: -1 }}>
        <img
          src="hero-mobile.jpg"
          alt={t('alt.home.hero', { ns: 'common' })}
          className="w-full h-[413]"
        />
      </div>

      <img
        src="hero.webp"
        alt={t('alt.home.hero', { ns: 'common' })}
        className="hidden lg:block h-full object-contain"
      />
    </div>
  );
}

function ComingBanner() {
  const [translateX, setTranslateX] = useState(0);
  const [isResetting, setIsResetting] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTranslateX((prev) => {
        if (prev <= -50) {
          setIsResetting(true); // Disable transition during reset
          return 0;
        }
        setIsResetting(false); // Enable transition for regular movement
        return prev - 0.5;
      });
    }, 300); // 200ms for smooth movement
    return () => clearInterval(interval);
  }, []);

  const content = 'COMING TO VIETNAM. '.repeat(20);

  return (
    <div className="absolute left-0 top-0 w-full overflow-hidden bg-[#181818] h-6 md:h-8 flex items-center">
      <div
        style={{
          transform: `translateX(${translateX}%)`,
          transition: isResetting ? 'none' : 'transform 0.3s linear', // No transition when resetting
          whiteSpace: 'nowrap',
        }}
        className="text-white text-[11px] md:text-base"
      >
        {content}
      </div>
    </div>
  );
}
