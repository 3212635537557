import pricingBackgroundMobile from '@assets/footages/pricing/pricing-bg-mobile.svg';
import Card from './Card';
import Button from '@components/Button';
import { useNavigate } from 'react-router-dom';

import PlanBg01 from '@assets/footages/pricing/pay_as_you_go.svg';
import PlanBg02 from '@assets/footages/pricing/monthly_plan.svg';
import PlanBg03 from '@assets/footages/pricing/unlimited_plan.svg';

import PlanBg01Mobile from '@assets/footages/pricing/pay_as_you_go-mobile.svg';
import PlanBg02Mobile from '@assets/footages/pricing/monthly_plan-mobile.svg';
import PlanBg03Mobile from '@assets/footages/pricing/unlimited_plan-mobile.svg';

import './index.css';
import { openWhatsapp } from '@src/utils/open-whatsapp';
import NormalObject from '@components/NormalObject';
import { Helmet } from 'react-helmet';

export default function Pricing() {
  const navigate = useNavigate();

  return (
    <div className="relative">
      {/* Add Helmet for SEO */}
      <Helmet>
        <title>How Much Does A Session With a Peer Counselor Cost?</title>
        <meta
          name="description"
          content="HeyJuni believes in fast yet affordable and accessible mental healthcare for everyone’s wellbeing and overall health. Book a counseling session within 15 minutes for SG $29.00, anytime, anywhere."
        />
        <meta
          name="keywords"
          content="HeyJuni Pricing, Peer Support, Mental Health Plans, Pay As You Go, Monthly Plan, Unlimited Plan"
        />
        <meta property="og:title" content="Pricing Plans - HeyJuni" />
        <meta
          property="og:description"
          content="Discover HeyJuni's flexible pricing options for mental health support. Choose the plan that fits your needs and get started today."
        />
      </Helmet>

      <div
        className="hidden md:block absolute top-0 right-0"
        style={{ zIndex: -1 }}
      >
        <img
          src="pricing-heading.webp"
          alt="A hand typing on the computer keyboard symbolizing work and collaboration."
        />
      </div>

      <div
        className="md:hidden absolute top-0 right-0 w-full"
        style={{ zIndex: -1 }}
      >
        <NormalObject
          data={pricingBackgroundMobile}
          className="w-full h-full object-cover"
        />
      </div>

      <div className="px-[40px] md:px-[76px]">
        <div className="pt-[58px] md:pt-[88px] pb-[20px] md:pb-[40px] font-extrabold text-6xl leading-[58px] md:text-8xl md:leading-[85px]">
          <p>Emotional Counseling.</p>
          <p>Affordable & Accessible.</p>
        </div>
        <div className="mb-[64px] md:mb-[48px] text-lg md:text-2xl md:leading-[32px] md:max-w-[850px]">
          <p>
            Mental health care should be accessible to everyone, as well-being
            is a fundamental need. Therapy can be costly and may not always be
            the right fit. Not everyone requires therapy, and it can often be
            time-consuming, with extensive planning.
          </p>
          <br />
          <p>
            Discover HeyJuni’s peer counseling services, where you can book a
            session anytime you need someone to listen — no sign-ups, no hassle.
            Get the support you deserve, within 15 minutes, and whenever you
            need it.
          </p>
        </div>
      </div>

      <div className="md:py-[20px] px-[30px] md:px-[60px] flex justify-center">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            src={card.src}
            srcMobile={card.srcMobile}
            price={card.price}
            priceFrequency={card.priceFrequency}
            description={card.description}
            features={card.features}
            buttonText={card.buttonText}
            onClick={() => {
              if (index === 0) {
                navigate('/booking');
              } else {
                openWhatsapp();
              }
            }}
          />
        ))}
      </div>

      <div className="pt-[20px] md:pt-[12px] pb-[40px] md:pb-[64px] px-[34px] md:px-[76px] text-[11px] md:text-[12px] leading-[16px] md:leading-[20px] text-[#00000080]">
        <span className="text-red-500">*</span>
        Deciding how often to attend support session is a collaborative process
        between you and your Peer Counselor and based on your own needs. For
        your well-being, we recommend one session per day, allowing you time to
        rest and reflect on what you've shared. To support this balance,
        sessions are currently limit at one per day.
      </div>
    </div>
  );
}

export const cardData = [
  {
    title: 'Pay as You Go',
    src: PlanBg01,
    srcMobile: PlanBg01Mobile,
    price: 'SGD $29.90',
    priceFrequency: (
      <>
        <div>Per</div>
        <div>Session</div>
      </>
    ),
    description:
      'Be flexible and pay for a single sessions you need in the moment.',
    features: [
      '24/7 emotional support whenever and wherever you need it',
      'Instant: Book and get matched with a Peer Counselor within 15 minutes',
      'Session Duration: 50 minutes',
      "Access to HeyJuni's WhatsApp customer support anytime",
    ],
    buttonText: 'Book Session',
  },
];
