export const CORE_MEMBERS = [
  {
    name: 'Ha Vy Vu',
    title: 'Business & Operations Manager',
  },
  {
    name: 'Aishah Alattas',
    title: 'Domain Advisor, Trainer, Therapist',
  },
  {
    name: 'Chiranjib B.',
    title: 'Tech & Solution Advisor',
  },
  {
    name: 'Sabrina Prabowo',
    title: 'UX/UI Lead',
  },
  {
    name: 'Lac Thien Ho',
    title: 'Fullstack Developer ',
  },
  {
    name: 'Lynn Tan',
    title: 'Product Manager',
  },
  {
    name: 'Quoc Anh Nguyen',
    title: 'Business Development',
  },
  {
    name: 'Akshita Bhatia',
    title: 'Clinical Consultant',
  },
  {
    name: 'Pradnya Govind',
    title: 'Lead Peer Counselor',
  },
  {
    name: 'Preston Hew',
    title: 'Clinical Consultant',
  },
];

export const SPECIAL_THANKS_MEMBERS = [
  {
    name: 'Aloysius Sim',
    title: 'UX/UI Designer',
  },
  {
    name: 'Jeremy Chern',
    title: 'UX/UI Designer',
  },
  {
    name: 'Alex Zeng',
    title: 'UX/UI Designer',
  },
  {
    name: 'Evelyn Chong',
    title: 'UX/UI Designer',
  },
];
