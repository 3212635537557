export interface NormalObjectProps {
  data: string;
  className?: string;
}

export default function NormalObject({ data, className }: NormalObjectProps) {
  return (
    <object data={data} className={className} style={{ pointerEvents: 'none' }}>
      Your browser does not support displaying this object.
    </object>
  );
}
