import React from 'react';
import appLogo from '@assets/logo.svg';
import linkedIn from '@assets/icons/shared/linked-in.svg';
import tiktok from '@assets/icons/shared/tiktok.svg';
import youtube from '@assets/icons/shared/youtube.svg';
import facebook from '@assets/icons/shared/facebook.svg';
import instagram from '@assets/icons/shared/instagram.svg';
import threads from '@assets/icons/shared/threads.svg';
import twitter from '@assets/icons/shared/twitter.svg';
import { useNavigate } from 'react-router-dom';
import WhatsAppButton from './WhatsappButton';
import ClickableObject from './ClickableObject';
import NormalObject from './NormalObject';

const exploreLinks = [
  { label: 'Team HeyJuni', href: 'team-juni' },
  { label: 'Mission & Vision', href: 'mission-and-vision' },
  { label: 'Become Peer Counselor', href: 'become-a-listener' },
  { label: 'Pricing', href: 'pricing' },
];

const supportLinks = [
  { label: 'FAQs', href: 'faq' },
  { label: 'Contact Us', href: 'contact-us' },
  { label: 'Crisis Helpline', href: 'crisis-helpline' },
  { label: 'Terms & Conditions', href: 'terms-and-conditions' },
  { label: 'Privacy Policy', href: 'privacy-policy' },
];

const socialLinks = [
  {
    href: 'https://www.linkedin.com/company/heyjuni/posts/?feedView=all',
    src: linkedIn,
    alt: 'LinkedIn',
  },
  {
    href: 'https://www.instagram.com/heyjuni_official/',
    src: instagram,
    alt: 'Instagram',
  },
  {
    href: 'https://www.youtube.com/@HeyJuni_official',
    src: youtube,
    alt: 'YouTube',
  },
  {
    href: 'https://www.tiktok.com/discover/heyjuni',
    src: tiktok,
    alt: 'TikTok',
  },
  {
    href: 'https://www.facebook.com/HeyJuniOfficial',
    src: facebook,
    alt: 'Facebook',
  },
  {
    href: 'https://www.threads.net/@heyjuni_official',
    src: threads,
    alt: 'Threads',
  },
  {
    href: '',
    src: twitter,
    alt: 'Twitter',
  },
];

// TODO: Need link to twitter

interface FooterProps {}

export default function Footer(props: FooterProps) {
  const navigate = useNavigate();

  return (
    <div className="relative pt-8 md:pt-[42px] pb-4 md:pb-[37px] px-8 md:px-[76px] shadow-top">
      <div className="flex flex-wrap flex-col md:flex-row">
        <div className="w-60 md:w-[346px]">
          <ClickableObject
            data={appLogo}
            className="h-[60px] w-[87px]"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/`);
            }}
          />
        </div>

        <div className="flex flex-wrap flex-1 flex-col md:flex-row gap-10 md:gap-[50px] mt-10 md:mt-0">
          <div className="w-44 md:w-auto flex-1">
            <div className="font-semibold text-2xl md:text-3xl">Explore</div>
            <div className="md:mt-4 font-normal text-base md:text-2xl md:leading-[36px]">
              {exploreLinks.map((link) => (
                <React.Fragment key={link.href}>
                  <a
                    // href={link.href}
                    className="group transition duration-300 inline-block mt-2 cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/${link.href}`);
                    }}
                  >
                    <span className="inline-block">{link.label}</span>
                    <span className="block bg-white w-full group-hover:bg-[#181818] transition-all duration-500 h-0.5" />
                  </a>

                  <br />
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="w-44 md:w-auto flex-1">
            <div className="font-semibold text-2xl md:text-3xl">Support</div>
            <div className="md:mt-4 font-normal text-base md:text-2xl md:leading-[36px]">
              {supportLinks.map((link) => (
                <React.Fragment key={link.href}>
                  <a
                    className="group transition duration-300 inline-block mt-2 cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/${link.href}`);
                    }}
                  >
                    <span className="inline-block">{link.label}</span>
                    <span className="block bg-white w-full group-hover:bg-[#181818] transition-all duration-500 h-0.5" />
                  </a>
                  <br />
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="flex-1">
            <div className="font-semibold text-2xl md:text-3xl">Follow Us</div>
            <div className="flex mt-2 md:mt-6 gap-4 md:gap-[20px] flex-wrap">
              {socialLinks.map((link) => (
                <a
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={link.alt}
                >
                  <NormalObject
                    data={link.src}
                    className="h-6 md:h-[35px] md:w-[35px] transition-transform duration-100 transform hover:scale-110"
                  />
                </a>
              ))}
            </div>
            <div className="font-semibold text-xl md:text-xl mt-8">
              Get in touch for any questions
            </div>
            <WhatsAppButton />
          </div>
        </div>
      </div>

      <div className="text-base font-regular mt-16">
        © 2024 Heyjuni. All Rights Reserved.
      </div>
    </div>
  );
}
