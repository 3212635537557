import { ReactNode, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
  useNavigate,
} from 'react-router-dom';
import { httpBatchLink } from '@trpc/client';
import type { TRPCRouter } from '@backend/src/trpcRouter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createTRPCReact } from '@trpc/react-query';
import Header from '@components/Header';
import Footer from '@components/Footer';
import Home from '@src/pages/Home';
import MeetTheTeam from '@src/pages/MeetTheTeam';
import MissionAndVision from '@src/pages/MissionAndVision';
import Blog from '@src/pages/Blog';
import Pricing from '@src/pages/Pricing';
import BecomeAListener from '@src/pages/BecomeAListener';
import ContactUs from '@src/pages/ContactUs';
import CrisisHelpline from '@src/pages/CrisisHelpline';
import FAQ from '@src/pages/FAQ';
import PrivacyPolicy from '@src/pages/PrivacyPolicy';
import TermsAndConditions from '@src/pages/TermsAndConditions';
import NotFound from '@src/pages/NotFound';
import Booking from '@src/pages/Booking';
import LearnMore from './pages/LearnMore';
import MainLayout from './layouts/MainLayout';
import {
  getAccessToken,
  isAccessTokenExpired,
  refreshAccessToken,
} from './utils/auth';
import { Snackbar } from '@components/Snackbar';
import Highlights from './pages/Blog/highlights';
import ArticleList from './pages/Blog/articles/article-list';
import { Videos } from './pages/Blog/videos';
import { Events } from './pages/Blog/events';
import Article from './pages/Blog/articles/article';
import VnHome from './pages/VnHome';
import { fetchCountry } from './utils/country';
import VnHeader from '@components/VnHeader';
import SchemaMarkup from './SchemaMarkup';

const BACKEND_URL = 'https://juniweb2-0.onrender.com/api';
export const trpc = createTRPCReact<TRPCRouter>();

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

// Layout Component for Unprotected Routes
const Layout = () => (
  <>
    <Header />
    <MainLayout>
      <Outlet />
    </MainLayout>
    <Footer />
  </>
);

const VnLayout = () => (
  <>
    <VnHeader />
    <MainLayout>
      <Outlet />
    </MainLayout>
  </>
);

const RedirectToVN = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCountryAndRedirect = async () => {
      const redirectData = sessionStorage.getItem('redirectData');
      const oneHourInMilliseconds = 60 * 60 * 1000;

      if (redirectData) {
        const { timestamp } = JSON.parse(redirectData);

        // Check if the stored timestamp is still valid
        if (Date.now() - timestamp < oneHourInMilliseconds) {
          return; // Don't redirect
        }
      }

      const country = await fetchCountry();

      if (country === 'VN') {
        // Store the current timestamp in sessionStorage
        sessionStorage.setItem(
          'redirectData',
          JSON.stringify({ timestamp: Date.now() })
        );
        navigate('/vn', { replace: true });
      }
    };

    fetchCountryAndRedirect();
  }, [navigate]);

  return null; // No UI; it only handles redirection
};

function App() {
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [errorSnackBarMessage, setErrorSnackbarMessage] = useState<string>('');

  function showError(err: Error) {
    setErrorSnackbarMessage(err.message);
    setShowErrorSnackbar(true);
  }

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            onError: (err) => showError(err as Error),
            useErrorBoundary: false,
          },
          mutations: {
            onError: (err) => showError(err as Error),
            useErrorBoundary: false,
          },
        },
      })
  );

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: BACKEND_URL,
          headers: async () => {
            let accessToken = getAccessToken();
            if (accessToken && isAccessTokenExpired(accessToken)) {
              accessToken = await refreshAccessToken();
            }
            console.log('accessToken:', accessToken);
            return {
              ...(accessToken
                ? { Authorization: `Bearer ${accessToken}` }
                : {}),
            };
          },
        }),
      ],
    })
  );

  return (
    <div className="text-[#181818]">
      <SchemaMarkup />
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <Snackbar
          message={errorSnackBarMessage}
          onClose={() => {
            setShowErrorSnackbar(false);
            setErrorSnackbarMessage('');
          }}
          show={showErrorSnackbar}
        />
        <QueryClientProvider client={queryClient}>
          <Router>
            {/* Handle redirection */}
            <RedirectToVN />

            <Routes>
              {/* Default Layout */}
              <Route element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="team-juni" element={<MeetTheTeam />} />
                <Route
                  path="mission-and-vision"
                  element={<MissionAndVision />}
                />
                <Route path="blog" element={<Blog />}>
                  <Route index element={<Navigate to="highlights" replace />} />
                  <Route path="highlights" element={<Highlights />} />
                  <Route path="articles" element={<Outlet />}>
                    <Route index element={<ArticleList />} />
                    <Route path=":slug" element={<Article />} />
                  </Route>
                  <Route path="videos" element={<Videos />} />
                  <Route path="events" element={<Events />} />
                  <Route
                    path="*"
                    element={<Navigate to="/blog/highlights" />}
                  />
                </Route>
                <Route path="pricing" element={<Pricing />} />
                <Route path="become-a-listener" element={<BecomeAListener />} />
                <Route path="contact-us" element={<ContactUs />} />
                <Route path="crisis-helpline" element={<CrisisHelpline />} />
                <Route path="faq" element={<FAQ />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="terms-and-conditions"
                  element={<TermsAndConditions />}
                />
                <Route path="booking" element={<Booking />} />
                <Route path="learn-more" element={<LearnMore />} />
              </Route>

              {/* VN Layout */}
              <Route element={<VnLayout />}>
                <Route path="vn" element={<VnHome />} />
              </Route>

              {/* Fallback Routes */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </QueryClientProvider>
      </trpc.Provider>
    </div>
  );
}

export default App;
