import { useState } from 'react';
import RightArrow from '@assets/icons/shared/circle-arrow-right.svg';
import NormalObject from '@components/NormalObject';

interface HoverCardProps {
  text: string;
  color: string;
  arrowIcon: any;
  // TODO: Update any
  handleClick?: () => void;
}

export function HoverCard({
  text,
  color,
  arrowIcon,
  handleClick,
}: HoverCardProps) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="w-full h-full flex flex-col justify-between p-[20px] md:p-[30px] md:pr-[28px] transition duration-300 ease-in-out cursor-pointer min-h-[180px] md:min-h-[400px]"
      style={{
        backgroundColor: isHovered ? 'black' : color,
        color: isHovered ? color : 'black',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
    >
      {/* TODO: Correct figma - md:5xl */}
      <p className="text-left text-3xl md:text-[36px] md:leading-[45px] font-extrabold">
        {text}
      </p>

      <div className="self-end">
        <NormalObject
          data={isHovered ? arrowIcon : RightArrow}
          className="w-[42px] h-[42px]"
        />
      </div>
    </div>
  );
}
