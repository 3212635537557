import { BlogCategory } from './index';
import SearchIcon from '@assets/footages/blog/search-icon.svg';
import BlogBackground from '@assets/footages/blog/background.svg';
import BlogBackgroundMobile from '@assets/footages/blog/background-mobile.png';
import Chip from '@components/Chip';
import { useNavigate } from 'react-router-dom';
import NormalObject from '@components/NormalObject';

interface SearchSectionProps {
  selectedOption: string;
  setSelectedOption: (value: string) => void;
}

export function SearchSection({
  selectedOption,
  setSelectedOption,
}: SearchSectionProps) {
  const navigate = useNavigate();

  const options = [
    {
      value: BlogCategory.HIGHLIGHT,
      label: 'Highlights',
      pathname: '/blog/highlights',
    },
    {
      value: BlogCategory.ARTICLE,
      label: 'Articles',
      pathname: '/blog/articles',
    },
    {
      value: BlogCategory.VIDEO,
      label: 'Videos & Podcasts',
      pathname: '/blog/videos',
    },
    {
      value: BlogCategory.EVENT,
      label: 'Socials/Events',
      pathname: '/blog/events',
    },
  ];

  return (
    <div className="relative pt-[30px] md:pt-[110px] px-[28px] md:px-[76px]">
      <div
        className="hidden md:block absolute left-0 top-[-180px]"
        style={{ zIndex: -1 }}
      >
        <NormalObject data={BlogBackground} />
      </div>
      <div
        className="md:hidden absolute left-0 top-0 w-full"
        style={{ zIndex: -1 }}
      >
        <NormalObject data={BlogBackgroundMobile} className="w-full" />
      </div>

      <div className="font-extrabold text-6xl md:text-8xl leading-[58px] md:leading-[85px] mb-[20px] md:mb-[40px]">
        Blog
      </div>
      <div className="text-lg md:text-2xl leading-[26px] md:leading-[32px]">
      Your space for exploring about HeyJuni and mental health resources, right at your fingertips!
      </div>

      <div className="mt-[145px] md:mt-[98px] mb-[50px] flex flex-wrap gap-2">
        {options.map((option) => {
          const value = typeof option === 'string' ? option : option?.value;
          const label = typeof option === 'string' ? option : option?.label;

          return (
            // TODO: Can use Link instead of navigate
            <Chip
              key={value}
              text={label as string}
              isSelected={selectedOption === value}
              select={() => {
                navigate(option.pathname);
              }}
            />
          );
        })}
      </div>

      {/* <div className="flex flex-row md:mb-[98px]">
        <div className="flex items-center border-b border-black w-[500px]">
          <input
            type="text"
            placeholder="Search"
            className="border-none outline-none flex-1 p-[5px] text-[16px]"
          />
        </div>
        <span
          style={{ marginLeft: '10px', fontSize: '18px', cursor: 'pointer' }}
          className="flex items-center justify-center"
        >
          <NormalObject data={SearchIcon} />
        </span>
      </div> */}
    </div>
  );
}
