import Button from '@components/Button';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { openWhatsapp } from '@src/utils/open-whatsapp';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// TODO: Check padidng and margin of the page

export default function MissionAndVision() {
  const navigate = useNavigate();
  const { t } = useTranslation('mission-and-vision');

  return (
    <div className="relative pt-[20px] md:py-[94px] px-[28px] md:px-[76px]">
      {/* Add Helmet for SEO */}
      <Helmet>
        <title>Bringing Mental Health Faster and Closer to Everyone</title>
        <meta
          name="description"
          content="We commit to deliver innovative mental health care in the fastest and most convenient way through anonymous and non-judgmental support with professional peer counselors."
        />
        <meta
          name="keywords"
          content="HeyJuni, Mission and Vision, Mental Health, Partners"
        />
        <meta property="og:title" content="Mission & Vision - HeyJuni" />
        <meta
          property="og:description"
          content="Learn about HeyJuni's mission to democratize mental health care and break the stigma around mental health."
        />
      </Helmet>

      {/* TODO: Fix the yellow line */}
      <div className="absolute right-0 top-0" style={{ zIndex: -1 }}>
        <img
          src="mission-and-vision/mission-and-vision-heading.webp"
          alt="Heading Background"
        />
      </div>

      <div className="mb-[120px] md:mb-[100px]">
        <div className="text-5xl md:text-8xl leading-[48px] md:leading-[85px] font-extrabold">
          <div>{t('hero.title-line-1')}</div>
          <div>{t('hero.title-line-2')}</div>
        </div>

        <div className="text-lg md:text-2xl mt-[20px] md:mt-[40px]">
          {t('hero.subtitle')}
        </div>

        <Button
          label={t('hero.call-to-action.button')}
          className="mt-[40px] md:mb-[100px] h-[50px] md:h-[60px] rounded-[20px] md:rounded-[24px] px-[30px] md:px-[30px] font-semibold text-lg md:text-2xl"
          onClick={() => navigate('/contact-us')}
        />
      </div>

      <div className="mx-auto mb-[120px] md:mb-[100px]">
        <div className="mb-[40px] w-full">
          <img
            src="mission-and-vision/team-members.webp"
            alt="A collage of different smiling faces of members of the team HeyJuni. "
            className="w-full"
          />
        </div>

        <div>
          <div className="md:pt-[20px] mb-[20px] md:mb-[32px] font-extrabold text-5xl md:text-7xl leading-[48px] md:leading-[65px]">
            {t('mission-and-vision.title')}
          </div>
          <div className="md:text-lg md:leading-[26px]">
            <p className="pb-[20px]">{t('mission-and-vision.content.p1')}</p>
            <p className="pb-[20px]">{t('mission-and-vision.content.p2')}</p>
          </div>
        </div>

        <div>
          <div className="md:pt-[20px] mb-[20px] md:mb-[32px] font-extrabold text-5xl md:text-7xl leading-[48px] md:leading-[65px]">
            {t('our-story.title')}
          </div>
          <div className="md:text-lg md:leading-[26px]">
            <p className="pb-[20px]">{t('our-story.content.p1')}</p>
            <p className="pb-[20px]">{t('our-story.content.p2')}</p>
            <p className="md:pb-[20px]">
              <span className="font-bold">
                {t('our-story.content.nhung-story.title')}:{' '}
              </span>
              {t('our-story.content.nhung-story.content')}
            </p>
            <p className="md:pb-[20px]">
              <span className="font-bold">
                {t('our-story.content.linh-story.title')}:{' '}
              </span>
              {t('our-story.content.linh-story.content')}
            </p>
            <p className="md:pb-[20px]">
              <span className="font-bold">
                {t('our-story.content.nora-story.title')}:{' '}
              </span>
              {t('our-story.content.nora-story.content')}
            </p>
            <p className="md:pb-[20px]">
              <span className="font-bold">
                {t('our-story.content.why.title')}:{' '}
              </span>
              {t('our-story.content.why.content')}
            </p>
          </div>
        </div>
      </div>

      <div className="text-center mb-[120px] md:mb-0">
        <div className="md:pt-[20px] mb-[40px] md:mb-[64px] font-extrabold text-5xl md:text-7xl leading-[48px] md:leading-[65px]">
          Our Partners
        </div>
        {/* TODO: Separate images here */}
        {/* TODO: Fix this later */}
        <div className="mb-[40px] md:mb-[64px]">
          <div className="flex justify-center mb-[96px] md:mb-[80px]">
            <PartnerItem
              title="Organizations"
              img="mission-and-vision/organizations.webp"
              description="We have the pleasure to work with these organizations to help grow our mission together."
              className="md:w-[510px]"
              alt="Moulmein Cairnhill Network & Youth Corps"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[96px] md:gap-y-0 md:gap-x-[40px]">
            <PartnerItem
              title="Businesses"
              img="mission-and-vision/businesses.webp"
              description="Some businesses we have the privilege to collaborate on projects and aid in building HeyJuni together."
              alt="Gibson Dunn, Huber’s Gourmet and Supermarkets, Scent By Six & Le Petit Society"
            />
            <PartnerItem
              title="University Clubs"
              img="mission-and-vision/university.webp"
              description="We proudly sponsor diverse halls and clubs from universities across Singapore."
              alt="Clubs from National University of Singapore, Clubs from Nayang Technical University & Clubs from Singapore Management University"
            />
          </div>
        </div>
        <Button
          label="Partner with Us"
          className="md:mb-[20px] h-[50px] md:h-[60px] rounded-[20px] md:rounded-[24px] px-[30px] md:px-[30px] font-semibold text-lg md:text-2xl"
          onClick={openWhatsapp}
        />
      </div>
    </div>
  );
}

interface PartnerItemProps {
  title: string;
  img: string;
  description: string;
  className?: string;
  alt?: string;
}

function PartnerItem(props: PartnerItemProps) {
  const { title, img, description, className, alt } = props;

  return (
    <div className={clsx('text-center', className)}>
      <div className="mb-[16px] md:mb-[24px] font-bold text-xl md:text-4xl md:leading-[40px]">
        {title}
      </div>
      <div className="w-full">
        <img src={img} alt={alt} className="mx-auto" />
      </div>
      <div className="mt-[20px] md:text-lg md:leading-[26px]">
        {description}
      </div>
    </div>
  );
}
