import backgroundImageMobile from '@assets/footages/learn-more/intro-mobile.png';
import Button from '@components/Button';
import { useNavigate } from 'react-router-dom';
import { openWhatsapp } from '@src/utils/open-whatsapp';

export function IntroSection() {
  const navigate = useNavigate();

  return (
    <section className="relative bg-white mb-[64px] md:mb-[40px]">
      <div
        className="hidden md:block absolute inset-0 bg-no-repeat bg-cover z-0"
        style={{ backgroundImage: `url(learn-more/learn-more-heading.webp)` }}
      ></div>

      <div className="md:hidden absolute left-0 right-0">
        <img
          src={backgroundImageMobile}
          className="w-full"
          alt="Background Image Mobile"
        />
      </div>

      {/* TODO: Will refactor, create a component for this: desktop and mobile img */}

      <div className="relative z-10 px-[25px] py-[20px] md:px-[76px] md:py-[94px]">
        <div className="md:max-w-[800px] flex flex-col gap-[20px] md:gap-[40px]">
          <h1 className="font-extrabold leading-[58px] md:leading-[85px] text-6xl md:text-8xl">
            Talking Is Therapy for the Soul
          </h1>

          <div className="font-bold text-xl md:font-semibold md:text-3xl">
            And you don't have to do it alone. We Are Your Safe Space to Talk
            Your Heart Out and Feel Heard.
          </div>
          <div className="font-regular text-lg md:font-regular md:text-2xl">
            Online mental health support is effective for emotional healing, and
            HeyJuni is committed to providing immediate, compassionate care for
            women experiencing loneliness, anxiety, or depression caused by
            hardships and trauma in life. In an anonymous online space, you'll
            be matched with trained, vetted female Peer Counselors through video
            calls, chats, or messaging. These empathetic professionals create a
            safe environment where you can share your pain, find relief, and
            build resilience.
          </div>

          <div>
            <Button
              label="Chat For More Info"
              className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
              onClick={openWhatsapp}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
