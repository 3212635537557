import { useNavigate } from 'react-router-dom';
import CoreTeam from './CoreTeam';
import ExecutiveMember from './ExecutiveMember';
import Button from '@components/Button';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { CORE_MEMBERS, SPECIAL_THANKS_MEMBERS } from './TeamList';

const HeartPeople = [
  'meet-the-team/heart-person-0.webp',
  'meet-the-team/heart-person-1.webp',
  'meet-the-team/heart-person-2.webp',
  'meet-the-team/heart-person-3.webp',
  'meet-the-team/heart-person-4.webp',
  'meet-the-team/heart-person-5.webp',
  'meet-the-team/heart-person-6.webp',
  'meet-the-team/heart-person-7.webp',
  'meet-the-team/heart-person-8.webp',
  'meet-the-team/heart-person-9.webp',
];

const SpecialThanksPeople = [
  'meet-the-team/special-thanks-person-1.webp',
  'meet-the-team/special-thanks-person-2.webp',
  'meet-the-team/special-thanks-person-3.webp',
  'meet-the-team/special-thanks-person-4.webp',
];

export default function MeetTheTeam() {
  const navigate = useNavigate();
  const gotoContactUs = () => {
    navigate('/contact-us');
  };
  const { t } = useTranslation('meet-the-team');

  const executiveMember = [
    {
      videoId: '1025694435',
      thumbnail: 'meet-the-team/executive-01.webp',
      name: t('executive.nhung-nguyen.name'),
      title: t('executive.nhung-nguyen.title'),
      about: t('executive.nhung-nguyen.about'),
      alt: t('alt.meet-the-team.nhung', { ns: 'common' }),
    },
    {
      videoId: '1025696593',
      thumbnail: 'meet-the-team/executive-03.webp',
      name: t('executive.linh-nguyen.name'),
      title: t('executive.linh-nguyen.title'),
      about: t('executive.linh-nguyen.about'),
      alt: t('alt.meet-the-team.linh', { ns: 'common' }),
    },
    {
      videoId: '1025697166',
      thumbnail: 'meet-the-team/executive-04.webp',
      name: t('executive.nora-liu.name'),
      title: t('executive.nora-liu.title'),
      about: t('executive.nora-liu.about'),
      alt: t('alt.meet-the-team.nora', { ns: 'common' }),
    },
  ];
  const coreMembers = [];

  return (
    <>
      {/* Add Helmet for SEO */}
      <Helmet>
        <title>HeyJuni Team: Meet our Doctors, Therapists & Advocates</title>
        <meta
          name="description"
          content="Our team is committed to making mental health care accessible and inclusive for all women, driven by a shared vision and passion for mental well-being."
        />
        <meta
          name="keywords"
          content="HeyJuni, Meet the Team, Mental Health, HeyJuni Team"
        />
        <meta property="og:title" content="Meet the Team - HeyJuni" />
        <meta
          property="og:description"
          content="Discover the passionate team working to make mental health care inclusive and accessible for everyone."
        />
      </Helmet>

      <div className="relative pt-[30px] md:pt-[100px] px-[28px] md:px-[76px]">
        <div className="absolute right-0 top-0" style={{ zIndex: -1 }}>
          <img src="meet-the-team-heading.webp" alt="Heading Background" />
        </div>

        <div
          className="text-6xl md:text-8xl leading-[58px] md:leading-[85px] font-extrabold"
          dangerouslySetInnerHTML={{ __html: t('title') }}
        ></div>

        <div className="md:text-2xl mt-[20px] md:mt-[40px] max-w-[796px]">
          {t('subtitle')}
        </div>

        <div className="mt-[80px] mb-[40px] py-[20px] md:my-[80px] md:py-[40px] grid grid-cols-1 md:grid-cols-1 md:gap-x-[52px] lg:gap-x-[76px] gap-y-[64px] md:gap-y-[80px]">
          {executiveMember.map((member) => (
            <div key={member.name} className="w-full">
              <ExecutiveMember
                placeholderImage={member.thumbnail}
                name={member.name}
                videoId={member.videoId}
                title={member.title}
                about={member.about}
                alt={member.alt}
              />
            </div>
          ))}
        </div>

        <div className="pb-[20px] md:pb-[40px] mb-[40px] md:mb-[96px] grid grid-cols-1 md:grid-cols-2 md:gap-[64px]">
          <div className="md:hidden mb-[20px] text-center font-extrabold text-[32px] leading-[36px]">
            Our Advisor
          </div>


          {/* Text Content Section */}
          <div>
            <h2 className="hidden md:block mb-[5px] md:mb-[20px] text-[28px] md:text-7xl leading-[36px] md:leading-tight font-extrabold">
              Our Advisor
            </h2>
            <h3 className="hidden md:block mb-[5px] md:mb-[10px] text-[12px] md:text-6xl leading-[20px] md:leading-tight font-semibold md:font-extrabold">
              Professor Simon Collinson
            </h3>
            <h3 className="md:hidden font-extrabold text-[28px] leading-[36px]">
              Simon Collinson
            </h3>
            <p className="mb-[20px] mt-[5px] md:mt-[28px] md:mb-[28px] md:text-4xl md:leading-normal font-semibold">
              Professor of Psychology and Digital Health at James Cook
              University | Visiting Professor at the National University of
              Singapore
            </p>

            <p className="text-[15px] md:text-2xl leading-[20px] md:leading-normal">
              Professor Simon Collinson is an expert in neuropsychology and
              digital health with over 20 years of experience. He is a Professor
              of Psychology at James Cook University, specializing in brain
              health and digital innovation, and a Visiting Professor at NUS's
              Yeo Boon Khim Mind Science Centre. His career spans academia and
              industry, including a role as Chief Scientific Officer at Savonix,
              where he led digital health projects. As a Consultant
              Neuropsychologist, he specializes in brain injury, dementia, and
              adult ADHD. A recognized thought leader, Professor Collinson
              bridges research and practice to enhance mental health.
            </p>
          </div>
          <div className="w-full">
            <img
              src="advisor.webp"
              alt="Professor Simon Collinson"
              className="mb-[20px] md:mb-0 w-full h-auto object-cover"
            />
          </div>
        </div>

        <div className="pt-[20px] mb-[40px] text-center font-extrabold text-[28px] md:text-7xl md:leading-[65px]">
          The Heart of HeyJuni
        </div>
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-x-[20px] md:gap-x-[72px] gap-y-[40px] md:gap-y-[80px] pb-[20px] mb-[40px] md:pb-[69px] md:mb-[96px]">
          {CORE_MEMBERS.map((member, index) => (
            <CoreTeam
              avatar={HeartPeople[index]}
              name={member.name}
              title={member.title}
            />
          ))}
        </div>

        <div className="pt-[20px] mb-[40px] text-center font-extrabold text-[28px] md:text-7xl md:leading-[65px]">
          Special Thanks
        </div>
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-x-[20px] md:gap-x-[72px] gap-y-[40px] md:pb-0 md:mb-0">
          {SPECIAL_THANKS_MEMBERS.map((member, index) => (
            <CoreTeam
              avatar={SpecialThanksPeople[index]}
              name={member.name}
              title={member.title}
            />
          ))}
        </div>
      </div>

      <div className="bg-[#181818] flex flex-col justify-center items-center p-[50px] md:py-[80px] mt-[60px] md:mt-[200px]">
        <p className="text-5xl md:text-7xl leading-[48px] md:leading-[65px] font-extrabold text-white text-center">
          Be a part of our team
        </p>
        <Button
          label="Join Our Team"
          onClick={gotoContactUs}
          className="mt-[30px] md:mt-[40px] rounded-[20px] md:rounded-[24px] h-[50px] md:h-[60px] px-[30px] md:px-[30px] font-semibold text-lg md:text-2xl"
        />
      </div>
    </>
  );
}
