import React, { useState } from 'react';
import { OutcomeExpectation } from '@source/types';
import { useBookingContext } from './BookingContext';
import Option from './components/Option';

// Updated options based on the image
const options = [
  {
    label: 'I want to open up and talk about what’s happening to me.',
    value: OutcomeExpectation.OPEN_UP,
  },
  {
    label: 'I need someone to help me calm my worries and anxiousness.',
    value: OutcomeExpectation.HELP_TO_CALM,
  },
  {
    label: 'I want to learn techniques to regulate my emotions.',
    value: OutcomeExpectation.LEARN_TECHNIQUES,
  },
  {
    label: 'I need encouragement and someone to validate how I feel.',
    value: OutcomeExpectation.NEED_ENCOURAGEMENT,
  },
  {
    label: 'I want to understand what triggers my emotions and problems.',
    value: OutcomeExpectation.UNDERSTAND_TRIGGERS,
  },
  {
    label: 'Other',
    value: OutcomeExpectation.OTHER,
  },
];

export function Step06() {
  const { setCurrentStep, dataList06, setDataList06 } = useBookingContext();

  return (
    <>
      <div className="booking-step-title">
      What do you hope to achieve MOST by talking with a Peer Counselor today?
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-2 gap-[10px] md:gap-x-[15px] md:gap-y-[20px] mt-[30px] md:mt-[40px]">
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            label={option.label}
            isSelected={dataList06.includes(option.value)}
            onClick={() => {
              setDataList06([option.value]);
            }}
            themeColor="md:bg-[#D8CFFC]"
            mobileThemeColor="bg-[#D8FFF3]"
            // height="md:h-[90px]"
            height="md:h-[80px]" // Change to fit the screen
            mobileHeight="h-[110px]"
            shadow="md:shadow-[0px_4px_8px_rgba(0,0,0,0.25)]"
          />
        ))}
      </div>
    </>
  );
}
