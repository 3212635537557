import z from 'zod';

export const PasswordLoginInputSchema = z.object({
    email: z.string().email(),
    password: z.string(),
});
export type PasswordLoginInput = z.infer<typeof PasswordLoginInputSchema>;

export const RefreshTokenLoginInputSchema = z.object({
    refreshToken: z.string(),
});
export type RefreshTokenLoginInput = z.infer<typeof RefreshTokenLoginInputSchema>;

export const LoginOutputSchema = z.object({
    accessToken: z.string(),
    refreshToken: z.string(),
});
export type LoginOutput = z.infer<typeof LoginOutputSchema>;

// Including role to use for creating token, no need for second query
export const UserPasswordHashSchema = z.object({
    userId: z.number(),
    role: z.number(),
    passwordHash: z.string(),
    isActive: z.boolean(),
});
export type UserPasswordHash = z.infer<typeof UserPasswordHashSchema>;
