import { useState } from 'react';
import { PopUp } from '@components/Popup';
import instagramIcon from '@assets/footages/blog/instagram-icon.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { shuffleArray } from '@src/utils/array';

const postsRaw = [
  {
    id: 'DD0mncASAHK',
    embedUrl: 'https://www.instagram.com/p/DD0mncASAHK/',
    thumbnail: 'blog/instagram-thumbnails/post01.png',
    highlightThumbnail: 'blog/instagram-thumbnails/post01-highlight.png',
    postAt: '...',
    title: `Who exactly are the HeyJuni Counselors? And what makes them special?`,
    content: `This video will give you all the answers you need to these questions - and more! We hope that it will help you understand HeyJuni and our Peer Counselors model, especially in helping you through your trauma with sexual violence.`,
  },
  {
    id: 'DDxQomTydvD',
    embedUrl: 'https://www.instagram.com/p/DDxQomTydvD/',
    thumbnail: 'blog/instagram-thumbnails/post02.png',
    highlightThumbnail: 'blog/instagram-thumbnails/post02-highlight.png',
    postAt: '...',
    title: 'Are you struggling to cope with your trauma and anxiety from SA/SV',
    content: `Confide in our Counselors in a safe, judgment-free space where you can share, find guidance, and ease your heavy feelings when you need it the most. Whether it's late at night, or you're curled up in bed at 12 a.m., we're here, ready to listen and be there for you.`,
  },
  {
    id: 'DAqSGC1yRX0',
    embedUrl: 'https://www.instagram.com/p/DAqSGC1yRX0/',
    thumbnail: 'blog/instagram-thumbnails/post03.png',
    highlightThumbnail: 'blog/instagram-thumbnails/post03-highlight.png',
    postAt: '...',
    title:
      'We wanted to take a moment to share how you can book a session with Heyjuni and what you can expect from our amazing trained listeners.',
    content: `We know that life can be overwhelming, and sometimes, you just need someone to talk to. At Heyjuni, we're here to provide virtual emotional support tailored just for you. Whether you're navigating anxiety, feeling lost, or just need a anonymous safe space to share, our listeners are ready to help. `,
  },
  {
    id: 'DEJKZG1yE7u',
    embedUrl: 'https://www.instagram.com/p/DEJKZG1yE7u/',
    thumbnail: 'blog/instagram-thumbnails/post04.png',
    highlightThumbnail: 'blog/instagram-thumbnails/post04-highlight.png',
    postAt: '...',
    title: `Feeling powerless in your healing journey? You're not alone. Let's reclaim your power together. 💪`,
    content: `Healing from trauma isn't linear, and it's normal to feel powerless, fearful, or disconnected along the way. But remember—these feelings don't define who you are. You have the strength, resilience, and courage to reclaim your power, step by step. 💛`,
  },
  {
    id: 'DDHuhPSyejg',
    embedUrl: 'https://www.instagram.com/p/DDHuhPSyejg/',
    thumbnail: 'blog/instagram-thumbnails/post05.jpg',
    highlightThumbnail: 'blog/instagram-thumbnails/post05-highlight.jpg',
    postAt: '...',
    title: `Why didn't you fight back?' Because trauma doesn't work that way. Let's talk about it.🤔❤️`,
    content: `Survivors of sexual violence often hear hurtful questions like ‘Why didn't you fight back?' But the reality is, trauma responses are automatic—they're about survival, not choice.`,
  },
  {
    id: 'DDKYiagyxah',
    embedUrl: 'https://www.instagram.com/p/DDKYiagyxah/',
    thumbnail: 'blog/instagram-thumbnails/post06.jpg',
    highlightThumbnail: 'blog/instagram-thumbnails/post06-highlight.jpg',
    postAt: '...',
    title: `Overcoming Trauma From SA/SV is a Journey of...`,
    content: `Self-discovery, resilience, patience, exploring emotions, and courage. It's also about allowing these emotions to be heard and validated. Don't let buried pain shape your life and future. Take that first step toward a life of confidence, connection, and self-love. 🌟 Swipe through to discover how you can begin.`,
  },
  {
    id: 'DDXQhhky6KB',
    embedUrl: 'https://www.instagram.com/p/DDXQhhky6KB/',
    thumbnail: 'blog/instagram-thumbnails/post07.jpg',
    highlightThumbnail: 'blog/instagram-thumbnails/post07-highlight.jpg',
    postAt: '...',
    title: `💔 Let's Break the Cycle of Stigma. Let's Speak About Our Experiences. 💬`,
    content: `"Maybe it's my fault..." 🚨 This statement and thought that reflect INTERNAL STIGMA could be the results of external stigma, victim-blaming, and shaming that survivors often face after SV/SA.`,
  },
  {
    id: 'DB79uC2Sl9B',
    embedUrl: 'https://www.instagram.com/p/DB79uC2Sl9B/',
    thumbnail: 'blog/instagram-thumbnails/post08.jpg',
    highlightThumbnail: 'blog/instagram-thumbnails/post08-highlight.jpg',
    postAt: '...',
    title: `This is what trauma feels like 😨`,
    content: `Do you sometimes feel overwhelmed with negative emotions out of the blue? You might be experiencing an emotional flashback from the trauma of sexual assault. Trauma is more than a memory.`,
  },
  {
    id: 'C76SRwuSNAg',
    embedUrl: 'https://www.instagram.com/p/C76SRwuSNAg/',
    thumbnail: 'blog/instagram-thumbnails/post09.jpg',
    highlightThumbnail: 'blog/instagram-thumbnails/post09-highlight.jpg',
    postAt: '...',
    title: `Sometimes, therapy might not feel like the right fit for everyone.`,
    content: `Listening services and peer support can offer a more approachable, affordable, and immediate way to find understanding and relief. These services provide a safe space to share your feelings and be heard without judgment, often making it easier to open up and find comfort.`,
  },
  {
    id: 'C776QAfyekE',
    embedUrl: 'https://www.instagram.com/p/C776QAfyekE/',
    thumbnail: 'blog/instagram-thumbnails/post10.jpg',
    highlightThumbnail: 'blog/instagram-thumbnails/post10-highlight.jpg',
    postAt: '...',
    title: `Introducing another awesome member 🎉🎉🎉`,
    content: `Meet Malia, the Head of Product at JUNI. Her profound commitment and passion for mental health fuels her mission to create a platform that helps people navigate their emotional struggles. Malia is incredibly proud of JUNI and the amazing team she's part of. 🌟`,
  },
  {
    id: 'C8N6oway3AL',
    embedUrl: 'https://www.instagram.com/p/C8N6oway3AL/',
    thumbnail: 'blog/instagram-thumbnails/post11.jpg',
    highlightThumbnail: 'blog/instagram-thumbnails/post11-highlight.jpg',
    postAt: '...',
    title: `✨ Meet our amazing member Aishah! ✨`,
    content: `We‘re excited to introduce our incredible Domain advisor and Trainer, Aishah. An expert in counseling and psychology, Aishah has been with us since Day 1, enriching our training system with her extensive experience and heartfelt dedication. We're deeply grateful to have her on our team. 🌟`,
  },
  {
    id: 'C8f4w18yHM5',
    embedUrl: 'https://www.instagram.com/p/C8f4w18yHM5/',
    thumbnail: 'blog/instagram-thumbnails/post12.jpg',
    highlightThumbnail: 'blog/instagram-thumbnails/post12-highlight.jpg',
    postAt: '...',
    title: `✨ Meet Our Head of Domain, Nora! ✨`,
    content: `We are thrilled to introduce Nora, an expert in counseling and psychology, and a cornerstone of JUNI since day one. As the Clinical Head, Nora provides invaluable management of our Domain and leads our Listeners team.`,
  },
  {
    id: 'C7lLD5XNrvs',
    embedUrl: 'https://www.instagram.com/p/C7lLD5XNrvs/',
    thumbnail: 'blog/instagram-thumbnails/post13.jpg',
    highlightThumbnail: 'blog/instagram-thumbnails/post13-highlight.jpg',
    postAt: '...',
    title: `Whether you're managing stress from the last semester or preparing for the next`,
    content: `this summer break is the perfect time for reflection, self-care, and growth! 🌞`,
  },
  {
    id: 'C7TZq9CSIUm',
    embedUrl: 'https://www.instagram.com/p/C7TZq9CSIUm/',
    thumbnail: 'blog/instagram-thumbnails/post14.jpg',
    highlightThumbnail: 'blog/instagram-thumbnails/post14-highlight.jpg',
    postAt: '...',
    title: `When you're facing tough times and life obstacles, do you keep the burden to yourself or talk it out with someone you can trust?`,
    content: `If you keep it in, what's holding you back from sharing? If you share, what have you found?`,
  },
  {
    id: 'C6LPdVRxoyZ',
    embedUrl: 'https://www.instagram.com/p/C6LPdVRxoyZ/',
    thumbnail: 'blog/instagram-thumbnails/post15.jpg',
    highlightThumbnail: 'blog/instagram-thumbnails/post15-highlight.jpg',
    postAt: '...',
    title: `Let's strive to cultivate a mindset that prioritizes positive experiences over negative ones, nurturing emotional and mental resilience.`,
    content: `To allow our psychological immune system to kick in, become stronger, and be less prone to stress and burnout, we need to move away from this negativity. Instead of feeling trapped, it's useful to activate the mind to change the brain and developing healthy habits and patterns we can turn to in stressful situations.`,
  },
  {
    id: 'C4f5ZQWSenN',
    embedUrl: 'https://www.instagram.com/p/C4f5ZQWSenN/',
    thumbnail: 'blog/instagram-thumbnails/post16.jpg',
    highlightThumbnail: 'blog/instagram-thumbnails/post16-highlight.jpg',
    postAt: '...',
    title: `🌟🌟Have you ever had something on your mind but didn't know who to talk to?`,
    content: `Sometimes, sharing your thoughts with those around you requires a lot of courage because you worry about how others might judge you. Sometimes, all you need is someone with listening ears👂👂We recognize the challenges you may face and we understand your situation.🌸`,
  },
  {
    id: 'DCWZxOdSl6Z',
    embedUrl: 'https://www.instagram.com/p/DCWZxOdSl6Z/',
    thumbnail: 'blog/instagram-thumbnails/post17.jpg',
    highlightThumbnail: 'blog/instagram-thumbnails/post17-highlight.jpg',
    postAt: '...',
    title: `The Anxiety Iceberg: What Lies Beneath the Surface`,
    content: `Anxiety is more than what meets the eye. People only see the tip of the iceberg - behaviors that might seem impulsive, avoidant, or even explosive. But beneath the surface lies a deeper struggle: feelings of vulnerability, fear, shame, and loneliness that most never realize. Let's remember to approach each other with empathy, as everyone is carrying more than what meets the eye. 🧊💙`,
  },
  {
    id: 'DAGFNpDS2oO',
    embedUrl: 'https://www.instagram.com/p/DAGFNpDS2oO/',
    thumbnail: 'blog/instagram-thumbnails/post18.jpg',
    highlightThumbnail: 'blog/instagram-thumbnails/post18-highlight.jpg',
    postAt: '...',
    title: `Healing from a Toxic Upbringing: My Journey to Self-Worth 💖✨`,
    content: `Growing up with a narcissistic parent can deeply impact self-worth. Explore the journey of overcoming self-doubt and embracing inner peace, recognizing that their inability to love isn't your fault. Discover the path to self-acceptance and reclaiming your true worth.`,
  },
  {
    id: 'DD8pWdCK3kd',
    embedUrl: 'https://www.instagram.com/p/DD8pWdCK3kd/',
    thumbnail: 'blog/instagram-thumbnails/post19.jpg',
    highlightThumbnail: 'blog/instagram-thumbnails/post19-highlight.jpg',
    postAt: '...',
    title: `5 Signs You're Experiencing Narcissistic Abuse`,
    content: `When you're deeply involved in a relationship, it's easy to lose sight of your sense of self and see things through a distorted lens.`,
  },
];
export const posts = shuffleArray(postsRaw);

export function Events() {
  const [page, setPage] = useState<number>(1);
  const [searchParams] = useSearchParams();
  const postId = searchParams.get('id');

  return (
    <section className="px-[28px] md:px-[76px]">
      <h2 className="text-5xl md:text-7xl font-extrabold leading-[48px] md:leading-[65px] mb-[40px]">
        Socials/Events
      </h2>

      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-[64px]">
        {posts.map((post, index) => (
          <PostCard
            key={index}
            embedUrl={post.embedUrl}
            thumbnail={post.thumbnail}
            postAt={post.postAt}
            isSelected={post.id === postId}
          />
        ))}
      </div>

      <div className="mt-[64px] mb-[98px] flex justify-center">
        {/* <TruncatedPagination
          pageSize={10}
          value={page}
          onChange={setPage}
          maxValue={4}
        /> */}
      </div>
    </section>
  );
}

interface PostCardProps {
  embedUrl: string;
  thumbnail: string;
  postAt: string;
  isSelected: boolean;
}

function PostCard(props: PostCardProps) {
  const { embedUrl, thumbnail, postAt, isSelected } = props;
  const [isOpenPost, setIsOpenPost] = useState(isSelected);
  const navigate = useNavigate();

  return (
    <>
      <div
        className="w-full cursor-pointer relative"
        onClick={() => setIsOpenPost(true)}
      >
        <div className="relative w-full aspect-square">
          <img
            className="absolute top-0 left-0 w-full h-full object-cover"
            src={thumbnail}
            alt="Post Thumbnail"
          />
        </div>
        <div className="absolute left-0 right-0 bottom-[20px] text-white w-full px-[20px] flex justify-between">
          <div>
            <p className="text-lg font-semibold">@heyjuni_official</p>
            <p className="text-sm">{postAt}</p>
          </div>
          <div>
            <img src={instagramIcon} alt="Instagram Icon" />
          </div>
        </div>
      </div>

      <PopUp
        isOpen={isOpenPost}
        onClose={() => {
          setIsOpenPost(false);

          // Remove the id query parameter
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.delete('id');
          navigate({ search: searchParams.toString() }, { replace: true });
        }}
        className="flex justify-center items-center !rounded-[0px]"
        isGlobalOverlap={true}
      >
        <div
          className="relative bg-black"
          style={{
            height: '90vh', // Fixed height
            width: 'calc(80vh * (9 / 16))', // Dynamically calculate width to maintain 9:16 ratio
          }}
        >
          <iframe
            className="absolute top-0 left-0 w-full h-full"
            src={`${embedUrl}embed`}
            title="Instagram post embed"
            allow="encrypted-media"
            allowTransparency
            frameBorder="0"
            scrolling="no"
          ></iframe>
        </div>
        {/* Responsive styles for smaller screens */}
        {/* <style>{`
          @media (max-width: 768px) {
            div.relative {
              width: calc(100vw - 32px) !important;
              height: calc((100vw - 32px) * (9 / 16)) !important; 
            }
          }
        `}</style> */}
      </PopUp>
    </>
  );
}
