export interface ClickableObjectProps {
  data: string;
  className?: string;
  onClick?: React.MouseEventHandler;
}

export default function ClickableObject({
  data,
  className,
  onClick,
}: ClickableObjectProps) {
  return (
    <div onClick={onClick} className="cursor-pointer">
      <object
        data={data}
        className={className}
        style={{ pointerEvents: 'none' }}
      >
        Your browser does not support displaying this object.
      </object>
    </div>
  );
}
