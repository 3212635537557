import React, { useEffect, useState } from 'react';
import Chip from './Chip';
import { useNavigate } from 'react-router-dom';

type Option = {
  value: string;
  label: string;
};

interface ChipRadioProps {
  options: string[] | Option[];
  selectedOption: string;
  pathname: string;
}

export default function ChipRadio({
  options,
  selectedOption,
  pathname,
}: ChipRadioProps) {
  const navigate = useNavigate();

  const handleSelect = (option: string) => () => {
    navigate(`${pathname}?selected=${option}`);
  };

  return (
    <div className="flex flex-wrap gap-2">
      {options.map((option) => {
        const value = typeof option === 'string' ? option : option?.value;
        const label = typeof option === 'string' ? option : option?.label;

        return (
          <Chip
            key={value}
            text={label as string}
            isSelected={selectedOption === value}
            select={handleSelect(value)}
          />
        );
      })}
    </div>
  );
}
