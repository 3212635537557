import heading from '@assets/footages/terms-and-conditions/heading.svg';
import headingMobile from '@assets/footages/terms-and-conditions/heading-mobile.svg';
import NormalObject from '@components/NormalObject';
import { Helmet } from 'react-helmet';

export default function PrivacyPolicy() {
  return (
    <>
      {/* SEO Metadata */}
      <Helmet>
        <title>HeyJuni - Privacy Policy</title>
        <meta
          name="description"
          content="Learn about how HeyJuni collects, uses, and protects your personal information. Read our privacy policy for detailed information."
        />
        <meta
          name="keywords"
          content="HeyJuni, privacy policy, personal data, data protection, user confidentiality"
        />
        <meta property="og:title" content="Privacy Policy - HeyJuni" />
        <meta
          property="og:description"
          content="Understand how HeyJuni handles and safeguards your personal information."
        />
      </Helmet>
      <div className="relative pt-[58px] md:pt-[90px] px-[28px] md:px-[76px] pb-[150px]">
        <div
          className="hidden md:block absolute left-0 top-0"
          style={{ zIndex: -1 }}
        >
          <NormalObject data={heading} className="object-cover" />
        </div>

        <div
          className="md:hidden absolute left-0 top-0 w-full transform -translate-y-[62px]"
          style={{ zIndex: -1 }}
        >
          <NormalObject data={headingMobile} className="w-full object-cover" />
        </div>

        <div className="text-6xl md:text-8xl font-extrabold leading-[58px] md:leading-[85px]">
          Privacy Policy
        </div>
        <div className="mt-[80px] md:mt-[144px] text-[15px] md:text-lg">
          <p>
            HeyJuni ("HeyJuni," "we," "us" and "our") respects your privacy and
            is committed to protecting it through this privacy policy
            ("Policy"). HeyJuni operates the Platform https://heyjuni.com.
            Privacy is extremely important to us, so we have taken several steps
            to protect your information and put you in control.
          </p>
          <br />
          <p>
            PLEASE REVIEW THIS PRIVACY POLICY CAREFULLY. As a user of this
            Platform, this policy outlines what information we may collect about
            you, how we use and share that information, how you may access your
            information and your choices about the use and disclosure of your
            information. By accessing, visiting, or using our Platform, you
            acknowledge this policy and agree to our Terms of Service (the
            "Terms"). If you are not comfortable with any part of this policy or
            do not agree with the Terms, please immediately discontinue access
            or use of our Platform.
          </p>
          <br />
          <p className="text-2xl font-bold">General</p>
          <br />
          <p>By using this Platform, you agree to the following:</p>
          <ol className="list-decimal list-inside inline-block ">
            <li>
              You will not reveal your personal identity (i.e. full name, PIN
              number, phone number, email address, social media or other
              identifiable information).
            </li>
            <li>
              You will not ask for a Speaker or Peer Counselor's personal
              identity.
            </li>
            <li>
              You will not share, screenshot or distribute conversations
              conducted on the Platform.
            </li>
          </ol>
          <br />
          <br />
          <p className="text-2xl font-bold">
            Collection of Personal Information
          </p>
          <br />
          <p>
            We may collect personal information which relates to an individual
            such as personal details (including your age, gender, phone number,
            and email address) or any information which you have provided in
            registration/application forms or any other similar forms and/or any
            information about you that has been or may be collected, stored,
            used and processed by HeyJuni from time to time. In addition, we may
            from time to time request certain other personal information which
            may be relevant for the purposes of providing our services to you.
            Personal Information that has been anonymized is not Personal
            Information as it does not allow for a specific individual to be
            identified. If you provide any personal information relating to a
            minor, you warrant that you are the parent or legal guardian of such
            minor and have the necessary authority to provide such information
            and the applicable consents.
          </p>
          <br />
          <p>
            We may provide additional "just-in-time" disclosures or additional
            information about our Personal Information collection, use and
            sharing practices. These notices may supplement this Privacy Policy,
            clarify our privacy practices in the circumstances described, or
            provide you with additional choices about how we process your
            personal information.
          </p>
          <br />
          <p>
            While the provision of personal information is not obligatory, it is
            required in connection with the purposes as stated below, and if we
            do not have this information we may not be able to carry out the
            purposes or provide our services to you.
          </p>
          <br />
          <br />
          <p className="text-2xl font-bold">Use of Personal Information</p>
          <br />
          <p>
            We may use your personal information for any of the following
            reasons:
          </p>
          <br />
          <ol className="list-disc list-inside inline-block">
            <li>
              To provide services or to perform any of our obligations towards
              you
            </li>
            <li>
              To process, manage or verify your request or application for any
              services
            </li>
            <li>
              To validate and/or process payments, rebates, refunds and charges
            </li>
            <li>
              To send you alerts, updates, mailers, promotional materials and
              the like from HeyJuni, our partners, advertisers or sponsors and
              to communicate with you about services/products that may be of
              interest to you from us, partners or other third parties
            </li>
            <li>To facilitate or enable any checks as may be required</li>
            <li>
              To manage our business(such as a merger, reorganization,
              consolidation, restructuring, bankruptcy, sale of substantially
              all interests or assets, and other similar transaction) and for
              internal purposes (such as auditing, data analysis, database
              records, evaluating our services and effectiveness of our
              marketing efforts, research and risk management)
            </li>
            <li>To comply with legal and regulatory requirements</li>
            <li>To enforce our legal rights or obtain legal advice</li>
            <li>
              To perform functions described at the time of collection of the
              personal information
            </li>
            <li>To respond to questions, comments and feedback from you</li>
            <li>
              To communicate with you for any of the purposes listed in this
              Policy
            </li>
            <li>
              For any other purpose that is ancillary to or in furtherance of
              these above purposes or which is required by law or any regulatory
              authorities or where we have your permission to do so
            </li>
          </ol>
          <br />
          <br />
          <p className="text-2xl font-bold">
            Cookies, analytics and automatic collection of Information
          </p>
          <br />
          <p>
            We do not collect cookies through this Platform. Your preferences
            are obtained through information that you have disclosed. This
            information will be used for analysis purposes to help us improve
            the user experience. We may use and disclose aggregated and
            anonymised statistics about the usage of this Platform. If you enrol
            via an employer, generally aggregated data and engagement
            information will be shared with your employer.
          </p>
          <br />
          <br />
          <p className="text-2xl font-bold">
            Third-Party Platforms or Providers
          </p>
          <br />
          <p>
            Our Platform may contain links to and from the portals, apps or
            resources of our partners, advertisers and other third parties. If
            you follow a link to or access any of these web platforms or
            resources, please note that they have their own privacy
            policies/data protection notices and terms of use which you should
            review. We do not accept any responsibility or liability for these
            web platforms or resources and your access and use of them is at
            your own risk.
          </p>
          <br />
          <br />
          <p className="text-2xl font-bold">Research policy</p>
          <br />
          <p>
            HeyJuni may use and disclose aggregated and anonymised data about
            the usage of this Platform. Such disclosure may be used to educate,
            research or analyse mental health concerns and causes. In conducting
            the research, HeyJuni may use an external party or vendor to compute
            the data. HeyJuni strictly does not reveal any personally
            identifiable information.
          </p>
          <br />
          <br />
          <p className="text-2xl font-bold">Chat policy</p>
          <br />
          <p>
            While we generally do not monitor transcripts of chats between
            Speakers and Listeners, we may occasionally review the chat
            transcripts to conduct quality control, address potential safety
            issues, and prevent misuse of our platform, if certain suspicious or
            potentially harmful activity is detected. We may also use aggregated
            data from chat transcripts to conduct research and development. In
            reviewing this information, we will maintain all applicable
            confidentiality/privacy standards.
          </p>
          <br />
          <p>
            We have a 24-hour chat storage policy, however it does not process
            the conversations. After 24 hours, messages will not be available on
            the cloud storage but may be available on the device from where it
            was sent to or from.
          </p>
          <br />
          <br />
          <p className="text-2xl font-bold">Opting out</p>
          <br />
          <p>
            You have the option to opt out of receiving marketing communications
            from us and selected third parties and limiting the distribution of
            your personal information. You can do so by contacting us via the
            contact details below or so far as applicable, by accessing your
            registration/account with us or by clicking on the "unsubscribe"
            link in certain electronic communications we may send you. When we
            receive your request to opt out from receiving marketing
            communication, it may take up to fourteen (14) working days for your
            opt-out to be updated in our records and systems. Accordingly, you
            may still receive marketing communication during this short period
            of time. Kindly be reminded that even if you opt-out from receiving
            marketing communication, we may still contact you for other purposes
            in relation to the transactions between us or the services you have
            requested from us.
          </p>
          <br />
          <br />
          <p className="text-2xl font-bold">Protection of Personal Data</p>
          <br />
          <p>
            We take the necessary security and technical measures to protect the
            security and confidentiality of personal data in compliance with
            applicable law. The transmission of payment information (such as
            credit card and account numbers) is protected through the use of
            encryption such as SSL (Secure Socket Layer) protection.
          </p>
          <br />
          <br />
          <p className="text-2xl font-bold">
            Checking and Updating Your Details and Passwords
          </p>
          <br />
          <p>
            If you wish to access, verify or correct any of your personal
            information, you may do so by contacting us via the contact details
            below. Our security procedures mean that we may request proof of
            identity before we reveal information or carry out the requests.
            This proof of identity may take the form of your username/e-mail
            address and password submitted upon registration (so far as
            applicable). Therefore, you should keep this information safe as you
            will be responsible for any action which we take in response to a
            request from someone using your username/e-mail and password. If you
            have an account with us, you can also update your personal
            information by accessing your account. You undertake to keep safe
            and not disclose to third parties, your account information,
            including passwords. We are not responsible for any misuse or
            disclosure of access information or passwords. Please contact us if
            any of your personal data subsequently becomes outdated, inaccurate
            or incomplete to help ensure the accuracy of the data in our
            records.
          </p>
          <br />
          <br />
          <p className="text-2xl font-bold">Changes to the Policy</p>
          <br />
          <p>
            We reserve the right to modify and change this Policy from time to
            time, without prior notice. Any changes to this Policy will be
            published on this Platform and your continued use of the Platform
            will constitute your acceptance of the modified Policy. If there are
            any inconsistencies between this Policy and the terms and conditions
            of the services we provide to you, the latter shall prevail.
          </p>
          <br />
          <br />
          <p className="text-2xl font-bold">Retention Period</p>
          <br />
          <p>
            Generally, we will retain your Personal Information for as long as
            your account or profile is active, or for the length of time needed
            to fulfil the purposes outlined in this Policy unless a longer
            retention period is required or permitted by law. HeyJuni will store
            your user account and associated Personal Information if your
            account is active. After a period of inactivity greater than 1
            calendar year, HeyJuni may, at its discretion, purge Personal
            Information related to inactive accounts.
          </p>
          <br />
          <br />
          <p className="text-2xl font-bold">Contacting Us</p>
          <br />
          <p>
            If you wish to contact us, you have any questions about this Policy
            or if you would like us to stop or limit the use/processing of your
            personal information, please contact us via our website
            https://heyjuni.com.
          </p>
        </div>
      </div>
    </>
  );
}
