import Button from '@components/Button';
import versus from '@assets/footages/landing/vs.png';
import logo from '@assets/logo.svg';
import { useNavigate } from 'react-router-dom';
import { openWhatsapp } from '@src/utils/open-whatsapp';
import NormalObject from '@components/NormalObject';
import { useTranslation } from 'react-i18next';

export function ComparisonSection() {
  const navigate = useNavigate();
  const { t } = useTranslation('home');

  const comparisonData = [
    {
      title: t('comparison.differences.stigma.title'),
      juni: t('comparison.differences.stigma.juni'),
      therapy: t('comparison.differences.stigma.therapy'),
    },
    {
      title: t('comparison.differences.privacy.title'),
      juni: t('comparison.differences.privacy.juni'),
      therapy: t('comparison.differences.privacy.therapy'),
    },
    {
      title: t('comparison.differences.cost.title'),
      juni: t('comparison.differences.cost.juni'),
      therapy: t('comparison.differences.cost.therapy'),
    },
    {
      title: t('comparison.differences.time.title'),
      juni: t('comparison.differences.time.juni'),
      therapy: t('comparison.differences.time.therapy'),
    },
    {
      title: t('comparison.differences.commitment.title'),
      juni: t('comparison.differences.commitment.juni'),
      therapy: t('comparison.differences.commitment.therapy'),
    },
    {
      title: t('comparison.differences.closeness.title'),
      juni: t('comparison.differences.closeness.juni'),
      therapy: t('comparison.differences.closeness.therapy'),
    },
  ];

  return (
    <section className="text-center py-[20px] md:pt-[40px] mb-[64px]">
      {/* Header with logos */}
      <p className="hidden md:block text-5xl md:text-7xl md:leading-[65px] font-extrabold">
        {t('comparison.title')}
      </p>

      <div className="mx-auto md:mx-[76px] md:my-[40px] md:border md:border-gray-300">
        <div className="flex items-center h-[80px] md:h-[100px]">
          <div className="flex-1 flex justify-end md:justify-center items-center pr-[30px] md:pr-0">
            <NormalObject
              data={logo}
              className="h-[45px] md:h-[54px] w-[65px] md:w-[81px]"
            />
          </div>
          <img
            src={versus}
            alt="vs"
            className="flex-shrink-0 h-[40px] md:h-[60px] w-[40px] md:w-[60px]"
          />
          <div className="flex-1 flex justify-start md:justify-center items-center pl-[30px] md:pl-0">
            <p className="text-4xl md:text-5xl font-extrabold">Therapy</p>
          </div>
        </div>

        {/* Comparison Table */}
        <div>
          {comparisonData.map(({ title, juni, therapy }, index) => (
            <div key={index}>
              <div className="bg-[#181818] text-white text-base md:text-3xl md:leading-[30px] font-semibold py-2 px-4">
                {title}
              </div>
              <div className="flex">
                <div className="text-sm md:text-lg md:font-regular md:leading-[26px] text-left w-1/2 bg-teal-100 py-[20px] px-[28px] md:p-[40px]">
                  <p>{juni}</p>
                </div>
                <div className="text-sm md:text-lg md:font-regular md:leading-[26px] text-left w-1/2 bg-white py-[20px] px-[28px] md:p-[40px]">
                  <p>{therapy}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-[35px] md:mt-0 md:pb-[40px]">
        <p className="text-base md:text-4xl font-semibold md:bold mb-[18px] md:mb-[20px]">
          {t('comparison.call-to-action.title')}
        </p>
        <Button
          label={t('comparison.call-to-action.button')}
          className="h-[50px] w-[168px] md:h-[60px] md:w-[192px] text-lg md:text-2xl font-semibold rounded-[20px] md:rounded-2xl"
          onClick={openWhatsapp}
        />
      </div>
    </section>
  );
}
