import Accordion from '@components/Accordion';
import React from 'react';

export default function GeneralFAQ() {
  const items = [
    {
      title: 'What is HeyJuni?',
      content: (
        <>
          We're the first platform in the world to provide on-demand and instant
          emotional support where women who deal with trauma, anxiety, stress
          and depression can book a session for emotional counseling/support
          within 15 minutes. In a completely anonymous and judgment-free space
          via texting, voice or video call, women are matched with trained
          female Peer Counselors who offer a safe place to confide, talk, learn
          coping strategies, and build resilience when it's needed most.
        </>
      ),
    },
    {
      title: 'Who are the individuals seeking support?',
      content: (
        <>
          <p>
            Women face immense challenges and carry countless responsibilities,
            often shouldering burdens that feel overwhelming, no matter their
            age or stage in life. And we recognize that challenging and
            traumatic life experiences can bring deep feelings of sadness, fear,
            anger, and loneliness, leading to struggles with anxiety, distress,
            and isolation. Therefore our platform is specifically dedicated for
            women who have experienced any form of trauma and life challenges
            stemming from:
          </p>
          <ol className="list-disc list-inside inline-block">
            <li>difficult relationships</li>
            <li>dealing with unresolved family conflicts</li>
            <li>childhood trauma</li>
            <li>abuse and mistreatment</li>
            <li>
              intimate partner violence (whether verbal, physical,
              psychological, or otherwise)
            </li>
            <li>feelings of isolation and loneliness</li>
            <li>stress and burnout</li>
            <li>struggles with self-worth and confidence</li>
            <li>navigating life transitions and uncertainties</li>
            <li>
              coping with grief and loss (of a person, marriage, breakups)
            </li>
            <li>managing anxiety, sadness, or overwhelming emotions</li>
            <li>balancing personal and professional responsibilities</li>
          </ol>
          <p className="font-bold mt-4">Why choose HeyJuni?</p>
          <ol className="list-decimal list-inside inline-block">
            <li className="mb-8">
              <span className="font-semibold">
                A Safe and Judgment-Free Space
              </span>
              <ol className="list-disc list-inside inline-block">
                <li>
                  We recognize that feeling emotional or mentally overwhelmed
                  often bring feelings of fear and embarrassment to speak out,
                  making it difficult to reach out to friends or family.
                </li>
                <li>
                  At HeyJuni, there are no labels or stigma - you won't be
                  treated as "mentally ill," nor will your privacy be
                  compromised by mental health records affecting insurance or
                  other aspects of life.
                </li>
              </ol>
            </li>
            <li className="mb-8">
              <span className="font-semibold">
                Support Beyond Self-Help, Therapy and Distractions
              </span>
              <ol className="list-disc list-inside inline-block">
                <li>
                  Self-help can feel isolating, especially when you're
                  struggling emotionally and lack the human connection necessary
                  for true healing.
                </li>
                <li>
                  Material distractions provide only short-term relief, leaving
                  you searching for deeper meaning and long-term recovery.
                </li>
                <li>
                  If you've tried therapy and felt frustrated because you
                  couldn't connect with your therapist - or worse, felt like you
                  were spending hundreds or even thousands of dollars without
                  being able to connect with anyone or feeling stigmatized by
                  the process of therapy.
                </li>
              </ol>
            </li>
            <li className="mb-8">
              <span className="font-semibold">
                On-Demand Emotional Support and Counseling:
              </span>
              <ol className="list-disc list-inside inline-block">
                <li>
                  Our trained Peer Counselors are available whenever you need
                  them - no waiting for appointments or lengthy intake
                  processes.
                </li>
                <li>
                  Whether you're feeling lonely, disconnected, or struggling to
                  regain trust, our Peer Counselors provide real-time support,
                  helping you cope with negative emotions as they arise. At
                  HeyJuni, you have a space where you are understood, valued,
                  and empowered to heal. Reach out whenever you're ready.
                </li>
              </ol>
            </li>
          </ol>
          <p>
            At HeyJuni, you have a space where you are understood, valued, and
            empowered to heal. Reach out whenever you're ready.
          </p>
        </>
      ),
    },
    {
      title:
        'How is HeyJuni different from other services and how do I benefit?',
      content: (
        <>
          <p>Here's how we stand out from other services:</p>
          <br />
          <p className="font-bold mt-4">Anonymity for Comfort and Safety</p>
          <p>
            When you connect with one of our vetted and trained Peer Counselors,
            you can speak openly and honestly without fear. We offer a
            completely anonymous and non-judgmental space where you can feel
            unexposed and safe, making it easier to be vulnerable and express
            your true feelings.
          </p>
          <br />
          <p className="font-bold mt-4">Instant Support</p>
          <p>
            Your struggles don't follow a schedule, and neither do we. If
            anxiety strikes late at night or at any time, you can count on us.
            You can book a session instantly, and within 15 minutes of your
            booking, we match you with a peer counselor and provide the support
            you need right when it matters most.
          </p>
          <br />
          <p className="font-bold mt-4">Real-time Coping In The Moment</p>
          <p>
            You'll have someone to accompany you and "take you by the hand"
            through your anxiety, helping you navigate coping exercises and find
            relief in the moment. Receive validation, encouragement, and a sense
            of empowerment as you work through your emotional struggles
          </p>
          <br />
          <p className="font-bold mt-4">Learn Coping Skills</p>
          <p>
            Gain valuable tools to deal with anxiety and emotional challenges on
            your own after the session. You'll learn techniques that you can
            take away for use in future situations. Intimate, relatable, casual
            Our approach is intimate and peer-to-peer, giving you the chance to
            feel comfortable about sharing. It's an approach that feels more
            personal and eye-to-eye than traditional therapy.
          </p>
          <br />
          <p className="font-bold mt-4">For Females, By Females</p>
          <p>
            All our Peer Counselors are female, bringing a sense of safety and
            understanding. Only women understand what other women go through.
          </p>
          <br />
          <p className="font-bold mt-4">
            Personalized and Culturally Competent
          </p>
          <p>
            We go beyond just providing a female Peer Counselor. We match you
            with someone suitable based on cultural understanding and individual
            preferences, ensuring the support you receive feels relevant and
            personalized.
          </p>
          <br />
          <p className="font-bold mt-4">Flexible Support Options</p>
          <p>
            Choose between building a long-term relationship with a Peer
            Counselor or calling in for on-demand, immediate support from
            different Peer Counselors - whatever suits your needs best.
          </p>
        </>
      ),
    },
    {
      title: 'Who are Peer Counselors?',
      content: (
        <>
          <p>
            The Peer Counselors at HeyJuni are a compassionate group of
            dedicated women trained to provide empathetic support and actively
            listen to individual needs. They are skilled in using cognitive
            behavioral techniques, mindfulness exercises, and other effective
            tools to assist those experiencing distress, trauma, or anxiety.
            Coming from diverse backgrounds - ranging from mental health
            professionals to advocates and individuals with lived experiences -
            they bring a variety of perspectives and understanding.
          </p>
          <br />
          <p>
            Each Peer Counselors undergoes rigorous screening, comprehensive
            training, and ongoing supervision by clinicians to ensure they
            deliver safe, understanding, and non-judgmental support. Their
            primary goal is to create a confidential and supportive environment
            where you can express yourself freely, receive personalized
            guidance, and feel accompanied in your healing process. They are
            there to take you by the hand, guiding you through coping strategies
            in real-time to help you manage your unique struggles.
          </p>
        </>
      ),
    },
    {
      title: `What are the common topics Users discuss?`,
      content: (
        <>
          <p>
            At HeyJuni, we believe that every issue, big or small, deserves to
            be heard. Whether you're feeling slightly moody or experiencing
            profound distress, it's important to reach out. Users often share
            their experiences and feelings related to various aspects of sexual
            violence, including:
          </p>
          <br />
          <p>
            <span className="font-bold">Trauma and PTSD:</span> Discussing the
            lasting emotional impact of traumatic events.
          </p>
          <br />
          <p>
            <span className="font-bold">Depression:</span> Coping with anxiety,
            distress, depression, and feelings of hopelessness stemming from
            their experiences.
          </p>
          <br />
          <p>
            <span className="font-bold">Loneliness:</span> Loneliness isn't just
            being alone; it's also feeling unable to share real emotions, even
            around friends or loved ones.
          </p>
          <br />
          <p>
            <span className="font-bold">Unresolved Relationship Issues:</span>{' '}
            Managing relationships can be tough as they often come with
            misunderstandings, unmet expectations, and emotional challenges that
            can leave you feeling hurt.
          </p>
          <br />
          <p>
            <span className="font-bold">Struggle with Childhood Trauma:</span>{' '}
            Traumatic or negative memories and experiences of a past event or a
            phase in life that leave a lasting impact.
          </p>
          <br />
          <p>
            <span className="font-bold">Shame and Guilt:</span> Navigating
            complex emotions like shame, guilt, and self-blame toward one’s own
            experiences and feelings.
          </p>
          <br />
          <p>
            <span className="font-bold">Violence, abuse and Isolation:</span>{' '}
            Feeling alone or disconnected from others after experiencing a
            traumatic event such as violence or abuse.
          </p>
          <br />
          <p>
            <span className="font-bold">Fear and Trust Issues:</span> Struggling
            with fear in relationships and difficulty trusting others.
          </p>
          <p>
            <span className="font-bold">Family Dynamics:</span> Exploring how
            experiences of mistreatment, violence or abuse have affected
            relationships with family members.
          </p>
          <br />
          <p>
            <span className="font-bold">Perpetrator Impact:</span> Processing
            feelings related to the perpetrator or person who causes the
            distress and trauma on one's life.
          </p>
          <br />
          <p>
            <span className="font-bold">
              Struggles with self-worth and confidence:
            </span>{' '}
            Being overwhelmed by self-doubt, feeling inadequate, or questioning
            your value, which can impact your ability to embrace opportunities
            and nurture thinking patterns.
          </p>
          <br />
          <p>
            <span className="font-bold">Sleeplessness:</span> Dealing with
            insomnia and disrupted sleep patterns as a result of emotional
            distress.
          </p>
          <br />
          <p>
            <span className="font-bold">Seeking Support:</span> The challenges
            of reaching out for help, including fears of judgment or stigma.
          </p>
          <br />
          <p>
            <span className="font-bold">Coping Mechanisms:</span> Having the
            need to learn and explore healthy coping strategies and ways to
            manage distressing feelings.
          </p>
          <br />
          <p>
            <span className="font-bold">Reclaiming Control:</span> Discussions
            about empowerment and regaining a sense of agency in their lives.
          </p>
        </>
      ),
    },
    {
      title: 'How serious do my problems have to be to benefit from Heyjuni?',
      content: (
        <>
          <p>
            At HeyJuni, we believe that every issue, big or small, deserves to
            be heard. Whether you're feeling slightly moody or experiencing
            profound distress, it's important to reach out. Here's why:
          </p>
          <ol className="list-disc list-inside inline-block">
            <li className="mb-4">
              <span className="font-bold">No Problem is Too Small:</span> It's
              never wrong to talk about your feelings. Bottling them up can lead
              to deeper issues over time. Even if you feel fine now, unaddressed
              emotions can resurface more intensely later on.
            </li>
            <li className="mb-4">
              <span className="font-bold">Building Healthy Habits:</span>{' '}
              Regularly seeking emotional support, even when you seem to be
              well, fosters a good habit of sharing and can prevent future
              problems.
            </li>
            <li className="mb-4">
              <span className="font-bold">Addressing Mild Distress:</span> If
              you're feeling mildly distressed, talking about it can help you
              develop positive coping skills and prevent escalation.
            </li>
            <li className="mb-4">
              <span className="font-bold">Moderate to Profound Distress:</span>{' '}
              If you're facing more significant emotional challenges, it's
              crucial to talk to someone. Gaining insight into your feelings and
              having a safe space to vent can aid in short-term relief and
              long-term healing.
            </li>
            <li className="mb-4">
              <span className="font-bold">Severe Distress:</span> If you're
              experiencing severe distress, we recommend seeking therapy.
              Furthermore you can still reach out to us as a supportive
              alternative when you need immediate assistance to manage negative
              emotions in the moment.
            </li>
          </ol>
          <br />
          <p>
            Overall, regardless of the severity of your situation, our Peer
            Counselors are here to provide a safe, empathetic space for you to
            express yourself.
          </p>
        </>
      ),
    },
    {
      title:
        'How will you protect my anonymity and confidentiality on the platform and in sessions?',
      content: (
        <>
          <p>
            At HeyJuni, your anonymity is our highest priority. We understand
            the importance of expressing yourself freely, which is why our
            platform is designed to ensure your confidentiality at every step.
          </p>
          <br />
          <p>Key Measures for Protecting Your Anonymity:</p>
          <ol className="list-disc list-inside inline-block">
            <li className="mt-2">
              <span className="font-bold">
                No Personal Identifiable Information:
              </span>{' '}
              During the booking process, you are not required to provide any
              personally identifiable information, including your real name.
            </li>
            <li className="mt-2">
              <span className="font-bold">Data Encryption:</span> All
              information you share is encrypted using industry-standard
              protocols. This ensures that your data is securely stored and
              protected against unauthorized access.
            </li>
            <li className="mt-2">
              <span className="font-bold">Confidential Environment:</span> At
              HeyJuni, you can discuss your experiences without fear of
              judgment. No one will know who you are or what you talk about in
              your sessions. Our Peer Counselors follow strict protocols to
              ensure that all interactions remain private, so you can speak
              openly without fear of repercussions or exposure.
            </li>
            <li className="mt-2">
              <span className="font-bold">Self-Disclosure Guidelines:</span>{' '}
              Additionally, to safeguard your own anonymity, we recommend you to
              refrain from sharing details that could reveal your identity. This
              further safeguards your anonymity.
            </li>
          </ol>
        </>
      ),
    },
    {
      title: 'What are the types of communication during a session?',
      content: (
        <>
          <p>
            Depending on your preference, it's a 1-on-1 conversation with a Peer
            Counselor via text, voice or video.
          </p>
        </>
      ),
    },
    {
      title: 'What does a session look like? What can I expect?',
      content: (
        <>
          <p>
            During a session at HeyJuni, you can expect a supportive and
            non-judgmental environment designed to help you share your thoughts
            and feelings freely. Here's what you can anticipate:
          </p>
          <ol className="list-disc list-inside inline-block">
            <li className="mt-2">
              <span className="font-bold">Personalized Matching:</span> You will
              be matched with a trained Peer Counselor who best suits your
              needs, ensuring you receive the appropriate support.
            </li>
            <li className="mt-2">
              <span className="font-bold">Flexible Communication:</span>{' '}
              Sessions can be conducted through text, audio, or video, depending
              on your preference, allowing you to choose the most comfortable
              way to connect.
            </li>
            <li className="mt-2">
              <span className="font-bold">Duration:</span> Each session lasts
              for 50 minutes, providing ample time for meaningful conversation.
            </li>
            <li className="mt-2">
              <span className="font-bold">Open Sharing:</span> Within this time,
              you can express what's on your mind and explore how your Listener
              can assist you. You can set specific goals for the session,
              whether it's venting, seeking motivation, or discussing coping
              strategies.
            </li>
            <li className="mt-2">
              <span className="font-bold">Confidentiality Assurance:</span> We
              prioritize your privacy. Everything shared during the session
              remains confidential.
            </li>
            <li className="mt-2">
              <span className="font-bold">Adaptable Support:</span> Your Peer
              Counselor will check in with you throughout the session to ensure
              your needs are being met and adapt the conversation as necessary.
            </li>
          </ol>
        </>
      ),
    },
    {
      title: 'What is the cost of each session?',
      content: (
        <div>
          <p>
            We currently offer a flexible option to access our peer support
            services:
          </p>
          <ol className="list-disc list-inside inline-block">
            <li className="mb-4">
              <span className="font-bold">Single Session Option:</span>{' '}
              Pay-as-you-go at SG $29.90 per session, allowing you the freedom
              and flexibility to book only when needed without any long-term
              commitment. Each session is 50 minutes long and you can book
              instantly or schedule ahead of time.
            </li>
          </ol>
          <p>
            We prioritize the quality of user experience by investing in
            extensive training and fair compensation for our Peer Counselors,
            Mentors and Clinicians, ensuring a supportive and uplifting
            environment. We maintain a strict commitment to user privacy by
            refraining from selling any user data, setting us apart from other
            "free" platforms. Instead of relying on intrusive ads to generate
            revenue, we focus on creating a seamless and ad-free platform to
            enhance user satisfaction.
          </p>
        </div>
      ),
    },
    {
      title: 'Does HeyJuni have an app?',
      content: (
        <>
          <p>
            HeyJuni is currently in the process of developing an app to give you
            the full experience. Please stay tuned for its upcoming release.
          </p>
        </>
      ),
    },
    {
      title: 'Our ethical statement',
      content: (
        <>
          <p>
            At HeyJuni, we are deeply committed to fostering an environment
            rooted in genuine support and understanding. Our mission is to
            uphold the highest standards of value, integrity, and respect,
            ensuring that every user receives the compassionate care they
            deserve. We prioritize ethical practices that promote emotional
            well-being, encourage open dialogue, and empower individuals on
            their journey toward healing.
          </p>
        </>
      ),
    },
  ];

  return (
    <>
      <p className="text-5xl font-extrabold mb-10">General</p>
      <Accordion items={items} />
    </>
  );
}
