import Button from '@components/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function RealWomenSection() {
  const navigate = useNavigate();
  const { t } = useTranslation('learn-more');

  return (
    <section className="relative bg-white mb-[64px] md:mb-0 md:py-[64px] md:px-[76px] overflow-hidden">
      <div className="hidden md:block  md:leading-[65px] md:text-7xl font-extrabold text-center md:text-left md:mb-[16px]">
        For Women, By Women
      </div>

      <div className="md:hidden text-5xl leading-[48px] font-extrabold text-center">
        <div>For Women,</div>
        <div>By Women</div>
      </div>

      <div className="md:hidden my-[40px]">
        <img
          src="learn-more/for-woman-by-woman.webp"
          alt="A collage of different smiling faces of HeyJuni’s Peer Counselors. They’re all women from different backgrounds and cultures."
        />
      </div>

      <div className="md:w-[52%] px-[28px] md:px-0 md:py-[16px] font-regular text-base md:text-2xl">
        <p className="">{t('for-woman-by-woman.content.p1')}</p>
        <br />
        <p className="">{t('for-woman-by-woman.content.p2')}</p>
        <div className="my-[20px] md:mt-[40px] md:mb-0 text-center md:text-left">
          <Button
            label={t('for-woman-by-woman.call-to-action.button')}
            className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
            onClick={() => navigate('/booking')}
          />
        </div>
      </div>

      {/* TODO: Fix this position later */}
      <div className="hidden md:block md:absolute md:top-[calc(50%-20px)] md:right-[0px] md:transform md:-translate-y-1/2">
        <img
          src="learn-more/for-woman-by-woman-desktop.webp"
          alt="A collage of different smiling faces of HeyJuni’s Peer Counselors. They’re all women from different backgrounds and cultures."
        />
      </div>
    </section>
  );
}
