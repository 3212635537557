import ChipRadio from '@components/ChipRadio';
import { useEffect, useState } from 'react';
import headingMobile from '@assets/footages/faq/heading-mobile.svg';
import GeneralFAQ from '@components/FAQs/GeneralFAQ';
import HelpSeekersFAQ from '@components/FAQs/HelpSeekersFAQ';
import ListenersFAQ from '@components/FAQs/ListenersFAQ';
import PrivacyFAQ from '@components/FAQs/PrivacyFAQ';
import { useSearchParams } from 'react-router-dom';
import NormalObject from '@components/NormalObject';
import { Helmet } from 'react-helmet';

export default function FAQ() {
  const [searchParams] = useSearchParams();
  const selected = searchParams.get('selected');

  const [selectedOption, setSelectedOption] = useState<string>(
    selected ?? 'General'
  );

  const renderFAQContent = () => {
    switch (selectedOption) {
      case 'General':
        return <GeneralFAQ />;
      case 'Users':
        return <HelpSeekersFAQ />;
      case 'Peer Counselor':
        return <ListenersFAQ />;
      case 'Privacy':
        return <PrivacyFAQ />;
      default:
        return <div>Please select an FAQs category to view the content.</div>;
    }
  };

  useEffect(() => {
    setSelectedOption(selected ?? 'General');
  }, [selected]);

  return (
    <>
      {/* Add Helmet for SEO */}
      <Helmet>
        <title>FAQs | Get Answers About Heyjuni, Counseling, Therapy</title>
        <meta
          name="description"
          content="What is HeyJuni? · Who will be helping me? · Who are the Peer Counselors / Listeners? · How are Supporters vetted? · How much does it cost? · Is HeyJuni right for me? • Why not Therapy?"
        />
        <meta
          name="keywords"
          content="HeyJuni FAQs, Frequently Asked Questions, Privacy, Peer Counselor FAQs, General FAQs, Help Seekers FAQs"
        />
        <meta property="og:title" content="FAQs - HeyJuni" />
        <meta
          property="og:description"
          content="Explore HeyJuni's FAQ section for information about services, privacy, peer counselors, and help seekers. Get all your questions answered."
        />
      </Helmet>
      <div className="relative">
        <div
          className="hidden md:block absolute top-0 left-0"
          style={{ zIndex: -1 }}
        >
          <img src="faq-heading.webp" alt="heading background" />
        </div>

        <div
          className="md:hidden absolute top-0 left-0 transform -translate-y-[31px] w-full"
          style={{ zIndex: -1 }}
        >
          <NormalObject data={headingMobile} className="w-full object-cover" />
        </div>

        <div className="py-[64px] px-[28px] md:px-0 md:py-[96px] md:w-[58%] mx-auto">
          <div className="font-black text-6xl leading-[58px] md:text-8xl md:leading-[85px]">
            <p>FAQs - Answers about</p>
            <p>HeyJuni’s Online Support</p>
          </div>
          <div className="mt-12 font-medium">
            At HeyJuni, we provide peer counseling services designed to offer
            emotional support and guidance, not therapy. Our Peer Counselors are
            trained to listen, understand, and help you build resilience through
            conversations. Our approach is flexible, accessible, and effective
            for those seeking immediate support, personal growth, and a safe
            space to express their feelings. Whether you’re facing loneliness,
            stress, or mental challenges, HeyJuni offers a empathetic,
            non-judgmental environment. Find out through the FAQs if HeyJuni is
            the right fit for your mental health journey.
          </div>
          <div className="mt-[50px] md:mt-[114px]">
            <ChipRadio
              options={['General', 'Users', 'Peer Counselor', 'Privacy']}
              selectedOption={selectedOption}
              pathname="/faq"
            />
          </div>
          <div className="mt-[65px] md:mt-[33px]">{renderFAQContent()}</div>
        </div>
      </div>
    </>
  );
}
// max-w-3xl
