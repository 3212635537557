import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import circleArrowLeft from '@assets/icons/shared/circle-arrow-left.svg';
import circleArrowRight from '@assets/icons/shared/circle-arrow-right.svg';

interface SwipeProps {
  children: React.ReactNode;
  className?: string;
  currentStepList: string[];
  cardWidth: number;
  gap: number;
  boundaryPadding: number;
}

export function Swipe(props: SwipeProps) {
  const {
    currentStepList,
    children,
    cardWidth,
    gap,
    boundaryPadding,
    className,
  } = props;

  const [currentStep, setCurrentStep] = useState(0); // Track the current step index
  const delta = cardWidth + 10;
  const widthToSwipe =
    cardWidth * currentStepList.length +
    gap * (currentStepList.length - 1) +
    2 * boundaryPadding -
    window.innerWidth +
    delta;

  const startX = useRef<number | null>(null);
  const defaultTranslateXDistance =
    2 * gap + (3 / 2) * cardWidth - window.innerWidth / 2;
  const currentTranslateX = useRef(-defaultTranslateXDistance);

  const containerRef = useRef<HTMLDivElement>(null);
  const innerContainerRef = useRef<HTMLDivElement>(null);
  const totalWidthRef = useRef<number>(0);
  const containerWidthRef = useRef<number>(0);

  const startX0 = useRef<number | null>(null);
  const startTimeRef = useRef<number | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerWidthRef.current = containerRef.current.offsetWidth;
      totalWidthRef.current = innerContainerRef.current?.scrollWidth || 0;
    }
  }, []);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    startX0.current = e.clientX;
    startX.current = e.clientX;
    startTimeRef.current = e.timeStamp;
    if (innerContainerRef.current) {
      innerContainerRef.current.style.transition = 'none';
    }
    // Add a mousemove event listener to handle dragging
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    return;

    e.preventDefault(); // Prevent default browser behavior

    if (startX.current === null) return;
    const currentX = e.clientX;
    updateTranslation(currentX);
  };

  const MIN_MOVE_THRESHOLD = 10; // Minimum movement to be recognized as valid
  const updateTranslation = (currentX: number) => {
    const diff = currentX - startX.current!;
    if (Math.abs(diff) < MIN_MOVE_THRESHOLD) return;

    let newCurrentTranslateX = currentTranslateX.current + diff;

    // Try to break left limit
    if (newCurrentTranslateX > 0) {
      newCurrentTranslateX = 0;
    }

    // Try to break right limit
    if (
      newCurrentTranslateX <
      -(totalWidthRef.current - containerWidthRef.current)
    ) {
      newCurrentTranslateX = -(
        totalWidthRef.current - containerWidthRef.current
      );
    }

    if (innerContainerRef.current) {
      innerContainerRef.current.style.transform = `translateX(${newCurrentTranslateX}px)`;
    }

    currentTranslateX.current = newCurrentTranslateX;
    startX.current = currentX;

    const newStep = Math.abs(
      Math.floor((newCurrentTranslateX / widthToSwipe) * currentStepList.length)
    );
    setCurrentStep(newStep);
  };

  const handleMouseUp = (e: MouseEvent) => {
    e.preventDefault();
    return;

    // endInteraction(e.clientX, e.timeStamp);
    // window.removeEventListener('mousemove', handleMouseMove);
    // window.removeEventListener('mouseup', handleMouseUp);
  };

  const endInteraction = (endX: number, timeStamp: number) => {
    return;

    // if (
    //   startX.current === null ||
    //   startTimeRef.current === null ||
    //   startX0.current === null
    // )
    //   return;

    // const timeTaken = timeStamp - startTimeRef.current;
    // const distance = endX - startX0.current;
    // const speed = Math.abs(distance / timeTaken);

    // // Adjust these thresholds to reduce sensitivity
    // const flickThreshold = 1.0; // Increase speed requirement for flick
    // const distanceThreshold = 100; // Increase minimum swipe distance for flick
    // const timeThreshold = 150; // Maximum time (in ms) allowed for a flick

    // let additionalTranslate = 0;

    // if (
    //   speed > flickThreshold &&
    //   Math.abs(distance) > distanceThreshold &&
    //   timeTaken < timeThreshold
    // ) {
    //   additionalTranslate = distance * 10; // Apply multiplier if all conditions met
    // }

    // currentTranslateX.current += additionalTranslate;

    // if (currentTranslateX.current > 0) currentTranslateX.current = 0;
    // if (
    //   currentTranslateX.current <
    //   -(totalWidthRef.current - containerWidthRef.current)
    // ) {
    //   currentTranslateX.current = -(
    //     totalWidthRef.current - containerWidthRef.current
    //   );
    // }

    // if (innerContainerRef.current) {
    //   innerContainerRef.current.style.transition = 'transform 0.3s ease';
    //   innerContainerRef.current.style.transform = `translateX(${currentTranslateX.current}px)`;
    // }

    // startX.current = null;
    // startTimeRef.current = null;

    // const newStep = Math.abs(
    //   Math.floor(
    //     (currentTranslateX.current / widthToSwipe) * currentStepList.length
    //   )
    // );
    // setCurrentStep(newStep);
  };

  useEffect(() => {
    console.log(window.innerWidth / 2 - cardWidth / 2 - gap / 2);
  }, []);

  const handleArrowClick = (direction: 'left' | 'right') => {
    // TODO: Will refactor this
    const finalTranslateX = -(
      (currentStepList.length - 1) * (cardWidth + gap) +
      (window.innerWidth - cardWidth) / 2 -
      window.innerWidth
    );

    if (
      direction === 'left' &&
      currentTranslateX.current > -(cardWidth + 2 * gap)
    ) {
      const newTranslateX = finalTranslateX;

      // Update the translateX value
      currentTranslateX.current = newTranslateX;
      // Update the style
      if (innerContainerRef.current) {
        innerContainerRef.current.style.transition = 'transform 0.3s ease';
        innerContainerRef.current.style.transform = `translateX(${newTranslateX}px)`;
      }

      return;
    }

    if (
      direction === 'right' &&
      // currentTranslateX.current === finalTranslateX
      Math.abs(currentTranslateX.current - finalTranslateX) <= 20
    ) {
      const newTranslateX = -defaultTranslateXDistance;

      // Update the translateX value
      currentTranslateX.current = newTranslateX;
      // Update the style
      if (innerContainerRef.current) {
        innerContainerRef.current.style.transition = 'transform 0.3s ease';
        innerContainerRef.current.style.transform = `translateX(${newTranslateX}px)`;
      }

      return;
    }

    const swipeDistance = cardWidth + gap;
    let newTranslateX =
      direction === 'left'
        ? currentTranslateX.current + swipeDistance
        : currentTranslateX.current - swipeDistance;

    // Ensure the translation does not exceed boundaries
    if (newTranslateX > 0) {
      newTranslateX = 0;
    }
    if (newTranslateX < -(totalWidthRef.current - containerWidthRef.current)) {
      newTranslateX = -(totalWidthRef.current - containerWidthRef.current);
    }

    // Update the translateX value
    currentTranslateX.current = newTranslateX;

    // Update the style
    if (innerContainerRef.current) {
      innerContainerRef.current.style.transition = 'transform 0.3s ease';
      innerContainerRef.current.style.transform = `translateX(${newTranslateX}px)`;
    }

    // Update current step
    const newStep = Math.abs(
      Math.floor((newTranslateX / widthToSwipe) * currentStepList.length)
    );
    setCurrentStep(newStep);
  };

  return (
    <>
      <div
        ref={containerRef}
        className={clsx(
          `w-full flex items-center overflow-hidden relative`,
          className
        )}
        onMouseDown={handleMouseDown} // Add mouse down event for desktop
      >
        <div
          className="absolute z-[1] top-1/2 cursor-pointer"
          style={{
            left: `${window.innerWidth / 2 - cardWidth / 2 - gap / 2}px`,
            transform: 'translateY(-50%) translateX(calc(-50% - 0px))',
          }}
          onClick={() => handleArrowClick('left')}
        >
          <img src={circleArrowLeft} alt="Arrow Left" />
        </div>
        <div
          ref={innerContainerRef}
          className="flex"
          style={{
            transform: `translateX(${currentTranslateX.current}px)`,
          }}
        >
          {children}
        </div>
        <div
          className="absolute z-[1] top-1/2 cursor-pointer"
          style={{
            left: `${window.innerWidth / 2 + cardWidth / 2 + gap / 2}px`,
            transform: 'translateY(-50%) translateX(calc(-50% - 0px))',
          }}
          onClick={() => handleArrowClick('right')}
        >
          <img src={circleArrowRight} alt="Arrow Right" />
        </div>
      </div>
      {/* <div className="hidden md:flex justify-center md:mt-[10px]">
        {currentStepList?.[0] !== '' ? (
          <img
            src={
              currentStepList[Math.min(currentStep, currentStepList.length - 1)]
            }
            className="hidden md:block"
          />
        ) : (
          <div className="flex justify-center space-x-2">
            {currentStepList.map((_, idx) => (
              <span
                key={idx}
                className={clsx('w-2 h-2 rounded-full', {
                  'bg-gray-300':
                    idx !== Math.min(currentStep, currentStepList.length - 1),
                  'bg-purple-500':
                    idx === Math.min(currentStep, currentStepList.length - 1),
                })}
              ></span>
            ))}
          </div>
        )}
      </div> */}
    </>
  );
}
