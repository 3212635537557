import whyImage01 from '@assets/footages/become-a-listener/why-01.svg';
import whyImage02 from '@assets/footages/become-a-listener/why-02.svg';
import whyImage03 from '@assets/footages/become-a-listener/why-03.svg';
import whyImage04 from '@assets/footages/become-a-listener/why-04.svg';
import whyImage05 from '@assets/footages/become-a-listener/why-05.svg';
import whyImage06 from '@assets/footages/become-a-listener/why-06.svg';
import NormalObject from '@components/NormalObject';

const cardData = [
  {
    src: whyImage01,
    title: 'Create Impact',
    description:
      'Be the support someone truly needs and reduce stigma around mental health.',
  },
  {
    src: whyImage02,
    title: 'Life Long Learning',
    description:
      'Gain experiences, knowledge, and communication skills. Access advanced courses.',
  },
  {
    src: whyImage03,
    title: 'Be a Professional',
    description:
      'Recognize peer counseling as a profession, expand career, and work with clinicians.',
  },
  {
    src: whyImage04,
    title: 'Be accredited',
    description:
      'Be recognized and certified for the meaningful work. Acquire practicum hours if needed.',
  },
  {
    src: whyImage05,
    title: 'Financial Benefits',
    description:
      'Receive financial compensation, and be rewarded for the skills you bring.',
  },
  {
    src: whyImage06,
    title: 'Working Flexibly',
    description:
      'Work from anywhere and anytime with flexible hours and no scheduling needed.',
  },
];

export function WhyBecomeSupportersSection() {
  return (
    <div className="mb-[84px] md:mb-0 md:px-[76px] md:py-[20px] text-center">
      <div className="text-center mb-[54px] md:mb-[42px] font-extrabold text-5xl md:text-7xl leading-[48px] md:leading-[65px]">
      Why Join as a Peer Counselor?
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-[60px] gap-y-[60px] md:gap-y-[80px] px-[68px] md:px-[40px] md:py-[40px]">
        {cardData.map((data, index) => (
          <Card
            key={index}
            src={data.src}
            title={data.title}
            description={data.description}
          />
        ))}
      </div>
    </div>
  );
}

interface CardProps {
  src: string;
  title: string;
  description: string;
}

function Card(props: CardProps) {
  const { src, title, description } = props;
  return (
    <div
      className={`relative overflow-hidden text-center flex flex-col gap-[20px] items-center`}
    >
      <div className="md:min-h-[62px]">
        <NormalObject data={src} />
      </div>
      <h3 className="font-bold text-xl md:text-4xl md:leading-[40px]">
        {title}
      </h3>
      <p className="text-[15px] md:text-lg leading-[22px] md:leading-[26px]">
        {description}
      </p>
    </div>
  );
}
