import React, { useContext } from 'react';

import { useBookingContext } from './BookingContext';

export function Step01() {
  const { address, setAddress, setCurrentStep } = useBookingContext();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setCurrentStep(2);
    }
  };

  return (
    <div>
      <h1 className="text-4xl md:text-5xl font-extrabold mb-[16px] md:mb-[24px]">
        Help Us Match You With The Right Peer Counselor For a Meaningful &
        Connected Experience
      </h1>

      <p className="font-regular text-base md:text-lg mb-[16px] md:mb-[24px]">
        Talk to a Peer Counselor anytime, with sessions available in just 15
        minutes. You can be completely anonymous throughout the support and can
        freely share what's on your mind.
      </p>
      <p className="font-regular text-base md:text-lg mb-[16px] md:mb-[24px]">
        We promise, you will not have to face your challenges alone. Take a
        moment to answer a few questions to book your session and get matched.
      </p>

      <div>
        <h2 className="text-base md:text-3xl font-semibold my-[16px] md:my-[24px]">
        Before starting, how should we address you?
        </h2>
        <input
          type="text"
          placeholder="Enter Name"
          className="w-full border-b border-black focus:outline-none focus:border-black placeholder-gray-500 font-regular text-base md:text-lg"
          value={address}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
}
