import React from 'react';
import { Helmet } from 'react-helmet';

const SchemaMarkup = () => {
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: 'HeyJuni',
    url: 'https://heyjuni.com',
    mainEntity: [
      {
        '@type': 'WebPage',
        name: 'Landing Page',
        url: 'https://heyjuni.com/',
      },
      {
        '@type': 'WebPage',
        name: 'Learn More Page',
        url: 'https://heyjuni.com/learn-more',
      },
      {
        '@type': 'WebPage',
        name: 'FAQs',
        url: 'https://heyjuni.com/faq',
      },
      {
        '@type': 'WebPage',
        name: 'Blog',
        url: 'https://heyjuni.com/blog',
      },
      {
        '@type': 'WebPage',
        name: 'Become Listener Page',
        url: 'https://heyjuni.com/become-a-listener',
      },
      {
        '@type': 'WebPage',
        name: 'Pricing Page',
        url: 'https://heyjuni.com/pricing',
      },
    ],
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
};

export default SchemaMarkup;
