import { useEffect, useState } from 'react';
import youtubeIcon from '@assets/footages/blog/youtube-icon.png';
import { PopUp } from '@components/Popup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { shuffleArray } from '@src/utils/array';

const API_URL = 'https://www.googleapis.com/youtube/v3/search';
const CHANNEL_ID = 'UCbtmZ9_WN_uwI3EUpfGTUDQ';
const API_KEY = 'AIzaSyCXj7D7CIyGu7Ae-bwNwk8km7135yrxRGA';
const MAX_RESULTS = 10;

const videosRaw = [
  {
    id: 'JbeE14t-24Q',
    thumbnail: 'https://img.youtube.com/vi/JbeE14t-24Q/hqdefault.jpg',
    date: 'Aug 30, 2024',
    duration: '16:34',
    title:
      'HeyJuni Unplugged Ep1: The Silent Struggles of Female Gen Zs in The LGBTQ Community 🌈💬',
    description: (
      <>
        <p>
          In this episode, we explore the challenges faced by female Gen Z
          members of the LGBTQ community. Growing up in an era of rapid social
          change, they navigate the complexities of identity, acceptance, and
          belonging while often confronting societal pressures and expectations.
          We discuss the importance of seeking validations and support when
          feeling isolated, stigmatized, and misunderstood.
        </p>
      </>
    ),
  },
  {
    id: 'ghkHGxfGpGg',
    thumbnail: 'https://img.youtube.com/vi/ghkHGxfGpGg/hqdefault.jpg',
    date: 'Sep 6, 2024',
    duration: '23:56',
    title:
      'HeyJuni Unplugged Ep2: When being True to Yourself ✌🏻 feels like a Betrayal to your Family 😬',
    description: (
      <>
        <p>
          Coming out is a journey filled with both hope and fear. It's not just
          about embracing our true identity - it's also about the deep fear of
          rejection from the people we love most. Balancing the need to be
          authentic with the desire to protect family ties can lead to a
          constant inner conflict. The fear of hurting those who have raised and
          supported us, coupled with the anxiety of being ostracized by our
          community and religion, can make us feel trapped and isolated.
        </p>
        <br />
        <p>
          Coming out is a journey filled with both hope and fear. It's not just
          about embracing our true identity - it's also about the deep fear of
          rejection from the people we love most. Balancing the need to be
          authentic with the desire to protect family ties can lead to a
          constant inner conflict. The fear of hurting those who have raised and
          supported us, coupled with the anxiety of being ostracized by our
          community and religion, can make us feel trapped and isolated.
        </p>
      </>
    ),
  },
  {
    id: 'uy0gs8754m8',
    thumbnail: 'https://img.youtube.com/vi/uy0gs8754m8/hqdefault.jpg',
    date: 'Sep 7, 2024',
    duration: '53:31',
    title:
      'HeyJuni Unplugged Ep3: The Emotional Weight ❤️‍🩹 of Childhood Trauma 😱',
    description: (
      <>
        <p>
          Women who have experienced childhood trauma often carry deep emotional
          scars into adulthood, affecting their relationships, self-worth, sense
          of security, and overall well-being. Unresolved pain can manifest as
          trust issues, depression, anxiety, and self-blame.
        </p>
        <br />
        <p>
          Healing starts with acknowledging the pain, no matter how difficult it
          is to face. Opening up about past experiences and grieving the lost
          childhood can help lift the weight of isolation and emotional pain.
          The process takes time, and it's important to move at one's own pace,
          finding strength in resilience and compassion for the child they once
          were. By addressing this trauma, many women not only heal but also
          break the cycle of passing unresolved pain to future generations,
          creating a healthier emotional legacy.
        </p>
      </>
    ),
  },
  {
    id: 'jIPUJ_7xF6Y',
    thumbnail: 'https://img.youtube.com/vi/jIPUJ_7xF6Y/hqdefault.jpg',
    date: 'Sep 18, 2024',
    duration: '43:58',
    title:
      'HeyJuni Unplugged Ep4: Breaking the cycle, surviving narcissistic parents & reclaiming mental health',
    description: (
      <>
        <p>
          In this episode, we dive into the complex dynamics of growing up with
          a narcissistic parent. Explore the emotional impact, hidden traumas,
          and lasting effects on mental health. Learn practical strategies for
          healing, breaking the toxic cycle, and reclaiming your sense of self.
          Whether you've experienced it first-hand or want to understand the
          struggles of others, this conversation offers valuable insights and
          hope for recovery 💪❤️
        </p>
      </>
    ),
  },
  {
    id: 'K7aQCBiCx-c',
    thumbnail: 'https://img.youtube.com/vi/K7aQCBiCx-c/hqdefault.jpg',
    date: 'Oct 14, 2024',
    duration: '41:52',
    title:
      'HeyJuni Unplugged Ep5: Negative Body Image 🫠 and the Journey to Self-Acceptance 🌿',
    description: (
      <>
        <p>
          In this deeply personal episode, we have the honor of sharing Aishah's
          powerful story - a story filled with vulnerability, courage, and
          transformation. As a child, Aishah faced cruel rejection and painful
          comments about her appearance, particularly due to her spine
          deformity. These external voices shaped her internal dialogue, making
          her feel disconnected from her body, leading her down a difficult path
          of self-loathing.
        </p>
        <br />
        <p>
          As reconstructive surgeries failed and the physical pain worsened, she
          lost faith in ever having a "normal" body. Her desperate need for
          control in an environment filled with constant judgment and remarks
          took over, and that control manifested in regulating her food intake.
          What started as a way to cope soon spiraled into an eating disorder,
          anorexia nervosa, which pushed her to dangerous extremes - going
          without food for days and watching her weight plummet to just 40kg.
        </p>
        <br />
        <p>
          Through her story, Aishah shares the raw truth of what it's like to
          battle both physical and emotional pain, and how she found the
          strength to climb out of the darkness. Her ability to rise above the
          darkness she once felt speaks volumes about the strength of the human
          spirit.
        </p>
        <br />
        <p>
          Today, she stands as an advocate and a respected leader in the mental
          health community. As HeyJuni's Domain Advisor and Trainer, Aishah
          continues to give back by guiding others through their own mental
          health journeys, fueled by the wisdom she's gained from her own
          experiences.
        </p>
        <br />
        <p>
          We are honored to have Aishah as part of our team, and we are deeply
          grateful for her passion, dedication, and strength. This episode is a
          testament to her resilience, and we hope her story inspires you as
          much as it has inspired us.
        </p>
        <br />
        <p>
          🌸 Join us for this episode that's not only a story of survival, but a
          powerful reminder that even in the darkest moments, hope and healing
          are possible. 🌸
        </p>
      </>
    ),
  },
  {
    id: 'OoGvDEcWbL8',
    thumbnail: 'https://img.youtube.com/vi/OoGvDEcWbL8/hqdefault.jpg',
    date: 'Oct 14, 2024',
    duration: '41:52',
    title:
      'HeyJuni Unplugged Ep6: 💔 Emotional abuse, sexual violence, or psychological trauma? 💔',
    description: (
      <>
        <p>
          In this episode, Malia, Nora and Pradnya uncover the heartbreaking
          stories of violence against women - some close to them - and how they
          are trapped in cycles of abuse by their partners in the most inhumane
          ways. 😢
        </p>
        <br />
        <p>
          We delve into the painful reality of trauma bonding, the hidden
          psychological chains that keep so many women trapped. Why don't they
          leave? Why don't they speak up? Why do they hesitate to change their
          situation?
        </p>
        <br />
        <p>
          Join us as we unpack the layers of shame, stigma, and fear that so
          many women face. It's time we understand the survivors' reality and
          help break the silence. 🕊️
        </p>
      </>
    ),
  },
  {
    id: '3ZJ_WiYhIKM',
    thumbnail: 'https://img.youtube.com/vi/3ZJ_WiYhIKM/hqdefault.jpg',
    date: 'Nov 13, 2024',
    duration: '32:34',
    title:
      'HeyJuni Unplugged Ep7: Online Dating Violence - have you been abused online before?',
    description: (
      <>
        <p>
          Online dating can be a minefield for many, with verbal, emotional,
          financial, and visual abuse often lurking behind digital screens. But
          when these acts extend beyond the online space, the risks could
          escalate into real-life sexual violence. In today's podcast, we dive
          into the realities of online dating violence, what consent truly
          means, and the contexts in which dating violence occurs. We also shed
          light on why so many women remain silent - out of shame, fear, or
          self-blame - and discuss the importance of speaking out and sharing
          their story.
        </p>
        <br />
        <p>
          Joining us today is Pradnya, an experienced social worker and
          passionate advocate for mental health, women's health, and peer
          support. Pradnya's work comes straight from the heart - as HeyJuni's
          Lead Listener, she embodies what it means to truly be there for
          others: Listening without judgment, offering understanding, and
          standing by women when they need it most. She's been on the front
          lines of mental health advocacy, breaking down the stigma that keeps
          so many silent.
        </p>
        <br />
        <p>
          At HeyJuni, we support women who have gone through such painful
          experiences and are facing sexual assault. We are an instant, 24/7
          female-to-female emotional support platform dedicated to helping women
          process trauma from sexual violence. We provide a safe space to share,
          reflect, and guide you toward emotional healing in the moment -
          whenever and wherever you need it most.
        </p>
        <br />
        <p>
          Join the conversation, know you're not alone, and reach out for
          support when you need it.
        </p>
      </>
    ),
  },
];
export const videos = shuffleArray(videosRaw);

export function Videos() {
  const [searchParams] = useSearchParams();
  const videoId = searchParams.get('id');

  const fetchVideos = async () => {
    const response = await fetch(
      `${API_URL}?part=snippet&channelId=${CHANNEL_ID}&maxResults=${MAX_RESULTS}&order=date&type=video&key=${API_KEY}`
    );
    const data = await response.json();

    return data.items;
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  return (
    <section className="px-[28px] md:px-[76px]">
      <h2 className="text-5xl md:text-7xl font-extrabold leading-[48px] md:leading-[65px] mb-[40px]">
        Today's Featured Podcast:
      </h2>
      <VideoCard
        id={videos[0].id}
        thumbnail={videos[0].thumbnail}
        date={videos[0].date}
        duration={videos[0].duration}
        title={videos[0].title}
        description={videos[0].description}
        isSelected={videos[0].id === videoId}
      />

      <h2 className="text-5xl md:text-7xl font-extrabold leading-[48px] md:leading-[65px] mb-[40px]">
        Other Videos & Podcasts:
      </h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-x-[40px] gap-y-[70px]">
        {videos.slice(1).map((video, index) => (
          <VideoCard
            key={index}
            id={video.id}
            thumbnail={video.thumbnail}
            date={video.date}
            duration={video.duration}
            title={video.title}
            description={video.description}
            isSelected={video.id === videoId}
          />
        ))}
      </div>
    </section>
  );
}

interface VideoCardProps {
  id: string;
  thumbnail: string;
  date: string;
  duration: string;
  title: string;
  description: string | JSX.Element;
  isSelected: boolean;
}

function VideoCard(props: VideoCardProps) {
  const { id, thumbnail, date, duration, title, description, isSelected } =
    props;
  const [isOpenVideo, setIsOpenVideo] = useState(isSelected);
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <div className="overflow-hidden flex flex-col gap-[20px] items-start pb-[14px]">
        <div className="relative w-full" onClick={() => setIsOpenVideo(true)}>
          <img
            src={thumbnail}
            alt="Video Thumbnail"
            className="w-full h-auto cursor-pointer"
          />
          <img
            src={youtubeIcon}
            className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer scale-50 md:scale-100"
            alt="Youtube Icon"
          />
        </div>

        <div className="text-base leading-[24px] text-[#888888]">
          {date} • {duration}
        </div>

        <div className="font-extrabold text-[28px] md:text-5xl leading-[36px] md:leading-[45px]">
          {title}
        </div>

        <div>
          <div
            className={`text-[15px] md:text-lg leading-[22px] md:leading-[26px] ${
              isExpanded ? '' : 'line-clamp-5'
            }`}
          >
            {description}
          </div>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="underline"
          >
            {isExpanded ? 'Read Less' : 'Read More'}
          </button>
        </div>

        <div
          className="inline-block border border-black rounded-full px-[14px] py-[8px] text-center text-sm font-medium hover:bg-black hover:text-white transition cursor-pointer"
          onClick={() => setIsOpenVideo(true)}
        >
          View more
        </div>
      </div>

      <PopUp
        isOpen={isOpenVideo}
        onClose={() => {
          setIsOpenVideo(false);

          // Remove the id query parameter
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.delete('id');
          navigate({ search: searchParams.toString() }, { replace: true });
        }}
        className="flex justify-center items-center !rounded-[0px]"
        isGlobalOverlap={true}
      >
        <div
          className="relative bg-black"
          style={{
            width: '80vw', // Default width for larger screens
            height: 'calc(80vw * (9 / 16))', // Maintain 16:9 aspect ratio
            maxWidth: '1200px', // Optional max width
            maxHeight: '675px', // Optional max height to prevent overly large modals
          }}
        >
          <iframe
            className="absolute top-0 left-0 w-full h-full shadow-md"
            src={`https://www.youtube.com/embed/${id}`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        {/* Responsive styles for smaller screens */}
        <style>{`
    @media (max-width: 768px) {
      div.relative {
        width: calc(100vw - 32px) !important; /* Full width minus padding for mobile */
        height: calc((100vw - 32px) * (9 / 16)) !important; /* Maintain 16:9 aspect ratio */
      }
    }
  `}</style>
      </PopUp>
    </>
  );
}
