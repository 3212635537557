import React, { useContext, useState } from 'react';

import { useBookingContext } from './BookingContext';
import Option from './components/Option';
import { IntenseSlider } from './components/IntenseSlider';

import state1Icon from '@assets/footages/booking/intense-level-1.svg';
import state2Icon from '@assets/footages/booking/intense-level-2.svg';
import state3Icon from '@assets/footages/booking/intense-level-3.svg';
import state4Icon from '@assets/footages/booking/intense-level-4.svg';
import state5Icon from '@assets/footages/booking/intense-level-5.svg';
import state6Icon from '@assets/footages/booking/intense-level-6.svg';
import state7Icon from '@assets/footages/booking/intense-level-7.svg';
import state8Icon from '@assets/footages/booking/intense-level-8.svg';
import state9Icon from '@assets/footages/booking/intense-level-9.svg';
import state10Icon from '@assets/footages/booking/intense-level-10.svg';
import NormalObject from '@components/NormalObject';

// Dummy state data for 10 sections with icons
const states = [
  { label: 'State 1', icon: state1Icon },
  { label: 'State 2', icon: state2Icon },
  { label: 'State 3', icon: state3Icon },
  { label: 'State 4', icon: state4Icon },
  { label: 'State 5', icon: state5Icon },
  { label: 'State 6', icon: state6Icon },
  { label: 'State 7', icon: state7Icon },
  { label: 'State 8', icon: state8Icon },
  { label: 'State 9', icon: state9Icon },
  { label: 'State 10', icon: state10Icon },
];

export function Step05() {
  const { setCurrentStep, status, setStatus } = useBookingContext();

  return (
    <>
      <div className="booking-step-title">
        From 1-10, how intense are your feelings right now?
      </div>

      <div className="mt-[66px] md:mt-[20px] md:border md:border-gray-200 md:shadow-[0_0_4px_rgba(0,0,0,0.1)] md:rounded-[36px] md:pt-[40px] md:px-[12px] md:h-[284px]">
        <img
          src={states[status].icon}
          className="mx-auto h-[77px] w-[77px]"
          alt="Status Icon"
        />
        <div className="md:mt-[62px]">
          <IntenseSlider
            states={states}
            currentSection={status}
            onChangeCurrentSection={setStatus}
          />
          <div className="flex justify-between font-semibold md:font-regular text-sm md:text-lg md:leading-[26px] mt-[21px]">
            <div>
              <div className="text-center">1</div>
              <div>Not Intense</div>
            </div>
            <div>
              <div className="text-center">10</div>
              <div>Very Intense</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
