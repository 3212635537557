import { trpc } from '@src/App';
import { fetchCountry } from '@src/utils/country';
import { useEffect, useRef, useState } from 'react';
import { z } from 'zod';

export function LetJoinSection() {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const subscriptionMutation =
    trpc.subscription.createSubscription.useMutation();

  const subscribe = async () => {
    if (!email) return; // Check if the email is not empty
    const emailSchema = z.string().email();
    const parseResult = emailSchema.safeParse(email);
    if (!parseResult.success) {
      // Check if the email is valid
      alert('Invalid email address');
      return;
    }

    try {
      setIsLoading(true);
      const countryCode = await fetchCountry();
      await subscriptionMutation.mutateAsync({
        email,
        locale: countryCode === 'VN' ? 'vn' : 'global',
      });
      setSubscribed(true);
      setEmail('');
    } catch (error) {
      console.error('Subscription failed:', error);
    }
    setIsLoading(false);
  };

  return (
    <div className="relative">
      <AnimationBackground />
      <div className="absolute p-6 md:pt-10 md:px-16 z-10 left-0 top-0 right-0 flex flex-row gap-5 md:gap-0 md:justify-between items-center md:items-start md:gap-40">
        <div className="w-1/2 md:w-auto space-y-5 text-[15px] leading-[22px] md:text-2xl md:leading-8">
          <p>
            Có phải những khó khăn trong cuộc sống khiến bạn cảm thấy bức bối,
            lo âu, cô độc hay buồn bã? Hãy trải nghiệm tham vấn đồng đẳng với
            bọn mình để giải tỏa cảm xúc, giải quyết vấn đề và phục hồi tâm lý.
            HeyJuni sẽ là nơi an toàn, luôn bên bạn để chữa lành mọi lúc, mọi
            nơi.
          </p>
          <p>
            HeyJuni - nền tảng hỗ trợ tâm lý cho phụ nữ, được tạo nên bởi phụ
            nữ.
          </p>
        </div>
        <div className="md:min-w-80 text-left md:text-right font-extrabold text-5xl leading-[48px] md:text-7xl md:leading-[76px] md:transform md:-translate-y-5">
          <p>CÙNG</p>
          <p>THAM</p>
          <p>GIA</p>
        </div>
      </div>

      <div className="absolute px-6 py-6 md:px-16 z-10 left-0 bottom-0 right-0 ">
        <div className="relative md:h-[300px] w-full md:flex md:flex-row justify-between overflow-hidden md:pt-12 text-center md:text-left">
          <div className="md:hidden font-extrabold md:text-5xl md:leading-[45px]">
            Hỗ trợ sức khỏe tinh thần nhanh và gần bạn hơn.
          </div>

          <div className="md:w-3/5 space-y-5">
            <div className="font-extrabold text-5xl md:text-6xl">
              Nhắc mình nha
            </div>

            <div className="flex items-center rounded-[18px] overflow-hidden w-full md:w-[487px] h-[46px] md:h-[72px] mt-5 md:mt-10">
              <input
                type="email"
                placeholder="Điền email để nhận thông báo..."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="h-full flex-grow text-[15px] md:text-2xl p-[12px] md:p-[20px] focus:outline-none placeholder-gray-400 bg-gray-100"
              />
              <button
                onClick={subscribe}
                disabled={isLoading}
                className="h-full bg-[#D8FFF3] text-[15px] md:text-lg px-[18px] md:px-8 hover:bg-[#9DFFE2] transition-colors"
              >
                GỬI
              </button>
            </div>
          </div>

          <div className="hidden md:block md:w-2/5 text-right font-extrabold md:text-5xl md:leading-[45px]">
            Hỗ trợ sức khỏe tinh thần nhanh và gần bạn hơn.
          </div>
        </div>
      </div>
    </div>
  );
}

function AnimationBackground() {
  const HEIGHT = 300;

  const [translateX1, setTranslateX1] = useState<number>(50);
  const initTranslateX2 = -(
    2 * document.documentElement.clientWidth +
    HEIGHT * 3
  );
  const [translateX2, setTranslateX2] = useState<number>(initTranslateX2);

  const [shouldTransition, setShouldTransition] = useState(true);

  const [screenWidth, setScreenWidth] = useState<number>(
    document.documentElement.clientWidth
  ); // Exclude scrollbar width

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(document.documentElement.clientWidth); // Update screen width on resize
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
  }, []);

  const frameIndex1 = useRef(1);
  const frameIndex2 = useRef(1);

  useEffect(() => {
    const startAnimation = () => {
      setTranslateX1(50 + (screenWidth + 100) * frameIndex1.current);
      frameIndex1.current += 1;

      switch (frameIndex2.current) {
        case 1:
          setTranslateX2(-screenWidth - HEIGHT * 3);
          break;
        case 2:
          setTranslateX2(-screenWidth - HEIGHT);
          break;
        case 3:
          setTranslateX2(-HEIGHT);
          break;
      }
      frameIndex2.current += 1;

      setTimeout(() => {
        setShouldTransition(false);

        if (frameIndex1.current === 4) {
          setTranslateX1(50);
          frameIndex1.current = 1;
        }

        if (frameIndex2.current === 4) {
          setTranslateX2(initTranslateX2);
          frameIndex2.current = 1;
        }

        setTimeout(() => {
          setShouldTransition(true);
          startAnimation();
        }, 500);
      }, 2000); // Pause at the target for the duration of the transition
    };

    startAnimation(); // Start the animation on mount
  }, [screenWidth]);

  return (
    <div className="relative overflow-hidden w-full">
      <div
        className="whitespace-nowrap h-[300px]"
        style={{
          transform: `translateX(${-translateX1}px)`, // Dynamic horizontal position
          transition: shouldTransition ? 'transform 1s linear' : 'none',
        }}
      >
        <ColorBlock
          color="bg-purple-400"
          rounded="rounded-b-full"
          width={screenWidth + 100}
        />
        <ColorBlock
          color="bg-yellow-400"
          rounded="rounded-b-full"
          width={screenWidth + 100}
        />
        <ColorBlock
          color="bg-yellow-400"
          rounded="rounded-b-full"
          width={screenWidth + 100}
        />
        <ColorBlock
          color="bg-purple-400"
          rounded="rounded-b-full"
          width={screenWidth + 100}
        />
      </div>

      <div
        className="whitespace-nowrap h-[300px]"
        style={{
          transform: `translateX(${translateX2}px)`, // Dynamic horizontal position
          transition: shouldTransition ? 'transform 1s linear' : 'none', // Apply or remove transition
        }}
      >
        <ColorBlock
          color="bg-yellow-400"
          rounded="rounded-t-full"
          width={HEIGHT * 2}
        />
        <ColorBlock
          color="bg-yellow-400"
          rounded="rounded-t-full"
          width={screenWidth}
        />
        <ColorBlock
          color="bg-purple-400"
          rounded="rounded-t-full"
          width={screenWidth}
        />
        <ColorBlock
          color="bg-yellow-400"
          rounded="rounded-t-full"
          width={HEIGHT * 2}
        />
        <ColorBlock
          color="bg-yellow-400"
          rounded="rounded-t-full"
          width={screenWidth}
        />
      </div>
    </div>
  );
}

interface ColorBlockProps {
  color: string;
  width: number;
  rounded: string;
}

function ColorBlock(props: ColorBlockProps) {
  const { color, width, rounded } = props;

  return (
    <div
      className={`inline-block ${color} h-full ${rounded} relative`}
      style={{ width: `${width}px` }}
    ></div>
  );
}
