import Button from '@components/Button';
import { openGoogleForm } from '@src/utils/open-googleform';

const cardData = [
  {
    src: 'become-a-listener/supporters-do-bg-01.webp',
    title: 'Identifying Triggers & Signs',
    description:
      'Recognizing triggers that cause anxiety or negative feelings, and understanding emotions linked to trauma and life challenges.',
  },
  {
    src: 'become-a-listener/supporters-do-bg-02.webp',
    title: 'Implementing Real-Time Support',
    description:
      'Providing Active Listening and empathy, assisting users in grounding anxiety and reducing emotional stress within each session.',
  },
  {
    src: 'become-a-listener/supporters-do-bg-03.webp',
    title: 'Providing Self-Help Tools',
    description:
      'Encouraging and equipping survivors with grounding and calming techniques they can use independently outside of the session.',
  },
  {
    src: 'become-a-listener/supporters-do-bg-04.webp',
    title: 'Nurturing Safe, Open Conversations',
    description:
      'Peer Counselors are survivors of trauma and hardships too, so the objective is to foster eye-to-eye, and open conversations.',
  },
  {
    src: 'become-a-listener/supporters-do-bg-02.webp',
    title: 'Practicing Professional Online Support',
    description:
      'Peer Counselors are trained, and utilize HeyJuni’s structured and supervised online counseling platform to support others.',
  },
  {
    src: 'become-a-listener/supporters-do-bg-01.webp',
    title: 'Building Long & Short-term Resilience',
    description:
      'Peer Counselors can help users cope in one session and build resilience over time, depending on the case.',
  },
];

export function SupportersDoSection() {
  return (
    <div className="mb-[84px] md:mb-0 md:px-[76px] md:py-[40px] text-center">
      <div className="hidden md:block text-center mb-[28px] font-extrabold text-7xl leading-[65px]">
      What Peer Counselors Do
      </div>
      <div className="md:hidden mb-[20px] text-center px-[28px] font-extrabold text-5xl leading-[48px]">
        <div>What Peer</div>
        <div>Counselors Do</div>
      </div>

      <div className="px-[28px] md:p-0 grid grid-cols-1 md:grid-cols-2 gap-[20px] md:gap-[40px]">
        {cardData.map((data, index) => (
          <Card
            key={index}
            src={data.src}
            title={data.title}
            description={data.description}
          />
        ))}
      </div>

      <Button
        label="Supporting with HeyJuni"
        className="mt-[20px] md:mt-[40px] h-[50px] md:h-[60px] px-[30px] font-semibold text-lg md:text-2xl rounded-[20px]"
        onClick={openGoogleForm}
      />
    </div>
  );
}

interface CardProps {
  src: string;
  title: string;
  description: string;
}

function Card(props: CardProps) {
  const { src, title, description } = props;
  return (
    <div
      className={`relative px-[28px] py-[20px] md:p-[32px] min-h-[160px] md:min-h-[264px] overflow-hidden text-left`}
    >
      <div
        className={`absolute top-0 left-0 w-full h-full`}
        style={{ zIndex: -1 }}
      >
        <img src={src} alt={title} className="w-full h-full object-cover" />
      </div>
      <h3 className="mb-[20px] md:mb-[32px] font-bold text-xl md:text-4xl md:leading-[40px]">
        {title}
      </h3>
      <p className="text-[15px] md:text-2xl leading-[22px] md:leading-[32px]">
        {description}
      </p>
    </div>
  );
}
