import React, { useState, useRef, useEffect } from 'react';
import sliderBar from '@assets/footages/booking/intense-level-slider.svg'; // Assume this now has 10 sections

// TODO: Replace with actual icons for 10 states
import sliderButton from '@assets/footages/booking/intense-level-slider-button.svg';
import NormalObject from '@components/NormalObject';

interface IntenseSliderProps {
  states: Array<any>;
  currentSection: number;
  onChangeCurrentSection: (value: number) => void;
}

export function IntenseSlider(props: IntenseSliderProps) {
  const { states, currentSection, onChangeCurrentSection } = props;

  // TODO: Fix init
  const [position, setPosition] = useState(currentSection / 9); // position in percentage
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef<HTMLDivElement>(null);

  // Helper to update the current section based on 10 sections
  const updateCurrentSection = (position: number) => {
    let section = Math.floor(position * 10); // Map position to section (0-9 for 10 sections)
    if (section > 9) {
      section = 9;
    }
    if (section < 0) {
      section = 0;
    }
    onChangeCurrentSection(section);
  };

  // Start drag (for both mouse and touch)
  const handleMouseDown = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  // Move drag (for both mouse and touch)
  const handleMove = (clientX: number) => {
    if (!isDragging || !sliderRef.current) return;

    const sliderRect = sliderRef.current.getBoundingClientRect();
    let newPosition = (clientX - sliderRect.left) / sliderRect.width;

    // Boundaries: ensure the position stays within the slider
    newPosition = Math.max(0, Math.min(newPosition, 1));

    setPosition(newPosition);
    updateCurrentSection(newPosition); // Update the section whenever the position changes
  };

  const handleMouseMove = (e: MouseEvent) => {
    handleMove(e.clientX);
  };

  const handleTouchMove = (e: TouchEvent) => {
    handleMove(e.touches[0].clientX);
  };

  // End drag (for both mouse and touch)
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Click on slider
  const handleSliderClick = (e: React.MouseEvent) => {
    if (!sliderRef.current) return;
    const sliderRect = sliderRef.current.getBoundingClientRect();
    const clickPosition = (e.clientX - sliderRect.left) / sliderRect.width;
    const clampedPosition = Math.max(0, Math.min(clickPosition, 1)); // Bound the position between 0 and 1

    setPosition(clampedPosition);
    updateCurrentSection(clampedPosition); // Update the section based on the clicked position
  };

  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => handleMouseMove(e);
    const onTouchMove = (e: TouchEvent) => handleTouchMove(e);
    const onMouseUp = () => handleMouseUp();

    if (isDragging) {
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
      window.addEventListener('touchmove', onTouchMove);
      window.addEventListener('touchend', onMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchend', onMouseUp);
    };
  }, [isDragging]);

  return (
    <div className="relative w-full mx-auto mt-8">
      {/* Slider Bar */}
      <div
        className="relative w-full h-4 cursor-pointer"
        onClick={handleSliderClick}
        ref={sliderRef}
      >
        <NormalObject data={sliderBar} className="w-full h-auto" />
      </div>

      {/* Draggable Icon */}
      <div
        className="absolute top-[-16px] md:top-[-24px] w-10 h-10 md:w-16 md:h-16 cursor-pointer"
        style={{
          left: `calc(${position * 100}% - 24px)`,
        }}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
      >
        <img src={sliderButton} className="w-full h-auto" alt="Slider Button" />
      </div>
    </div>
  );
}
