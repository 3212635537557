export function WomanSupportSection() {
  return (
    <div className="mt-[20px] md:mt-0 mb-[84px] md:mb-0 md:px-[76px] md:py-[64px] flex justify-between gap-[40px]">
      <div className="hidden md:block">
        <img
          src="woman-support.webp"
          alt="A collage of two images. The left image shows two women smiling at each other. The right image shows many hands joining, symbolizing care and unity."
          className="w-[544px] h-[394px]"
        />
      </div>
      <div className="flex-1">
        <div className="mb-[20px] md:mb-[28px] font-extrabold text-5xl md:text-7xl leading-[48px] md:leading-[65px] text-center md:text-left">
          <p>Support For Women,</p>
          <p>By Women</p>
        </div>
        <div className="px-[28px] md:px-0 text-[13px] leading-[20px] md:text-2xl md:leading-[32px]">
          <div className="md:hidden mb-[20px]">
            <img
              src="woman-support.webp"
              alt="A collage of two images. The left image shows two women smiling at each other. The right image shows many hands joining, symbolizing care and unity."
            />
          </div>
          Our female Peer Counselors are compassionate experts with lived
          experience in mental health. Drawing from their personal journeys,
          they offer empathetic support and guidance for those navigating trauma
          and anxiety.
        </div>
      </div>
    </div>
  );
}
