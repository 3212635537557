import React, { useContext } from 'react';

import { useBookingContext } from './BookingContext';

export function Step02() {
  const { setCurrentStep, anxiousReason, setAnxiousReason } =
    useBookingContext();

  return (
    <>
      <div className="booking-step-title">
      What’s on your mind? What’s weighing on your heart?
      </div>

      <div className="mt-[30px] flex-1 w-full flex flex-col gap-[15px] md:gap-[20px] justify-between">
        <textarea
          placeholder="Share what’s been challenging you - this is your space to let it out."
          className="flex-1 w-full min-h-[280px] md:min-h-0 py-[18px] px-[20px] md:p-[24px] border border-gray-300 rounded-[18px] font-regular text-base md:text-lg placeholder-gray-400 resize-none"
          value={anxiousReason}
          onChange={(e) => setAnxiousReason(e.target.value)}
        ></textarea>

        <div className="text-center text-[#999999] font-regular md:font-semibold mb-[20px]">
          <div className="text-base md:text-lg">
            If writing feels difficult,
          </div>
          <a
            className="text-sm md:text-base underline cursor-pointer"
            onClick={() => setCurrentStep(3)}
          >
            skip here
          </a>
        </div>
      </div>
    </>
  );
}
