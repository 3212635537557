import React, { useContext, useState } from 'react';
import { PreferredMode } from '@source/types';

import { useBookingContext } from './BookingContext';
import Option from './components/Option';

const options = [
  { label: 'Text Messaging', value: PreferredMode.TEXT },
  { label: 'Voice Call', value: PreferredMode.VOICE },
  { label: 'Video Call', value: PreferredMode.VIDEO },
];

export function Step09() {
  const { setCurrentStep, dataList09, setDataList09 } = useBookingContext();

  return (
    <>
      <div className="booking-step-title">
      Please select your preferred way to communicate with your Peer Counselor.
        <br />
        Each session is 50 minutes long.
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-[30px] md:mt-[55px] gap-[15px]">
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            label={option.label}
            isSelected={dataList09.includes(option.value)}
            onClick={() => {
              setDataList09([option.value]);
            }}
            themeColor="md:bg-[#D8CFFC]"
            mobileThemeColor="bg-[#D8FFF3]"
            height="md:h-[75px]"
            mobileHeight="h-[60px]"
          />
        ))}
      </div>
    </>
  );
}
