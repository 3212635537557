import { isOffTime } from '@src/utils/time';
import { BookingTimeType } from '@src/types/booking';
import 'react-datepicker/dist/react-datepicker.css';
import Option from './components/Option';

import AppointmentSelector from './components/AppointmentSelector';
import { useBookingContext } from './BookingContext';
import Button from '@components/Button';

const options = [
  { label: 'Book Session Now', value: BookingTimeType.BOOK_NOW },
  { label: 'Schedule Session', value: BookingTimeType.SCHEDULE_SESSION },
];

interface Step10Props {
  setOpenBookNowPopup: (value: boolean) => void;
}

export function Step10(props: Step10Props) {
  const { setOpenBookNowPopup } = props;
  const { bookingTimeType, setBookingTimeType } = useBookingContext();

  const isOff = isOffTime();

  return (
    <>
      <div className="hidden md:block booking-step-title">
        <div>
          Please select an option which works best for you, and we'll be ready
          to assist.
        </div>
      </div>
      <div className="md:hidden booking-step-title">
        Please select the date and time to book a session
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 mt-[30px] mb-[30px] gap-[15px]">
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            label={option.label}
            isSelected={bookingTimeType === option.value}
            onClick={() => {
              if (option.value === BookingTimeType.SCHEDULE_SESSION) {
                setBookingTimeType(BookingTimeType.SCHEDULE_SESSION);
                return;
              }

              if (!isOff) setOpenBookNowPopup(true);
            }}
            themeColor="md:bg-[#D8CFFC]"
            mobileThemeColor="bg-[#D8FFF3]"
            height="md:h-[75px]"
            mobileHeight="h-[60px]"
            disabled={isOff && option.value === BookingTimeType.BOOK_NOW}
          />
        ))}
      </div>

      {bookingTimeType === BookingTimeType.SCHEDULE_SESSION && (
        <AppointmentSelector />
      )}

      {isOff && !bookingTimeType && <OffTimeSection />}
    </>
  );
}

function OffTimeSection() {
  const { setBookingTimeType } = useBookingContext();

  return (
    <div className="flex flex-col items-center justify-center w-full p-[24px] md:py-[20px] md:px-[30px] md:mb-[30px] bg-white rounded-[17px] border border-gray-300 shadow-md text-center space-y-[16px]">
      <div className="flex flex-col items-center">
        <div className="md:text-4xl md:leading-[40px] font-bold md:mt-[8px]">
          We'll be available for instant sessions after a short pause.
        </div>
      </div>
      <Button
        label="Schedule Session Ahead"
        className="h-[50px] px-[30px] md:px-[30px] rounded-[20px] font-semibold md:text-lg"
        onClick={() => setBookingTimeType(BookingTimeType.SCHEDULE_SESSION)}
      />
      <p className="text-[15px]">
        We’re deeply sorry. Our instant support is momentarily on a short
        technical break. Thank you for understanding. Please consider scheduling
        a session in advance.
      </p>
    </div>
  );
}
