import React from 'react';

export default function HealingCopingAndBuildingResilience() {
  return (
    <div>
      <img
        src="blog/articles/healing-coping-and-building-resilience.webp"
        alt="healing-coping-and-building-resilience"
        className="w-full"
      />
      <p className="mt-5 text-[15px] md:text-lg">
        If you or someone you care about has experienced sexual violence,
        remember that the journey to recovery is not linear, nor is it
        one-size-fits-all. Seeking support from trusted individuals,
        professionals, or survivor networks can make a significant difference.
        Healing takes time, and each step—no matter how small—represents
        progress. Recovery from SV is deeply personal, and there are multiple
        paths to finding peace and reclaiming autonomy.
      </p>
      <ol className="mt-5 list-disc list-inside inline-block text-[15px] md:text-lg">
        <li>
          Therapy and Counseling: Trauma-focused therapies, like Cognitive
          Behavioral Therapy (CBT) and Eye Movement Desensitization and
          Reprocessing (EMDR), are particularly effective for PTSD and trauma.
          Therapy offers a safe space to process the experience and develop
          coping strategies.
        </li>
        <li>
          Support Groups: Sharing experiences in a group setting can help those
          affected feel understood, reducing isolation and stigma. Hearing
          others' stories can be incredibly healing and empowering.
        </li>
        <li>
          Mindfulness and Grounding Exercises: Mindfulness techniques, such as
          deep breathing and meditation, can help calm the nervous system.
          Practicing these exercises daily can provide moments of peace amid
          anxiety.
        </li>
      </ol>
      <p className="text-xl md:text-4xl font-bold mt-16">
        Try This Effective Technique
      </p>
      <p className="mt-5 text-[15px] md:text-lg">
        You can try this quick grounding technique to center yourself. Take a
        deep breath and identify:
      </p>
      <ol className="mt-5 list-disc list-inside inline-block text-[15px] md:text-lg">
        <li>5 things you can see</li>
        <li>4 things you can touch</li>
        <li>3 things you can hear</li>
        <li>2 things you can smell</li>
        <li>1 thing you can taste</li>
      </ol>
      <p className="mt-2 text-[15px] md:text-lg">
        Practicing grounding techniques like these during moments of stress can
        help bring you back to the present and reduce the intensity of negative
        emotions.
      </p>
      <p className="text-xl md:text-4xl font-bold mt-16">
        Finding Empowerment in the Healing Process
      </p>
      <p className="mt-5 text-[15px] md:text-lg">
        The path to healing is ongoing, and while the journey may be
        challenging, it can also be transformative. Those who have experienced
        SV often discover resilience, strength, and a newfound sense of purpose
        in their lives, whether through advocacy, helping others, or simply
        embracing self-compassion.
      </p>
      <p className="mt-5 text-[15px] md:text-lg">
        ****Consider writing a letter to yourself from a future version who has
        found healing. What advice, encouragement, or wisdom might that future
        self share? This can be a powerful way to reconnect with hope and
        resilience.
      </p>
      <p className="text-xl md:text-4xl font-bold mt-16">
        Moving Forward, Together
      </p>
      <p className="mt-5 text-[15px] md:text-lg">
        The mental health impact of sexual violence is profound and complex,
        touching every aspect of one's life. However, with the right support
        systems—be it therapy, community, or loved ones—healing is possible. If
        you or someone you know has been affected by SV, remember that help is
        available, and you don't have to face this journey alone. Talking about
        these effects, educating ourselves, and offering understanding are all
        ways we can support each other toward recovery.
      </p>
    </div>
  );
}
