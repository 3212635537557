import React, { useState } from 'react';

interface ChipProps {
  text: string;
  isSelected: boolean;
  select: () => void;
}

export default function Chip({ text, isSelected, select }: ChipProps) {
  return (
    <div
      className={`inline-block cursor-pointer px-3 rounded-full transition-colors border border-black select-none ${
        isSelected ? 'bg-[#181818] text-white' : 'bg-transparent text-black'
      }`}
      onClick={select}
    >
      <span
        className={`text-center ${isSelected ? 'text-white' : 'text-black'}`}
      >
        {text}
      </span>
    </div>
  );
}
