import Button from '@components/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// TODO: Will use Trans

export function IntroSection() {
  const navigate = useNavigate();
  const { t } = useTranslation('home');

  return (
    <div className="flex flex-col lg:flex-row mb-[64px]">
      <div className="md:hidden pl-[26px]">
        <img
          src="hero-mobile.webp"
          alt={t('alt.home.hero', { ns: 'common' })}
          className="w-full h-[413]"
        />
      </div>

      <div className="flex flex-col px-[26px] md:pl-[76px] md:pr-0 mt-[20px] md:mt-[106px] md:pt-0">
        <div className="hidden md:block font-extrabold md:text-8xl md:leading-[85px] tracking-[-0.03em]">
          <p>{t('introduction.title-01')}</p>
          <p>{t('introduction.title-02')}</p>
        </div>

        <div className="md:hidden font-extrabold text-5xl leading-[48px]">
          <p>{t('introduction.title-01')}</p>
          <p>{t('introduction.title-02')}</p>
        </div>

        <div className="font-bold md:font-semibold text-xl md:text-3xl mt-[20px]">
          <p>{t('introduction.subtitle')}</p>
        </div>

        <p className="mt-[20px] md:mt-[28px] font-regular text-base md:text-2xl md:pr-[68px] leading-[22px] md:leading-[32px]">
          {t('introduction.content')}
        </p>
        <Button
          label="Learn More"
          className="font-semibold mt-[20px] md:mt-[28px] text-lg md:text-2xl h-[50px] md:h-[60px] w-[154px] md:w-[174px] rounded-[20px] md:rounded-2xl"
          onClick={() => navigate('/learn-more')}
        />
      </div>
      <img
        src="hero.webp"
        alt={t('alt.home.hero', { ns: 'common' })}
        className="hidden lg:block h-full object-contain"
      />
    </div>
  );
}
