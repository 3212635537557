import healing01StepImg from '@assets/footages/learn-more/healing-step01.svg';
import healing02StepImg from '@assets/footages/learn-more/healing-step02.svg';
import healing03StepImg from '@assets/footages/learn-more/healing-step03.svg';
import healing04StepImg from '@assets/footages/learn-more/healing-step04.svg';
import healing05StepImg from '@assets/footages/learn-more/healing-step05.svg';

import clsx from 'clsx';
import { Swipe } from '@components/Swipe';
import { MobileSwipe } from '@components/MobileSwipe';

const cards = [
  {
    title: 'Healing Starts With Sharing Your Story',
    description: `The first step is yours: being ready to talk about what happened. If you've faced trauma, mistreatment, abuse, or violence — or if you're feeling lonely, burned out, stressed, sad, or lost — it's a sign to speak up. Sharing your pain is the beginning of your healing journey.`,
    image: 'learn-more/healing01.webp',
    imageMobile: 'learn-more/healing01-mobile.webp',
    alt: 'A black and white image of a woman’s profile. She looks down, wrapping her arms around her shoulders. From the side, her face looks sentimental yet hopeful.',
  },
  {
    title: 'Are You That Person...',
    description: `...no one worries about because you always show up when they need you? You're that person who always says “I'm okay” even though nothing is okay. Everyone thinks you're strong, optimistic, and that you have it all together. But the truth is… you're suffering in silence and no one knows.`,
    image: 'learn-more/healing02.webp',
    imageMobile: 'learn-more/healing02-mobile.webp',
    alt: 'A black and white image of a woman putting both hands on her face. She looks like she is crying and in desperation. This symbolizes the sadness, loneliness and frustrations that this woman is facing.',
  },
  {
    title: 'Why Talking Is Therapeutic',
    description: `Talking about your struggles is like releasing a weight, letting the inner storm settle. It clears mental obstacles, creating space for healing and understanding. Scientifically, expressing emotions activates the prefrontal cortex, regulating negativity and lowering stress hormones, while boosting oxytocin, which promotes trust and comfort.`,
    image: 'learn-more/healing03.webp',
    imageMobile: 'learn-more/healing03-mobile.webp',
    alt: 'Image of a young women holding a mobile phone infront of her face. This symbolizes communication, conversations and sharing. Talking and expressing feelings emotions is the first step toward healing.',
  },
  {
    title: `What Can You Talk About With HeyJuni's Peer Counselor`,
    description: `You can talk about: Difficult relationships with family, friends, or partners • Childhood experiences and trauma • Dealing with challenging personalities including narcissists • Feelings of anger or resentment towards parents or loved ones • Experiences of mistreatment or intimate partner violence • Past or current abuse or assault and more.`,
    image: 'learn-more/healing04.webp',
    imageMobile: 'learn-more/healing04-mobile.webp',
    alt: 'Image of a young women covering her face with one hand. She looks sad but it seems like she’s also ready to talk about the struggles she is facing.',
  },
  {
    title: 'Find Empowerment, A Sense of Peace, and Inner Strength',
    description:
      "Talking to HeyJuni's Peer Counselors enables emotional growth and resilience. By sharing your feelings, you'll experience relief and gain insights into the triggers and signs of your distress. Through supportive conversations, you'll develop effective coping and grounding strategies, building your ability to navigate life's challenges.",
    image: 'learn-more/healing05.webp',
    imageMobile: 'learn-more/healing05-mobile.webp',
    alt: 'A profile image of a young woman looking reflective, gazing inward as she embraces her journey toward inner strength and resilience.',
  },
];

const desktopCards = [
  {
    title: 'Find Empowerment, A Sense of Peace, and Inner Strength',
    description:
      "Talking to HeyJuni's Peer Counselors enables emotional growth and resilience. By sharing your feelings, you'll experience relief and gain insights into the triggers and signs of your distress. Through supportive conversations, you'll develop effective coping and grounding strategies, building your ability to navigate life's challenges.",
    image: 'learn-more/healing05.webp',
    imageMobile: 'learn-more/healing05-mobile.webp',
    alt: 'A profile image of a young woman looking reflective, gazing inward as she embraces her journey toward inner strength and resilience.',
  },
  ...cards,
  {
    title: '',
    description: '',
    image: '',
    imageMobile: '',
    alt: '',
  },
];

const currentStepList = [
  healing01StepImg,
  healing02StepImg,
  healing03StepImg,
  healing04StepImg,
  healing05StepImg,
];

const desktopCurrentStepList = [healing05StepImg, ...currentStepList, null];

const CARD_WIDTH = 664;
const GAP = 76;
const BOUNDARY_PADDING = 76;

export function HealingSwipe() {
  return (
    <Swipe
      currentStepList={desktopCurrentStepList}
      cardWidth={CARD_WIDTH}
      gap={GAP}
      boundaryPadding={BOUNDARY_PADDING}
    >
      {desktopCards.map((card, index) => {
        const className =
          index === 0
            ? 'md:pr-[38px] md:pl-[76px]'
            : index === desktopCards.length - 1
              ? 'md:pl-[38px] md:pr-[76px]'
              : 'md:px-[38px]';

        return (
          <Card
            title={card.title}
            description={card.description}
            image={card.image}
            imageMobile={card.imageMobile}
            className={clsx('pb-[10px]', className)}
            alt={card.alt}
          />
        );
      })}
    </Swipe>
  );
}

interface CardProps {
  title: string;
  description: string;
  image: string;
  imageMobile: string;
  className?: string;
  alt: string;
}

function Card(props: CardProps) {
  const { title, description, image, imageMobile, className, alt } = props;
  const emptyCard = !title;
  const emptyCardWith = (window.innerWidth - 2 * GAP - CARD_WIDTH) / 2 - 16; // TODO: 16 is just a delta param, maybe have a more robust solution

  if (emptyCard) {
    return (
      <div className={clsx(className)}>
        <div
          className="relative md:w-[664px] md:h-[357px] overflow-hidden"
          style={{
            ...(window.innerWidth >= 768 && {
              width: `${emptyCardWith}px`,
            }),
          }}
        ></div>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        'hidden md:inline-flex flex-col gap-[28px] md:gap-[20px] items-center',
        className
      )}
    >
      <div className="w-[88%] h-[452px] md:w-[664px] md:h-[357px] flex flex-row overflow-hidden rounded-[20px] shadow-[0_5px_10px_rgba(0,0,0,0.1)]">
        <div className="flex-1">
          <div className="px-[12px] py-[8px] md:px-[20px] md:pt-[20px] font-semibold text-base md:text-3xl">
            {title}
          </div>
          <div className="px-[12px] py-[8px] md:p-[20px] font-regular text-sm md:text-lg">
            {description}
          </div>
        </div>
        <div className="relative">
          <img
            src={image}
            alt={alt}
            className="hidden md:block max-w-full max-h-full object-contain"
          />
          <img
            src={imageMobile}
            alt={alt}
            className="md:hidden max-w-full max-h-full object-contain"
          />
          <div className="absolute top-0 left-0 p-[10px] md:p-0 md:top-[20px] md:left-[20px] text-white font-semibold md:font-bold text-xl md:text-4xl"></div>
        </div>
      </div>
    </div>
  );
}

function MobileCards() {
  return (
    <>
      {cards.map((card, index) => {
        const className = '!inline-flex min-w-full';

        return (
          <Card
            title={card.title}
            description={card.description}
            image={card.image}
            imageMobile={card.imageMobile}
            className={clsx('pb-[10px]', className)}
            alt={card.alt}
          />
        );
      })}
    </>
  );
}

export function HealingSwipeMobile() {
  return (
    <MobileSwipe dataList={cards}>
      <MobileCards />
    </MobileSwipe>
  );
}
