import React, { useState } from 'react';
import VimeoEmbedPortrait from '@components/VimeoEmbedPortrait';
import { PopUp } from '@components/Popup';

interface ExecutiveMemberProps {
  placeholderImage: string;
  videoId: string;
  name: string;
  title: string;
  about: string;
  alt: string;
}

export default function ExecutiveMember({
  placeholderImage,
  videoId,
  name,
  title,
  about,
  alt,
}: ExecutiveMemberProps) {
  const [isOpenVideo, setIsOpenVideo] = useState(false);

  return (
    <>
      <PopUp
        isOpen={isOpenVideo}
        onClose={() => {
          setIsOpenVideo(false);
        }}
        className="w-full !rounded-[0px] max-w-80"
        isGlobalOverlap={true}
      >
        <div className="w-full aspect-[9/16] relative overflow-hidden">
          <VimeoEmbedPortrait videoId={videoId} />
        </div>
      </PopUp>

      {/* Layout wrapper */}
      <div
        className="md:flex md:space-x-8 items-start cursor-pointer"
        onClick={() => setIsOpenVideo(true)}
      >
        {/* Image */}
        <div className="md:flex-shrink-0 md:w-1/2 max-w-[500px]">
          <img
            src={placeholderImage}
            alt={alt}
            className="w-full object-cover"
          />
        </div>

        {/* Text content */}
        <div className="mt-[20px] md:mt-0 md:w-1/2 max-w-[800px]">
          <p className="text-[28px] md:text-7xl leading-[36px] md:leading-normal font-extrabold">
            {name}
          </p>
          <p className="mt-[5px] md:mt-[10px] text-xl md:text-5xl leading-[26px] md:leading-normal font-bold">
            {title}
          </p>
          <p className="mt-[20px] md:mt-[32px] text-[15px] md:text-xl leading-[22px] md:leading-normal">
            {about}
          </p>
        </div>
      </div>
    </>
  );
}

// TODO: Add responsive for mobile
