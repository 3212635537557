import Button from '@components/Button';
import Accordion from '@components/Accordion';
import { useNavigate } from 'react-router-dom';

export default function FaqSection() {
  const navigate = useNavigate();

  return (
    <section className="relative bg-white mt-[64px] md:mt-0 md:pt-[64px] md:pb-[120px] md:px-[76px] mb-[40px] md:mb-[20px] overflow-hidden">
      <div className="text-5xl leading-[48px] md:text-7xl font-extrabold md:leading-[65px] text-center">
        FAQs
      </div>

      <div className="md:mt-[20px] px-[30px] md:px-[100px]">
        <Accordion items={items} />
      </div>

      <div className="text-center mt-[104px] md:mt-[136px]">
        <div className="font-bold text-xl md:text-4xl mb-[20px]">
          Can't find your question?
        </div>
        <Button
          label="Go to FAQs"
          className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
          onClick={() => navigate('/faq?selected=Users')}
        />
      </div>
    </section>
  );
}

const items = [
  {
    title: 'How will my anonymity be protected?',
    content: (
      <>
        <p>
          At HeyJuni, your anonymity is our highest priority. We understand the
          importance of expressing yourself freely, which is why our platform is
          designed to ensure your confidentiality at every step.
        </p>
        <br />
        <p className="text-2xl font-bold">
          Key Measures for Protecting Your Anonymity
        </p>
        <ol className="list-decimal list-inside inline-block">
          <li className="mb-4">
            <span className="font-bold">
              No Personal Identifiable Information:
            </span>{' '}
            During the booking process, you are not required to provide any
            personally identifiable information, including your real name.
          </li>
          <li className="mb-4">
            <span className="font-bold">Data Encryption:</span> All information
            you share is encrypted using industry-standard protocols. This
            ensures that your data is securely stored and protected against
            unauthorized access.
          </li>
          <li className="mb-4">
            <span className="font-bold">Confidential Environment:</span> At
            HeyJuni, you can discuss your experiences without fear of judgment.
            No one will know who you are or what you talk about in your
            sessions. Our Peer Counselors follow strict protocols to ensure that
            all interactions remain private, so you can speak openly without
            fear of repercussions or exposure.
          </li>
          <li className="mb-4">
            <span className="font-bold">Self-Disclosure Guidelines:</span>{' '}
            Additionally, to safeguard your own anonymity, we recommend you to
            refrain from sharing details that could reveal your identity. This
            further safeguards your anonymity.
          </li>
        </ol>
      </>
    ),
  },
  {
    title: 'Who is the Peer Counselor that I will talk to?',
    content: (
      <>
        <p>
          Female Peer Counselors are the persons who will provide support to you.
        </p>
        <br />
        <p>
          The Peer Counselors at HeyJuni are a compassionate group of dedicated
          women trained to provide empathetic support and actively listen to
          individual needs. They are skilled in using cognitive behavioral
          techniques, mindfulness exercises, and other effective tools to assist
          those experiencing distress, trauma, or anxiety. Coming from diverse
          backgrounds - ranging from mental health professionals to advocates
          and individuals with lived experiences - they bring a variety of
          perspectives and understanding.
        </p>
        <br />
        <p>
          Each Peer Counselors undergoes rigorous screening, comprehensive
          training, and ongoing supervision by clinicians to ensure they deliver
          safe, understanding, and non-judgmental support. Their primary goal is
          to create a confidential and supportive environment where you can
          express yourself freely, receive personalized guidance, and feel
          accompanied in your healing process. They are there to take you by the
          hand, guiding you through coping strategies in real-time to help you
          manage your unique struggles.
        </p>
      </>
    ),
  },
  {
    title: 'How do you match me with a Peer Counselor?',
    content: (
      <div>
        Our matching process is designed to address your unique needs. When you
        connect with a Peer Counselor, we take into account your emotions,
        social context, and cultural background. By combining technology with
        genuine empathy, we aim to create a personalized experience that aligns
        with your specific challenges and preferences. This ensures you receive
        the most suitable support tailored to you.
      </div>
    ),
  },
  {
    title: 'Will I talk to the same Peer Counselor every session?',
    content: (
      <div>
        It's entirely your choice! You can opt to speak with different Peer
        Counselors for varied perspectives or request to connect with the same
        Peer Counselor for continuity and comfort. To ensure your privacy and
        anonymity, please contact our customer service at{' '}
        <a href="mailto:anna@heyjuni.com" className="text-blue-800">
          anna@heyjuni.com
        </a>{' '}
        if you'd like to speak with a specific Peer Counselor.
      </div>
    ),
  },
  {
    title: 'How much does a session cost?',
    content: (
      <div>
        <p>
          We currently offer a flexible option to access our peer support
          services:
        </p>
        <ol className="list-decimal list-inside inline-block">
          <li className="mb-4">
            <span className="font-bold">Single Session Option:</span>{' '}
            Pay-as-you-go at SG $29.90 per session, allowing you the freedom to
            book only when needed without any long-term commitment.
          </li>
        </ol>
        <p>
          We prioritize the quality of user experience by investing in extensive
          training and fair compensation for our Peer Counselor, Mentors and
          Clinicians, ensuring a supportive and uplifting environment. We
          maintain a strict commitment to user privacy by refraining from
          selling any user data, setting us apart from other "free" platforms.
          Instead of relying on intrusive ads to generate revenue, we focus on
          creating a seamless and ad-free platform to enhance user satisfaction.
        </p>
      </div>
    ),
  },
];
