import { useNavigate } from 'react-router-dom';
import Button from '@components/Button';
import { useTranslation } from 'react-i18next';

export default function ListenersPromiseSection() {
  const navigate = useNavigate();
  const { t } = useTranslation('learn-more');

  return (
    <section className="relative bg-white md:py-[64px] md:px-[76px] mb-[64px] md:mb-[40px] overflow-hidden">
      {/* TODO: Create the common css for title */}
      <div className="text-5xl leading-[48px] md:leading-[65px] md:text-7xl font-extrabold text-center">
        {t('promise.title')}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 mt-[16px] mb-[40px] px-[28px] md:px-0 md:my-[40px] gap-[20px]">
        <Card
          title={t('promise.cards.card-1.title')}
          description={t('promise.cards.card-1.content')}
          bgColor="bg-[#D8FFF3]"
        />
        <Card
          title={t('promise.cards.card-2.title')}
          description={t('promise.cards.card-2.content')}
          bgColor="bg-[#D8CFFC]"
        />
        <Card
          title={t('promise.cards.card-3.title')}
          description={t('promise.cards.card-3.content')}
          bgColor="bg-[#FEE60C]"
        />
      </div>

      <div className="text-center">
        <Button
          label={t('promise.call-to-action.button')}
          className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
          onClick={() => navigate('/become-a-listener')}
        />
      </div>
    </section>
  );
}

interface CardProps {
  title: string;
  description: string;
  bgColor: string;
}

function Card({ title, description, bgColor }: CardProps) {
  return (
    <div
      className={`flex flex-col items-center justify-center md:justify-start ${bgColor} text-center py-[40px] md:py-[48px] px-[32px] h-[175px] md:h-auto`}
    >
      <h3 className="text-xl md:text-4xl font-bold">{title}</h3>
      <p className="text-base md:text-2xl font-regular">{description}</p>
    </div>
  );
}
