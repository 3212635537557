import React, { ReactNode, useRef, useState } from 'react';
import clsx from 'clsx';

interface MobileSwipeProps {
  dataList: any[];
  children: ReactNode;
}

export function MobileSwipe(props: MobileSwipeProps) {
  const { dataList, children } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);
  const [startX, setStartX] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const swipeRef = useRef<HTMLDivElement>(null);

  // Handle the start of a swipe
  const handleTouchStart = (e: React.TouchEvent) => {
    setIsSwiping(true);
    setStartX(e.touches[0].clientX);
  };

  // Handle swipe movement
  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isSwiping) return;
    const currentX = e.touches[0].clientX;
    setTranslateX(currentX - startX);
  };

  // Handle the end of the swipe and decide slide change
  const handleTouchEnd = () => {
    if (!isSwiping) return;
    setIsSwiping(false);

    if (translateX > 50 && currentIndex > 0) {
      changeSlide('left');
    } else if (translateX < -50 && currentIndex < dataList.length - 1) {
      changeSlide('right');
    }

    setTranslateX(0);
  };

  // Function to change the slide based on direction
  const changeSlide = (direction: 'left' | 'right') => {
    if (direction === 'right' && currentIndex < dataList.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else if (direction === 'left' && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="md:hidden relative w-full max-w-md mx-auto overflow-hidden">
      <div
        className="flex transition-transform duration-100"
        style={{
          transform: `translateX(calc(-${currentIndex} * 100% + ${translateX}px))`,
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        ref={swipeRef}
      >
        {children}
      </div>

      {/* Slide indicators */}
      <div className="flex justify-center mt-[28px] space-x-2">
        {dataList.map((_, idx) => (
          <span
            key={idx}
            className={clsx('w-2 h-2 rounded-full', {
              'bg-gray-300': idx !== currentIndex,
              'bg-purple-500': idx === currentIndex,
            })}
          ></span>
        ))}
      </div>
    </div>
  );
}
