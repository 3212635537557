const API_KEY = '8a35eed2a3855056f90c15d3ec6e6b1a';

export const fetchCountry = async (): Promise<string | null> => {
  const cachedCountry = localStorage.getItem('userCountry');
  if (cachedCountry) return cachedCountry;

  try {
    // Using free trial, 5000 requests per month
    const response = await fetch(
      `https://api.ipstack.com/check?access_key=${API_KEY}`
    );
    if (!response.ok) throw new Error('Failed to fetch location');
    const data = await response.json();
    const countryCode = data.country_code;
    localStorage.setItem('userCountry', countryCode);

    return countryCode;
  } catch (error) {
    console.error(error);
    return null;
  }
};
