import Button from '@components/Button';
import { useNavigate } from 'react-router-dom';
import { HealingSwipe, HealingSwipeMobile } from './components/HealingSwipe';

export function HealingSection() {
  const navigate = useNavigate();

  return (
    <section className="relative bg-white md:py-[64px] mb-[94px] md:mb-[40px]">
      <div className="text-center font-extrabold text-5xl md:text-7xl leading-[48px] md:leading-[65px] mb-[10px] md:mb-[28px]">
      How We Can Support You
      </div>
      <HealingSwipe />
      <HealingSwipeMobile />
      <div className="text-center mt-[28px] mb-[8px] md:mt-[28px] md:mb-[16px] font-bold text-xl md:text-4xl">
      Take the first step to healing, and get matched with a Peer Counselor.
      </div>
      <div className="text-center">
        <Button
          label="Get Support"
          className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
          onClick={() => navigate('/booking')}
        />
      </div>
    </section>
  );
}
