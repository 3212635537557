import Button from '@components/Button';
import { openWhatsapp } from '@src/utils/open-whatsapp';

// TODO: Check css

export default function TrainingSection() {
  return (
    <section className="relative bg-white md:py-[40px] md:px-[76px] mb-[84px] overflow-hidden">
      <div className="md:hidden text-center mt-[40px] mb-[24px] font-extrabold text-5xl leading-[48px]">
        Training and Curriculum
      </div>

      <div className="md:hidden my-[24px] flex justify-center">
        <img
          src="training-background-mobile.webp"
          className="w-[360px] h-[360px]"
          alt="A close up image of a computer, and hand holding a pencil, symbolizing online learning and training."
        />
      </div>

      <div className="md:w-[60%] md:py-[64px] md:pr-[75px]">
        <div className="hidden md:block md:mb-[24px] font-extrabold md:text-[56px] md:leading-[65px]">
          Training and Curriculum
        </div>

        <div className="px-[28px] md:p-0">
          <p className="text-[15px] md:text-lg">
            Our training program is designed for individuals passionate about
            learning the skills of peer counseling. With our comprehensive
            curriculum, you'll gain the knowledge and techniques to help
            yourself, support loved ones, or even become a Peer counselor on our
            platform. This program offers a meaningful path toward a
            professional role, allowing you to grow within our community and
            make peer support a rewarding career.
          </p>
        </div>

        <div className="mt-[24px] md:mt-[40px] mb-[20px] md:mb-0 text-center md:text-left">
          <Button
            label="Contact Training Team"
            className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
            onClick={openWhatsapp}
          />
        </div>
      </div>

      {/* TODO: Fix this position later */}
      <div className="hidden md:block md:absolute md:top-[calc(50%+20px)] md:right-0 md:transform md:-translate-y-1/2">
        <img
          src="training-background.webp"
          alt="A close up image of a computer, and hand holding a pencil, symbolizing online learning and training."
        />
      </div>
    </section>
  );
}
