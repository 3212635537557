import Button from '@components/Button';
import { SupporterSwipe } from './components/SupporterSwipe';

interface MeetSupportersSectionProps {
  actionLabel: string;
  handleAction: () => void;
}

export function MeetSupportersSection(props: MeetSupportersSectionProps) {
  const { actionLabel, handleAction } = props;

  return (
    <section className="relative bg-white mb-[84px] md:mb-0 md:pt-[65px] md:pb-[40px]">
      <div className="text-center font-extrabold text-5xl md:text-7xl leading-[48px] md:leading-[65px] mt-[30px] md:mt-0 mb-[20px] md:mb-[20px]">
      Some Faces Behind The Support
      </div>
      <SupporterSwipe />
      <div className="text-center mt-[20px] md:mt-[28px] md:mb-[26px]">
        <Button
          label={actionLabel}
          className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
          onClick={handleAction}
        />
      </div>
    </section>
  );
}
