import Button from '@components/Button';
import AnonymityImage from '@assets/footages/landing/full-anonymity.svg'; // Replace with your image paths
import AnytimeImage from '@assets/footages/landing/anytime-anywhere.svg';
import ExpertiseImage from '@assets/footages/landing/assure-expertise.svg';
import SupportImage from '@assets/footages/landing/adaptive-support.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function OfferSection() {
  const navigate = useNavigate();
  const { t } = useTranslation('home');

  const offers = [
    {
      title: t('offer.offers.anonymity.title'),
      description: t('offer.offers.anonymity.description'),
      backgroundImage: AnonymityImage,
    },
    {
      title: t('offer.offers.anytime.title'),
      description: t('offer.offers.anytime.description'),
      backgroundImage: AnytimeImage,
    },
    {
      title: t('offer.offers.women.title'),
      description: t('offer.offers.women.description'),
      backgroundImage: ExpertiseImage,
    },
    {
      title: t('offer.offers.effective.title'),
      description: t('offer.offers.effective.description'),
      backgroundImage: SupportImage,
    },
  ];

  return (
    <section className="text-center px-[20px] md:px-[76px] py-[20px] mb-[64px]">
      {/* <SectionTitle value="What we offer" /> */}
      <p className="text-5xl md:text-7xl leading-[48px] md:leading-[65px] font-extrabold mb-[40px] md:mb-0">
        {t('offer.title')}
      </p>

      {/* Offer Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px] md:gap-[40px] md:my-[40px] mx-auto">
        {offers.map((offer, index) => (
          <div
            key={index}
            className="relative bg-cover bg-center p-[28px] md:p-[32px] md:h-[528px]"
            style={{ backgroundImage: `url(${offer.backgroundImage})` }}
          >
            {/* Overlay Text */}
            <div className="relative z-10 text-left">
              <h3
                className="text-[28px] md:text-6xl leading-[36px] md:leading-[62px] font-extrabold mb-[20px] md:mb-[32px]"
                dangerouslySetInnerHTML={{ __html: offer.title }}
              ></h3>
              <p className="md:text-2xl md:leading-[32px] md:font-regular">
                {offer.description}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="text-center mt-[60px] md:mt-0">
        <Button
          label={t('offer.call-to-action.button')}
          className="h-[50px] md:h-[60px] !px-[30px] text-lg md:text-2xl md:leading-[30px] font-semibold rounded-[20px] md:rounded-[24px]"
          onClick={() => navigate('/learn-more')}
        />
      </div>
    </section>
  );
}
