import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import WhatIsSexualViolence from './article-content/what-is-sexual-violence';
import HealingCopingAndBuildingResilience from './article-content/healing-coping-and-building-resilience';
import HowSexualViolenceHappensInFamiliarSettings from './article-content/how-sexual-violence-happens-in-familiar-settings';
import TheMentalTollOfSexualViolence from './article-content/the-mental-toll-of-sexual-violence';
import SuggestedArticle from './suggested-article';

export type ArticleData = {
  title: string;
  author: string;
  date: string;
  content: React.ComponentType;
};

const articleDataList: { [key: string]: ArticleData } = {
  'what-is-sexual-violence': {
    title:
      'The First Step to Breaking the Stigma Is to Understand Sexual Violence',
    author: 'Askhita Bhatia',
    date: '06/12/24',
    content: WhatIsSexualViolence,
  },
  'how-sexual-violence-happens-in-familiar-settings': {
    title:
      'It Happens Here Too: Examples of Sexual Violence in Everyday Situations',
    author: 'Askhita Bhatia',
    date: '06/12/24',
    content: HowSexualViolenceHappensInFamiliarSettings,
  },
  'the-mental-toll-of-sexual-violence': {
    title:
      'What Is the Impact of Sexual Violence on Mental Health and Well-being?',
    author: 'Askhita Bhatia',
    date: '06/12/24',
    content: TheMentalTollOfSexualViolence,
  },
  'healing-coping-and-building-resilience': {
    title: 'Recovery and Resilience: Coping Strategies After Sexual Violence',
    author: 'Askhita Bhatia',
    date: '06/12/24',
    content: HealingCopingAndBuildingResilience,
  },
};

export default function Article() {
  const navigate = useNavigate();
  const params = useParams();
  const slug = params.slug;
  const articleData = slug ? articleDataList[slug] : null;

  return (
    <section className="px-[28px] md:px-[76px] mb-[40px] md:mb-[76px]">
      <a
        onClick={() => navigate('..')}
        className="text-lg md:text-2xl text-black-500 underline cursor-pointer"
      >
        {'< Back to Articles'}
      </a>
      {articleData && (
        <div className="mt-10">
          <p className="text-5xl md:text-7xl font-extrabold leading-[48px] md:leading-[65px] tracking-tight">
            {articleData.title}
          </p>
          <div className="flex flex-col md:flex-row justify-between mt-4">
            <p className="text-lg text-gray-500">
              Written by: {articleData.author}
            </p>
            <p className="text-lg text-gray-500">
              Date created: {articleData.date}
            </p>
          </div>
          <div className="mt-4">
            <articleData.content />
          </div>
        </div>
      )}
      <SuggestedArticle />
    </section>
  );
}
