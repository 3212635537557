import SwirlStepBg from '@assets/footages/how-it-works/swirl-steps.svg';
import Button from '@components/Button';

import step01Img from '@assets/footages/how-it-works/how-it-works-1.svg';
import step02Img from '@assets/footages/how-it-works/how-it-works-2.svg';
import step03Img from '@assets/footages/how-it-works/how-it-works-3.svg';
import step04Img from '@assets/footages/how-it-works/how-it-works-4.svg';
import { useNavigate } from 'react-router-dom';
import { MobileSwipe } from '@components/MobileSwipe';
import NormalObject from '@components/NormalObject';
import { useTranslation } from 'react-i18next';

interface Step {
  id: number;
  src: string;
  title: string;
  line1: string;
  line2: string;
  line3: string;
}

interface MobileCardsProps {
  steps: Step[];
}

function MobileCards({ steps }: MobileCardsProps) {
  return (
    <>
      {steps.map((step) => (
        <div
          key={step.id}
          className="min-w-full flex-shrink-0 flex flex-col items-center justify-center"
        >
          <NormalObject
            data={step.src}
            className="w-full h-auto object-cover mb-[30px]"
          />
          <h3 className="text-[28px] leading-[36px] font-extrabold p-[10px] mb-[10px]">
            {step.title}
          </h3>
          <p className="text-center text-lg">{step.line1}</p>
          <p className="text-center text-lg">{step.line2}</p>
          <p className="text-center text-lg">{step.line3}</p>
        </div>
      ))}
    </>
  );
}

export function WorkFlowSection() {
  const navigate = useNavigate();
  const { t } = useTranslation('home');

  const steps = [
    {
      id: 1,
      src: step01Img,
      title: t('work-flow.steps.personalize.title'),
      line1: t('work-flow.steps.personalize.line-1'),
      line2: t('work-flow.steps.personalize.line-2'),
      line3: t('work-flow.steps.personalize.line-3'),
    },
    {
      id: 2,
      src: step02Img,
      title: t('work-flow.steps.get-matched.title'),
      line1: t('work-flow.steps.get-matched.line-1'),
      line2: t('work-flow.steps.get-matched.line-2'),
      line3: t('work-flow.steps.get-matched.line-3'),
    },
    {
      id: 3,
      src: step03Img,
      title: t('work-flow.steps.get-support.title'),
      line1: t('work-flow.steps.get-support.line-1'),
      line2: t('work-flow.steps.get-support.line-2'),
      line3: t('work-flow.steps.get-support.line-3'),
    },
    {
      id: 4,
      src: step04Img,
      title: t('work-flow.steps.build-resilience.title'),
      line1: t('work-flow.steps.build-resilience.line-1'),
      line2: t('work-flow.steps.build-resilience.line-2'),
      line3: t('work-flow.steps.build-resilience.line-3'),
    },
  ];

  return (
    <div className="py-[20px] mb-[64px]">
      <div className="text-center">
        <p className="text-5xl md:text-7xl leading-[48px] md:leading-[65px] font-extrabold mb-[24px] md:mb-0">
          {t('work-flow.title')}
        </p>
      </div>

      <div className="hidden md:block relative w-full pt-[94px] pb-[47px]">
        <NormalObject data={SwirlStepBg} className="w-full h-auto" />

        <div className="absolute top-full transform translate-y-[40px] inset-0 flex justify-between items-center">
          {steps.map((step) => (
            <div key={step.id} className="flex flex-col items-center flex-1">
              <h3 className="text-4xl font-bold md:leading-[40px] pt-[14px]">
                {step.title}
              </h3>
              <p className="text-center font-regular md:leading-[26px] text-lg mt-[14px]">
                {step.line1}
              </p>
              <p className="text-center font-regular md:leading-[26px] text-lg">
                {step.line2}
              </p>
              <p className="text-center font-regular md:leading-[26px] text-lg">
                {step.line3}
              </p>
            </div>
          ))}
        </div>
      </div>

      <MobileSwipe dataList={steps}>
        <MobileCards steps={steps} />
      </MobileSwipe>

      <div className="mt-[42px] md:mt-[158px] text-center">
        <Button
          label={t('work-flow.call-to-action')}
          className="h-[50px] w-[168px] md:h-[60px] md:w-[192px] text-lg md:text-2xl font-semibold rounded-[20px] md:rounded-2xl"
          onClick={() => navigate('/booking')}
        />
      </div>
    </div>
  );
}
