import React from 'react';
import { IntroSection } from './intro-section';
import RealWomenSection from './real-women-section';
import { HealingSection } from './healing-section';
import { WorkFlowSection } from '../Home/work-flow-section';
import ListenersPromiseSection from './listeners-promise-section';
import FaqSection from './faq-section';
import TalkToListenerSection from './talk-to-listener-section';
import { MeetSupportersSection } from '../BecomeAListener/meet-some-supporters-section';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// TODO: Create common button used for all places in this page

function LearnMore() {
  const navigate = useNavigate();

  return (
    <div>
      {/* Add Helmet for SEO */}
      <Helmet>
        <title>
          Online Mental Support within minutes. For Women, By Women.
        </title>
        <meta
          name="description"
          content="HeyJuni offers instant, affordable 1-on-1 emotional support with female peer counselors, proven effective for mental recovery — as a standalone support or alongside therapy."
        />
        <meta
          name="keywords"
          content="HeyJuni, Learn More, Peer Counselors, Mental Health, Healing"
        />
        <meta property="og:title" content="Learn More - HeyJuni" />
        <meta
          property="og:description"
          content="Discover how HeyJuni helps in healing, breaking stigma, and providing inclusive mental health care solutions."
        />
      </Helmet>

      <IntroSection />
      <HealingSection />
      <RealWomenSection />
      <MeetSupportersSection
        actionLabel="Book a Peer Counselor"
        handleAction={() => navigate('/booking')}
      />
      <ListenersPromiseSection />
      <WorkFlowSection />
      <FaqSection />
      <TalkToListenerSection />
    </div>
  );
}

export default LearnMore;
