import React from 'react';
import { useNavigate } from 'react-router-dom';

export interface ArticleCardProps {
  image: string;
  title: string;
  description: string;
  date: string;
  author: string;
  slug: string;
  alt: string;
}

export default function ArticleCard({
  image,
  title,
  description,
  date,
  author,
  slug,
  alt,
}: ArticleCardProps) {
  const navigate = useNavigate();

  const gotoArticle = (slug: string) => {
    navigate(`/blog/articles/${slug}`);
  };

  return (
    <div
      className="border-[2px] rounded-[20px] overflow-hidden h-[600px] flex flex-col gap-2 px-[20px] py-[40px] cursor-pointer"
      onClick={() => gotoArticle(slug)}
    >
      <img
        className="w-full object-cover rounded-[20px]"
        src={image}
        alt={alt}
      />
      <p className="text-sm text-gray-500">
        {date} • By: {author}
      </p>
      <h2 className="mt-2 text-3xl font-bold text-gray-800">{title}</h2>
      <p className="mt-2 text-gray-600 flex-1 text-[15px] md:text-lg">
        {description}
      </p>
      <button
        className="mt-4 px-4 py-2 text-sm font-medium text-black border-[2px] border-black bg-white rounded-full hover:bg-black hover:text-white self-start"
        onClick={() => {
          gotoArticle(slug);
        }}
      >
        Read more
      </button>
    </div>
  );
}
