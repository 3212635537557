import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@components/Button';
import { PopUp } from '@components/Popup';
import appLogo from '@assets/logo.svg';
import languageIcon from '@assets/icons/shared/language.svg';
import arrowDownIcon from '@assets/icons/shared/arrow-down.svg';
import mobileMenuIcon from '@assets/icons/shared/menu-icon.svg';
import closeNavMenu from '@assets/icons/shared/close-nav-menu.svg';

import useScrollToTop from '@src/hooks/useScrollToTop';
import ClickableObject from './ClickableObject';

interface HeaderProps {}

const MobileNavLinks = ({ onClick }: { onClick?: () => void }) => {
  const links = [
    // { path: '/about', label: 'ABOUT' },
    { path: '/learn-more', label: 'OUR SERVICES' },
    { path: '/mission-and-vision', label: 'MISSION & VISION' },
    { path: '/team-juni', label: 'TEAM HEYJUNI' },
    { path: '/become-a-listener', label: 'BECOME PEER COUNSELOR' },
    { path: '/pricing', label: 'PRICING' },
    { path: '/blog', label: 'BLOG' },
  ];

  return (
    <>
      {links.map(({ path, label }) => (
        <Link
          key={path}
          to={path}
          onClick={onClick}
          className="font-extrabold leading-[36px] text-[28px]"
        >
          {label}
        </Link>
      ))}
    </>
  );
};

// Mobile Menu Component
const MobileMenu = ({
  toggleMenu,
  navigate,
}: {
  toggleMenu: () => void;
  navigate: (path: string) => void;
}) => (
  <div
    className="fixed top-0 left-0 w-full h-full bg-white z-[999]"
    style={{ touchAction: 'none' }}
  >
    <header className="sticky top-0 bg-white z-50 h-[62px] md:h-[80px]">
      <nav className="mx-auto flex items-center justify-between px-[20px] h-full">
        <img
          src={appLogo}
          className="w-[64px] h-[44px]"
          onClick={() => {
            navigate('/');
          }}
          alt="HeyJuni Logo"
        />
        <div onClick={toggleMenu}>
          <img
            src={closeNavMenu}
            className="w-[19px] h-[12px]"
            alt="Close Icon"
          />
        </div>
      </nav>
    </header>
    <div className="flex flex-col gap-[36px] px-[28px] pt-[42px] text-3xl font-extrabold">
      <MobileNavLinks onClick={toggleMenu} />
      <Button
        label="Book Session"
        className="font-semibold text-lg w-full h-[50px] rounded-[20px]"
        onClick={() => {
          navigate('/booking');
          toggleMenu();
        }}
      />
    </div>
  </div>
);

const DesktopNavLinks = ({ onClick }: { onClick?: () => void }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  // Call the custom hook with isOpenMenu to block or allow scrolling

  const aboutRef = useRef(null);

  return (
    <>
      <span
        className="relative inline-flex items-center gap-[10px] cursor-pointer"
        onClick={(e) => {
          if (isOpenMenu) {
            setIsOpenMenu(false);
          } else setIsOpenMenu(true);
        }}
        ref={aboutRef}
      >
        About
        <ClickableObject data={arrowDownIcon} className="h-[6px] w-[10px]" />
        <PopUp
          isOpen={isOpenMenu}
          onClose={() => setIsOpenMenu(false)}
          baseRef={aboutRef}
        >
          <div className="flex flex-col p-[20px] gap-[15px] font-regular text-lg leading-[23px]">
            <Link to="/team-juni">Team HeyJuni</Link>
            <Link to="/mission-and-vision">Mission & Vision</Link>
          </div>
        </PopUp>
      </span>
      <Link to="/learn-more">Our Services</Link>
      <Link to="/become-a-listener">Become Peer Counselor</Link>
      <Link to="/pricing">Pricing</Link>
      <Link to="/blog">Blog</Link>
    </>
  );
};

// Desktop Header Component
const DesktopHeader = ({
  toggleMenu,
  navigate,
}: {
  toggleMenu: () => void;
  navigate: (path: string) => void;
}) => {
  const { i18n } = useTranslation();

  const [isOpenLanguage, setIsOpenLanguage] = useState(false);
  const languageRef = useRef(null);

  return (
    <header className="fixed top-0 left-0 right-0 bg-white shadow-md z-50 h-[62px] md:h-[90px]">
      <nav
        id="desktop-header"
        className="mx-auto flex items-center justify-between md:px-[33px] md:py-[23px] h-full"
      >
        <div className="flex items-center gap-[50px] pl-[20px] md:pl-0">
          <img
            src={appLogo}
            className="w-[64px] h-[44px] md:w-[74px] md:h-[50px] cursor-pointer"
            onClick={() => navigate('/')}
            alt="HeyJuni Logo"
          />
          <ul className="hidden sm:flex space-x-[30px] font-regular text-lg">
            <DesktopNavLinks />
          </ul>
        </div>
        <div className="flex items-center space-x-[30px]">
          <Button
            label="Book Session"
            className="font-semibold text-base md:text-xl h-[34px] md:h-[44px] leading-[18px] md:leading-[30px] rounded-[10px] md:rounded-[15px] px-[15px] md:px-[20px]"
            onClick={() => navigate('/booking')}
          />
          <div
            className="hidden sm:flex items-center space-x-[10px] cursor-pointer"
            onClick={(e) => {
              if (isOpenLanguage) {
                setIsOpenLanguage(false);
              } else setIsOpenLanguage(true);
            }}
            ref={languageRef}
          >
            <ClickableObject
              data={languageIcon}
              className="h-[20px] w-[20px]"
            />
            <span className="font-regular text-lg leading-[40px]">
              {i18n.language === 'en' ? 'EN' : 'VN'}
            </span>
            <ClickableObject
              data={arrowDownIcon}
              className="h-[6px] w-[10px]"
            />

            <PopUp
              isOpen={isOpenLanguage}
              onClose={() => setIsOpenLanguage(false)}
              baseRef={languageRef}
              right={20}
            >
              <div className="flex flex-col p-[20px] gap-[15px] font-regular text-lg leading-[23px]">
                <LanguageSwitcher />
              </div>
            </PopUp>
          </div>
        </div>
        <div className="block sm:hidden">
          <ClickableObject
            data={mobileMenuIcon}
            onClick={toggleMenu}
            className="w-[19px] h-[12px] mr-[20px]"
          />
        </div>
      </nav>
    </header>
  );
};

// Main Header Component
export default function Header(props: HeaderProps) {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? 'hidden' : 'visible';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isMenuOpen]);

  // TODO: Put this somewhere else make more sense.
  useScrollToTop();

  return (
    <>
      {!isMenuOpen && (
        <DesktopHeader toggleMenu={toggleMenu} navigate={navigate} />
      )}
      {isMenuOpen && <MobileMenu toggleMenu={toggleMenu} navigate={navigate} />}
    </>
  );
}

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const options = [
    { value: 'en', label: 'English' },
    { value: 'vn', label: 'Vietnamese' },
  ];

  function changeLanguage(lang: string) {
    i18n.changeLanguage(lang);
  }

  return (
    <>
      {options.map((option) => (
        <div key={option.value} onClick={() => changeLanguage(option.value)}>
          {option.label}
        </div>
      ))}
    </>
  );
}
