import Button from '@components/Button';
import Accordion from '@components/Accordion';
import { useNavigate } from 'react-router-dom';

export default function FaqSection() {
  const navigate = useNavigate();

  return (
    <section className="relative bg-white md:pt-[64px] md:pb-[120px] md:px-[76px] mb-[40px] md:mb-[20px] overflow-hidden">
      <div className="text-5xl leading-[48px] md:text-7xl font-extrabold md:leading-[65px] text-center">
        FAQs
      </div>

      <div className="md:mt-[20px] px-[30px] md:px-[100px]">
        <Accordion items={items} />
      </div>

      <div className="text-center md:mt-[32px]">
        <div className="font-bold text-xl md:text-4xl mb-[20px]">
          Can't find your question?
        </div>
        <Button
          label="Go to FAQs"
          className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
          onClick={() => navigate(`/faq?selected=Peer Counselor`)}
        />
      </div>
    </section>
  );
}

const items = [
  {
    title: 'What does the training curriculum cover?',
    content: (
      <>
        <p>
          Our curriculum provides specialized training on trauma and anxiety
          related to sexual violence, giving you a thorough and compassionate
          understanding of this sensitive subject.
        </p>
        <p>Flexible, Remote Learning.</p>
        <p>
          The program is fully remote, allowing you to complete it at your
          convenience. It includes self-guided modules, pre-recorded video
          content, and regular check-ins with our clinicians and trainers.
        </p>
        <br />
        <p className="font-bold">Core Modules</p>
        <ol className="list-disc list-inside inline-block">
          <li>
            <span className="font-semibold">Support Essentials:</span>{' '}
            Foundations of empathy, active listening, and supportive
            communication.
          </li>
          <li>
            <span className="font-semibold">Psychological First Aid:</span>{' '}
            Immediate response techniques for emotional support.
          </li>
          <li>
            <span className="font-semibold">CBT Principles:</span> Core Core
            cognitive-behavioral techniques for peer support.
          </li>
          <li>
            <span className="font-semibold">
              Effective Online Communication:
            </span>{' '}
            Building connections and trust in virtual support settings.
          </li>
          <li>
            <span className="font-semibold">Identifying Crisis:</span> Although
            HeyJuni is not as crisis platform, it is important to understand
            crisis and to navigate users to the right platforms for support.
          </li>
        </ol>
      </>
    ),
  },
  {
    title: 'What are the requirements to become a Peer Counselor at HeyJuni?',
    content: (
      <div>
        <p>
          You don't need professional qualifications to join the course. At
          HeyJuni, we believe that everyone has the potential to be part of the
          mental health care system.
        </p>
        <br />
        <p className="text-3xl font-semibold">
          However to become a Peer Counselor, you should bring these values:
        </p>
        <ul className="list-disc list-inside">
          <li>A basic level of empathy</li>
          <li>A genuine desire to help others</li>
          <li>Integrity and a willingness to learn</li>
          <li>Familiarity with digital platforms</li>
          <li>
            An intention to make a positive impact on people's lives. We value
            diverse backgrounds and experiences, as they enrich the support we
            provide.
          </li>
        </ul>
        <br />
        <p className="text-3xl font-semibold">
          Furthermore, these are some basic criteria you should bring:
        </p>
        <ul className="list-disc list-inside">
          <li>Be at least 18 years old</li>
          <li>Identify as female</li>
          <li>Complete the HeyJuni training curriculum</li>
          <li>Have basic tech skills</li>
          <li>Possess strong communication abilities</li>
          <li>
            Ideally have personal or professional experience related to mental
            health or support services.
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Will I receive a certification after the training?',
    content: (
      <div>
        <ul className="list-disc list-inside">
          <li>
            Yes, upon completing the training, you'll earn an official
            certificate accredited by Juni.
          </li>
          <li>
            If you need assistance submitting your certification to your school
            or organization for recognition, feel free to reach out—we're here
            to help.
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Is there a cost for the training?',
    content: (
      <div>
        <ul className="list-disc list-inside">
          <li>
            One-Time Fee: The training program fee is 150 SGD. This one-time
            payment covers all materials, program organization, and trainer
            compensation.
          </li>
          <li>
            This fee ensures that you receive high-quality, consistent training
            from start to finish.
          </li>
        </ul>
      </div>
    ),
  },
];
